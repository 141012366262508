<template>
  <div class="row">
    <input type="hidden" name="imgPrincipal" :value="filePrincipal" v-if="filePrincipal != null" >
    <div class="col-md-3 text-center" v-for="(file, index) in filesPreview" :key="index" >
      <i class="fas fa-check-circle marck-icon" v-if="index == filePrincipal "></i>
      <img :src="file" class="img-fluid rounded"  v-bind:class="[index == filePrincipal ? 'img-active' : '']" @click="ShowPrincipal(index)" />
    </div>
    <div class="col-md-3">
      <div class="d-flex flex-column box-upload border justify-content-center align-items-center">
        <input type="file" multiple name="files[]" accept="image/*" class="input-file" ref="file" @change="handleChange">
        <i class="fas fa-upload"></i>
        <div>Select file</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      markImage: {
        required: false,
        type: Boolean
      }
    }, 
    data() {
      return {
        files: null,
        filesPreview: [],
        filePrincipal: null,
      }
    },
    methods: {
      handleChange(event) {
        this.files = this.$refs.file.files;

        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            this.filesPreview.push(URL.createObjectURL(this.files.item(i)));
          }
        } else {
          this.filesPreview = [];
        }
      }
      ,ShowPrincipal(index){
        if(this.markImage){
          if(index == this.filePrincipal)
            this.filePrincipal = null;
          else
            this.filePrincipal = index;
        }
      }
    }
  }
</script>

<style scoped>
  .box-upload {
    width: 8rem;
    height: 8rem;
    text-align: center;
    cursor: pointer;
    position: relative;
  }

  .input-file {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 8rem;
    height: 8rem;
  }
  .img-active{
    opacity: 0.5;
  }
  .marck-icon{
    color: #000000;
    left: 0;
    position: absolute;
    left: 20px;
    top: 5px;
    font-size: 24px;
  }
</style>
