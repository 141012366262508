<template>
<div>
  <div class="row">
    <div class="col">
          <button class="btn btn-primary mt-3 mb-3" @click="addImage = true">Agregar Imagén</button>
          <button class="btn btn-warning"  v-if="addImage" @click="cancel()">Cancelar</button>
      
          <select style="width: 200px; height: 36px;" class="mt-3 mb-3" v-if="addImage" v-model="type" name="type" id="type">
            <option value="resort">Resort</option>
            <option value="hoteles">Hoteles</option>
            <option value="tours">Tours</option>
            <option value="traslados">Traslados</option>
            <option value="autos">Renta de autos</option>
          </select>
      <table class="table" v-if="!addImage">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" class="text-wrap">Nombre</th>
            <th scope="col" class="text-wrap">Tipo</th>
            <th scope="col">Imagen</th>
            <th scope="col">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in images" :key="item.id">
            <th scope="row">{{ item.id }}</th>
            <td>{{ item.file }}</td>
            <td scope="row">{{ item.type }}</td>
            <td class="text-wrap"> <img :src="item.public_path" :alt="item.name" class="img-preview"> </td>
            <td>
              <button class="btn btn-danger" @click="deleteImage(item.id)">Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row justify-content-md-center" v-if="addImage">
  
    <div class="col-8" v-if="files.length > 0">
      <div class="alert alert-info" v-if="isFinish">
        Imagenes guardadas correctamente
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" class="text-wrap">Nombre</th>
            <th scope="col">Imagen</th>
            <th scope="col">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in files" :key="item.name">
            <th scope="row">1</th>
            <td>{{ item.name }}</td>
            <td class="text-wrap"> <img :src="item.image" :alt="item.name" class="img-preview"> </td>
            <td>
              <button class="btn btn-danger" @click="removeFile(item.id)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="container-images" class="col-4">
      <div class="row align-items-center">
        <div class="drop row justify-content-md-center" @dragover.prevent @drop="onDrop">
          <label v-if="!image" class="btn display-inline btn-primary">
              <i class="fas fa-images"></i> Seleccionar imagen
            <input id="files" type="file" name="image" @change="onChange" multiple="multiple">
          </label>
          <small class="form-text text-muted">* 500px x 250px</small>
        </div>
        <div class="col mt-3 mb-3" v-if="files.length > 0">
          <button class="btn btn-warning" @click="cancel()">Cancelar</button>
          <button class="btn btn-success" @click="save()"  v-loader-on-click>Guardar</button>
        </div>
      </div>
    </div>

  </div>
  </div>
</template>
<script>
export default {
    data() {
      return {
        addImage: false,
        image: '',
        images: [],
        files: [],
        type:'resort',
        isFinish: false,
        errors: [],
        data: new FormData(),
        percentCompleted: 0
        }
    },
    mounted(){
      var vm = this;
        axios.get('/carousel/list')
        .then(function (response) {  
          console.log(response.data.images);
            if (response.status == 200) {
              vm.images = response.data.images;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    methods: {
      onDrop: function(e) {
        var vm = this;
        e.stopPropagation();
        e.preventDefault();

        for(var i= 0; i< e.dataTransfer.files.length; i++){
          vm.files.push({
            image: URL.createObjectURL(e.dataTransfer.files[i]),
            name: e.dataTransfer.files[i].name,
            file: e.dataTransfer.files[i]
          });

          console.log(e.dataTransfer.files[i]);
          console.log(URL.createObjectURL(e.dataTransfer.files[i]));
        }

        console.log(vm.files);
      },
      onChange(e) {
        var vm = this;
        for(var i= 0; i< e.target.files.length; i++){
          vm.files.push({
            image: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
            file: e.target.files[i]
          });

          // vm.files.push(e.target.files[i]);
        }

        // this.createFile(vm.files[0]);
      },
      createFile(file) {
        if (!file.type.match('image.*')) {
          alert('Seleccione una imagén');
          return;
        }
        var img = new Image();
        var reader = new FileReader();
        var vm = this;
        let image;
        reader.onload = function(e) {
          image = e.target.result;
        }
        reader.readAsDataURL(file);
        return image;
      },
      removeFile(name) {
        var vm = this;
        let i = vm.files.indexOf( name );
        vm.files.splice( i, 1 );
      },
      deleteImage(id){
        var vm = this;
        axios.delete('/carousel/'+id)
        .then(function (response) {  
          let i = vm.images.indexOf( id );
          vm.images.splice( i, 1 );    
        })
        .catch(function (error) {
            console.log(error);
        });

      },
      cancel(){
        this.data = new FormData();
        this.files = [];
        this.addImage = false;
      },
      save(){
        var vm = this;
        vm.errors = [];
        vm.isFinish = false;
        
        if (vm.files.length <= 0) {
            vm.errors.push("No ha seleccionado imagenes")
        }
        
        var config = {
            headers: { 'Content-Type': 'multipart/form-data' } ,
            onUploadProgress: function(progressEvent) {
                this.percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                this.$forceUpdate();
            }.bind(this)
        };

        for(var i= 0; i< vm.files.length; i++){
          console.log(vm.files[i]);
          vm.data.append('images[]', vm.files[i].file);
          vm.data.append('type', this.type);
        }

        axios.post('/carousel/save', vm.data, config)
        .then(function (response) {
            if (response.status == 200) {
              vm.isFinish = true;
              vm.error = [];

                setTimeout(() => vm.isFinish=false, 5000);
              vm.data = new FormData();
              vm.files = [];
              vm.addImage = false;
              location.reload();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
      }
    }
}
</script>

<style>

#container-images input[type="file"] {
  /* position: absolute; */
  opacity: 0;
  z-index: -1;
}

.helper {
  width: 0;
}

#container-images  .img {
  border: 1px solid #f6f6f6;
}

#container-images  .drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
}

.img-preview{
     width: 80px;
    border-radius: 5px;
    border: 3px solid #b7b7b7;
}

.text-wrap{
  word-wrap: break-word;
  word-break: break-all;
}
</style>