<template>
  <div>
    <form :action="`/paymentStripe`" method="POST" id="payment-form" ref="paymentForm">
      <csrf-token></csrf-token>
      <input type="hidden" name="payment_id" :value="payment_id">
      <input type="hidden" name="total" :value="totalToPayF" />
      <input type="hidden" name="currency" :value="currency" />
      <input type="hidden" name="idcoupon" :value="idcoupon" />
      <div class="container"> 
          <div class="col-md-12">
            <stripe-checkout 
              :total="totalToPayF" 
              :currency="currency"
              @payment-successfully="paymentSuccessfully"
            ></stripe-checkout>
          </div>
      </div>
    </form>
    <div id="off">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totaltopay: {
      type: String
    },
    currency: {
      type: String,
      required: true
    },
    idcoupon: {
      type: String,
    }
  },
  data: function () {
    return {
      payment_id: null,
    }
  },
  computed: {
    totalToPayF() {
      return 20;
      //return this.totaltopay;
    }
  },
  mounted() {
  },
  methods: {
    paymentSuccessfully(paymentResult) {
      this.payment_id = paymentResult.id;
      this.$nextTick(() => {
        this.$refs.paymentForm.submit();
      })
    },
  }
}
</script>