<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card border-0 shadow-sm" v-if="coupons.length > 0">
        <div class="card-body pt-0 pb-0">
          <table class="table table-outlined mb-0">
            <thead>
              <tr>
                <th class="border-0">Cupón</th>
                <th class="border-0">Valor</th>
                <th class="border-0">Tipo</th>
                <th class="border-0">Tarifa Fija</th>
                <th class="border-0">Creado el</th>
                <th class="border-0">Válido hasta</th>
                <th class="border-0">Utilizado el</th>
                <th class="border-0"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coupon in coupons" :key="coupon.id">
                <td>
                  {{ coupon.code }}
                </td>
                <td>
                  <div v-if="editing === coupon.id">
                    <input class="form-control form-control-sm" v-model="valueEditing" name="value" />
                  </div>
                  <div v-else>
                    {{ coupon.value }}
                  </div>
                </td>
                <td>
                  {{ coupon.coupon_type.name }}
                </td>
                <td>
                  {{ coupon.fixed_rate}}
                  <div v-if="coupon.fixed_rate === 'true' || coupon.fixed_rate === true || coupon.fixed_rate">                    
                    Si
                  </div>
                  <div v-else>
                    No
                  </div>
                </td>
                <td>
                  {{ coupon.created_at }}
                </td>
                <td>
                  <div v-if="editing === coupon.id">
                    <input type="date" class="form-control form-control-sm" v-model="validUntilEditing" name="valid_until" />
                  </div>
                  <div v-else>
                    {{ coupon.valid_until }}
                  </div>
                </td>
                <td>
                  {{ coupon.used_at }}
                </td>
                <td>
                  <div v-if="editing === null && coupon.used_at === null">
                    <button type="button" class="btn text-info btn-link btn-sm" @click="edit(coupon)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <form class="d-inline" :action="coupon.delete_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="DELETE"></request-method>
                      <button type="submit" class="btn text-danger btn-link btn-sm">
                          <i class="fas fa-trash"></i>
                      </button>
                    </form>
                  </div>

                  <div v-if="editing === coupon.id">
                    <form :action="coupon.update_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="PUT"></request-method>
                      <input type="hidden" name="value" :value="valueEditing">
                      <input type="hidden" name="valid_until" :value="validUntilEditing">
                      <button type="submit" class="btn text-primary btn-link btn-sm">
                        <i class="fas fa-save"></i> Guardar
                      </button>
                    </form>
                    <button type="button" class="btn text-danger btn-link btn-sm" @click="cancel">
                      <i class="fas fa-times"></i> Cancelar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <slot name="links">

          </slot>
        </div>
      </div>
    
      <div class="d-flex align-items-center justify-content-center flex-column h-100" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-25 fill-dark-purple">
          <path d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z"/>
        </svg>
        <div class="text-center text-muted mt-3">
          No hay información por mostrar
        </div>
      </div>
      
    </div>
    <div class="col-md-3">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">Agregar cupones</h5>
          <form :action="storeUrl" method="POST">
            <div v-for="(error, index) in errors" :key="index" class="text-danger">
              <i class="fas fa-times"></i> {{ error }}
            </div>
            <csrf-token></csrf-token>
            <div class="form-group">
              <label for="value">Valor</label>
              <input type="text" v-model="newCoupon.value" class="form-control" name="value" :class="{'is-invalid': errors.value}">
            </div>
            <div class="form-group">
              <label for="amount">Cantidad de cupones</label>
              <input type="text" v-model="newCoupon.amount" class="form-control"  :class="{'is-invalid': errors.amount}" name="amount">
            </div>
            <div class="form-group">
              <input type="checkbox" v-model="newCoupon.isFixedRate" class="form-control" name="IsFixedRate">
              <label for="IsFixedRate">Aplicar tarifa fija</label>
            </div>
            <div class="form-group">
              <label for="valid_until">Válido hasta</label>
              <input type="date" v-model="newCoupon.validUntil" class="form-control" name="valid_until">
            </div>
            <div class="form-group">
              <label for="type">Tipo</label>
              <select name="coupon_type_id" v-model="newCoupon.couponTypeId" id="type" class="form-control">
                <option v-for="type in couponTypeOptions" :key="type.value" :value="type.value">{{ type.text }}</option>
              </select>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      coupons: {
        type: Array,
        required: true,
      },
      couponTypes: {
        type: Array,
        required: true,
      },
      errors: {
        type: Array,
        required: true
      },
      storeUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        editing: null,
        valueEditing: null,
        validUntilEditing: null,
        newCoupon: {
          value: null,
          amount: 1,
          fixedRate: false,
          validUntil: null,
          couponTypeId: null,
        }
      }
    },
    computed: {
      couponTypeOptions() {
        return [{ value: null, text: 'Elija un tipo' }]
          .concat(this.couponTypes.map(ct => {
            return {
              value: ct.id,
              text: ct.name,
            };
          }));
      }
    },
    methods: {
      edit(coupon) {
        console.log(coupon);
        this.editing = coupon.id;
        this.valueEditing = coupon.value;
        this.validUntilEditing = coupon.valid_until;
      },
      cancel() {
        this.editing = null;
        this.valueEditing = null;
        this.validUntilEditing = null;
      }
    }
  }
</script>
