import * as moment from "moment";

export default {
  props: {
    now: {
      tpye: String,
      required: true,
    },
    years: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      now_: null,
      holderName: '',
      cardNumber: '',
      cvv2: '',
      expirationMonth: null,
      expirationYear: null,
      states: {
        cardNumber: true,
        cvv2: true,
        expirationDate: true,
      }
    };
  },
  mounted() {
    this.now_ = moment(this.now);
  },
  computed: {
    monthOptions() {
      return [{value: null, text: 'Mes'}].concat(['01','02','03','04','05','06','07','08','09','10','11','12'].map(m => {
        return {
          value: m, text: m
        };
      }));
    },
    yearOptions() {
      return [{value: null, text: 'Año'}].concat(this.years.map(y => {
        return {
          value: y - 2000, text: y // this valid until 2100
        };
      }));
    }
  },
  methods: {
    cardNumberHandler(e) {
      this.cardNumber = e.target.value;
    },
    validateCardNumber() {
      this.$set(this.states, 'cardNumber', OpenPay.card.validateCardNumber(this.cardNumber));
    },
    cvv2Handler(e) {
      this.cvv2 = e.target.value;
    },
    validatCvv2() {
      this.$set(this.states, 'cvv2', OpenPay.card.validateCVC(this.cvv2));
    },
    expirationMonthHandler(e) {
      this.expirationMonth = e.target.value;
    },
    expirationYearHandler(e) {
      this.expirationYear = e.target.value;
    },
    validateExpirationDate() {
      this.$set(this.states, 'expirationDate', OpenPay.card.validateExpiry(this.expirationMonth, this.expirationYear));
    },
  },
  render(h) {
    let {
      holderName,
      cardNumber,
      cvv2,
      expirationMonth,
      expirationYear,
      states,
      monthOptions,
      yearOptions,
    } = this;

    return h('div', [
      this.$scopedSlots.default({
        holderName,
        cardNumber,
        cvv2,
        expirationMonth,
        expirationYear,
        states,
        monthOptions,
        yearOptions,
        handlers: {
          cardNumber: this.cardNumberHandler,
          expirationMonth: this.expirationMonthHandler,
          expirationYear: this.expirationYearHandler,
          cvv2: this.cvv2Handler
        },
        validators: {
          cardNumber: this.validateCardNumber,
          expirationDate: this.validateExpirationDate,
          cvv2: this.validatCvv2
        }
      })
    ]);
  },
}
