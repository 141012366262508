<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group row">
          <label class="col-md-2">Descripción</label>
          <div class="input-group col-md-4">
            <input type="hidden" :name="name" v-model="listDescriptions_.join('|')">
            <input type="text" name="nameDescriptions" class="form-control border-0 bg-gray-200" v-model="description" v-on:keydown.enter.prevent="AddDescription($event, description, 1)">
          </div>
          <div class="col-md-6">
            <button type="button" class="btn btn-outline-primary" v-on:click="AddDescription($event, description, 1)"> Agregar</button>
          </div>
        </div>
      </div> 
      <div class="col-12">
        <div class="card bg-gray-200 border-0">
          <div class="card-body">
            <div class="d-flex align-content-end flex-wrap">
              <div class="col-md-4 col-sm-12 col-xs-4" v-for="(item,index) in listDescriptions_">
                <button type="button" class="ml-2 btn btn.default text-danger" v-on:click="RemoveDescription(index,1)">
                  <i class="fas fa-times" style="font-size: 20px;"></i>
                </button>
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      listDescriptions:{
        type: String
      },
      name:{
        type: String
      }

    },
    data() {
      return {
        listDescriptions_: [],
        description : null  
      }
    },
    mounted() {
      this.listDescriptions_ = (this.listDescriptions != null && this.listDescriptions != "") ? this.listDescriptions.split("|") : [];
    },
    computed: {

    },
    methods:{
      //nuevos
      AddDescription(event, description){
        if(description != null && description != ''){
          this.listDescriptions_.push(description);
          this.description  = null;
        }
      },
      RemoveDescription(index){        
        this.listDescriptions_.splice(index,1);
      }

    }
  }
</script>
