<template>
  <div>
    <div class="row">
      <select v-model="chanelSelect" @change="onChangeChanel()" style="margin-right: 10px;" class="form-control" name="chanel">
        <option :value="null">Seleccione un canal</option>
        <option :value="'all'">Todos los canales</option>
        <option v-for="chan in this.chanel" :key="chan.id" :value="chan.id">{{ chan.name }}</option>
      </select>

      <select v-if="this.listuser.length > 0" v-model="userSelect" style="margin-right: 10px;" class="form-control" name="user">
        <option :value="'all'">Todos los usuarios</option>
        <option v-for="user in this.listuser" :key="user.id" :value="user.id">{{ user.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
          chanel: {
                  type: Array,
                  required: true,
              },
          users: {
              type: Array,
              required: true,
          }
        },
  data() {
    return {
      chanelSelect: null,
      userlSelect: null,
      listuser:[],
    }
  },
  computed: {
  },
  methods: {
    onChangeChanel(val){
      this.listuser = [];
      if(this.chanelSelect != 'all'){
        this.users.forEach(element => {
          if(element.id_chanel == this.chanelSelect){
            this.listuser.push({
              id: element.id,
              name: element.name
            });
          }
        });
      }
    }
  }
}
</script>