<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card border-0 shadow-sm" v-if="memberships.length > 0">
        <div class="card-body">
          <table class="table table-outlined">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Precio</th>
                <th>Valor</th>
                <th>Creado el</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="membership in memberships" :key="membership.id">
                <td>
                  {{ membership.name }}
                </td>
                <td>
                  <div v-if="editing === membership.id">
                    <input class="form-control form-control-sm" v-model="priceEditing" name="price" />
                  </div>
                  <div v-else>
                    {{ membership.price }}
                  </div>
                </td>
                <td>
                  <div v-if="editing === membership.id">
                    <input class="form-control form-control-sm" v-model="valueEditing" name="value" />
                  </div>
                  <div v-else>
                    {{ membership.value }}
                  </div>
                </td>
                <td>
                  {{ membership.created_at }}
                </td>
                <td>
                  <div v-if="editing === null">
                    <button type="button" class="btn text-info btn-link btn-sm" @click="edit(membership)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <form class="d-inline" :action="membership.delete_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="DELETE"></request-method>
                      <button type="submit" class="btn text-danger btn-link btn-sm">
                          <i class="fas fa-trash"></i>
                      </button>
                    </form>
                  </div>

                  <div v-if="editing === membership.id">
                    <form :action="membership.update_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="PUT"></request-method>
                      <input type="hidden" name="value" :value="valueEditing">
                      <input type="hidden" name="price" :value="priceEditing">
                      <button type="submit" class="btn text-primary btn-link btn-sm">
                        <i class="fas fa-save"></i> Guardar
                      </button>
                    </form>
                    <button type="button" class="btn text-danger btn-link btn-sm" @click="cancel">
                      <i class="fas fa-times"></i> Cancelar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <slot name="links">

          </slot>
        </div>
      </div>
    
      <div class="alert alert-info" v-else>Aún no hay membresias</div>
      
    </div>
    <div class="col-md-3">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">Agregar membresia</h5>
          <form :action="storeUrl" method="POST">
            <div v-for="(error, index) in errors" :key="index" class="text-danger">
              <i class="fas fa-times"></i> {{ error }}
            </div>
            <csrf-token></csrf-token>
            <div class="form-group">
              <label for="name">Nombre</label>
              <input type="text" class="form-control" name="name" :class="{'is-invalid': errors.name}">
            </div>
            <div class="form-group">
              <label for="value">Precio</label>
              <input type="text" class="form-control"  :class="{'is-invalid': errors.price}" name="price">
            </div>
            <div class="form-group">
              <label for="value">Valor</label>
              <input type="text" class="form-control"  :class="{'is-invalid': errors.value}" name="value">
            </div>
        
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      memberships: {
        type: Array,
        required: true,
      },
      errors: {
        type: Array,
        required: true
      },
      storeUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        editing: null,
        priceEditing: null, 
        valueEditing: null,
      }
    },
    methods: {
      edit(membership) {
        this.editing = membership.id;
        this.valueEditing = membership.value;
        this.priceEditing = membership.price;
      },
      cancel() {
        this.editing = null;
        this.valueEditing = null;
        this.priceEditing = null;
      }
    }
  }
</script>
