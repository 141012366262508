export default {
    methods: {
        hasServerError(key) {
            return Object.hasOwnProperty.call(this.$_errors, key);
        },
        serverError(key, _default = '') {
            return this.hasServerError(key) ? this.$_errors[key] : _default;
        },
        hasOld(key) {
            return Object.hasOwnProperty.call(this.$_old, key);
        },
        old(key, _default = '') {
            return this.hasOld(key) ? this.$_old[key] : _default;
        }
    },
    computed: {
        $_errors: () => window._serverData && window._serverData._errors || {},
        $_old: () => window._serverData && window._serverData._old || {}
    }
}
