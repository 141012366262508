<template>
  <div class="d-flex position-relative flex-nowrap">
    <div class="position-fixed w-183">
      <slot name="title" :title="title">{{ title }}</slot>
    </div>
    <div class="ml-183">
      <div class="d-flex flex-nowrap">
        <div class="av-column text-center mr-2 mb-2" v-for="category in categories" :key="category.id">
          <slot :category="category">{{ category.descriptions[0].name }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      }
    },
  }
</script>

<style scoped>
  .av-column {
    width: 250px;
    display: inline-block;
  }
  .w-183 {
    width: 183px;
    z-index: 5;
    height: 45px;
    background: white;
  }
  .ml-183 {
    margin-left: 183px;
  }
</style>
