<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card border-0 shadow-sm" v-if="couponTypes.length > 0">
        <div class="card-body pt-0 pb-0">
          <table class="table table-outlined mb-0">
            <thead>
              <tr>
                <th class="border-0">Nombre</th>
                <th class="border-0">Creado el</th>
                <th class="border-0"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="couponType in couponTypes" :key="couponType.id">
                <td>
                  <div v-if="editing === couponType.id">
                    <input class="form-control form-control-sm" v-model="nameEditing" name="name" />
                  </div>
                  <div v-else>
                    {{ couponType.name }}
                  </div>
                </td>
                <td>
                  {{ couponType.created_at }}
                </td>
               
                <td>
                  <div v-if="editing === null && couponType.used_at === null">
                    <button type="button" class="btn text-info btn-link btn-sm" @click="edit(couponType)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <form class="d-inline" :action="couponType.delete_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="DELETE"></request-method>
                      <button type="submit" class="btn text-danger btn-link btn-sm">
                          <i class="fas fa-trash"></i>
                      </button>
                    </form>
                  </div>

                  <div v-if="editing === couponType.id">
                    <form :action="couponType.update_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="PUT"></request-method>
                      <input type="hidden" name="name" :value="nameEditing">
                      <button type="submit" class="btn text-primary btn-link btn-sm">
                        <i class="fas fa-save"></i> Guardar
                      </button>
                    </form>
                    <button type="button" class="btn text-danger btn-link btn-sm" @click="cancel">
                      <i class="fas fa-times"></i> Cancelar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <slot name="links">

          </slot>
        </div>
      </div>
    
      <div class="d-flex align-items-center justify-content-center flex-column h-100" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-25 fill-dark-purple">
          <path d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z"/>
        </svg>
        <div class="text-center text-muted mt-3">
          No hay información por mostrar
        </div>
      </div>
      
    </div>
    <div class="col-md-3">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">Agregar tipo</h5>
          <form :action="storeUrl" method="POST">
            <div v-for="(error, index) in errors" :key="index" class="text-danger">
              <i class="fas fa-times"></i> {{ error }}
            </div>
            <csrf-token></csrf-token>
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" name="name" :class="{'is-invalid': errors.name}">
            </div>
            
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      couponTypes: {
        type: Array,
        required: true,
      },
      errors: {
        type: Array,
        required: true
      },
      storeUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        editing: null,
        nameEditing: null,
      }
    },
    methods: {
      edit(couponType) {
        this.editing = couponType.id;
        this.nameEditing = couponType.name;
      },
      cancel() {
        this.editing = null;
        this.nameEditing = null;
      }
    }
  }
</script>
