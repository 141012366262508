<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  props: ['title', 'labels', 'subscriptions'],
  mounted() {
    let data = {
        labels: this.labels,
        datasets: [{
            data: this.subscriptions,
            backgroundColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1
        }
      ]
    };

    let options = {
      responsive: true,
        title: {
            display: true,
            text: this.title
        },
    };

    this.renderChart(data, options);
  },
  watch: {
    subscriptions: {
      deep: true,
      handler() {
        console.log('shoud update');
        let data = {
        labels: this.labels,
        datasets: [{
            data: this.subscriptions,
            backgroundColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1
        }
      ]
    };

    let options = {
      responsive: true,
        title: {
            display: true,
            text: this.title
        },
    };

    this.renderChart(data, options);
      }
    }
  }
}
</script>