<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card border-0 shadow-sm py-2">
                    <form :action="`/new-membership-year-update-api`" method="POST" id="payment-form" ref="paymentForm">
                        <csrf-token></csrf-token>
                        <input type="hidden" name="idClient" id="idClient" :value="client.id">
                        <input type="hidden" name="number_contract" id="number_contract" :value="client.number_contract">
                        <input type="hidden" name="fechacompra" id="fechacompra" :value="client.fechac">
                        <input type="hidden" name="fecharenovacion" id="fecharenovacion" :value="client.fecharenovacion">
                        <input type="hidden" name="idioma" id="idioma" :value="client.lang">
                        <input type="hidden" name="estadocivil" id="estadocivil" :value="client.estadocivil">
                        <input type="hidden" name="nametitular" id="nametitular" :value="client.name">
                        <input type="hidden" name="apellidotitular" id="apellidotitular" :value="client.apellidotitular">
                        <input type="hidden" name="email" id="email" :value="client.email1">
                        <input type="hidden" name="namecotitular" id="namecotitular" :value="client.namecotitular">
                        <input type="hidden" name="apellidocotitular" id="apellidocotitular" :value="client.apellidocotitular">
                        <input type="hidden" name="email2" id="email2" :value="client.email2">
                        <input type="hidden" name="international_code_home" id="international_code_home" :value="client.international_code_home">
                        <input type="hidden" name="numerocasa" id="numerocasa" :value="client.numerocasa">
                        <input type="hidden" name="international_code_cel_titular" id="international_code_cel_titular" :value="client.international_code_cel_titular">
                        <input type="hidden" name="telefonotitular" id="telefonotitular" :value="client.telefonotitular">
                        <input type="hidden" name="international_code_cel_cotitular" id="international_code_cel_cotitular" :value="client.international_code_cel_cotitular">
                        <input type="hidden" name="telefonocotitular" id="telefonocotitular" :value="client.telefonocotitular">
                        <input type="hidden" name="country" id="country" :value="client.country">
                        <input type="hidden" name="state" id="state" :value="client.state">
                        <input type="hidden" name="city" id="city" :value="client.city">
                        <input type="hidden" name="direccion" id="direccion" :value="client.direccion">
                        <input type="hidden" name="cp" id="cp" :value="client.cp">
                        <input type="hidden" name="precio" id="precio" :value="client.precio">
                        <input type="hidden" name="moneda" id="moneda" :value="client.moneda">
                        <input type="hidden" name="vipclubweeks" id="vipclubweeks" :value="client.vipclubweeks">
                        <input type="hidden" name="smartweeks" id="smartweeks" :value="client.smartweeks">
                        <input type="hidden" name="hotelescheck" id="hotelescheck" :value="client.hotelescheck">
                        <input type="hidden" name="tourscheck" id="tourscheck" :value="client.tourscheck">
                        <input type="hidden" name="cruceroscheck" id="cruceroscheck" :value="client.cruceroscheck">
                        <input type="hidden" name="autoscheck" id="autoscheck" :value="client.autoscheck">
                        <input type="hidden" name="trasladoscheck" id="trasladoscheck" :value="client.trasladoscheck">
                        <input type="hidden" name="yatescheck" id="yatescheck" :value="client.yatescheck">
                        <input type="hidden" name="descTravelVoucher" id="descTravelVoucher" :value="client.descTravelVoucher">
                        <input type="hidden" name="intervalinternacional" id="intervalinternacional" :value="client.intervalinternacional">
                        <input type="hidden" name="certificadoautos" id="certificadoautos" :value="client.certificadoautos">
                        <input type="hidden" name="recompensayates" id="recompensayates" :value="client.recompensayates">
                        <input type="hidden" name="recompensacondominios" id="recompensacondominios" :value="client.recompensacondominios">
                        <input type="hidden" name="agente" id="agente" :value="client.agente">
                        <input type="hidden" name="international_code" id="international_code" :value="client.international_code">
                        <input type="hidden" name="numTravelVoucher" id="numTravelVoucher" :value="client.numTravelVoucher">
                        <input type="hidden" name="totalintervalinternacional" id="totalintervalinternacional" :value="client.totalintervalinternacional">
                        <input type="hidden" name="certificadoautosval" id="certificadoautosval" :value="client.certificadoautosval">
                        <input type="hidden" name="recompensayatesval" id="recompensayatesval" :value="client.recompensayatesval">
                        <input type="hidden" name="resort" id="resort" :value="client.resort">
                        <input type="hidden" name="status" id="status" :value="client.status">
                        <input type="hidden" name="lastminuteweeks" id="lastminuteweeks" :value="client.lastminuteweeks">
                        <input type="hidden" name="rentaautocheck" id="rentaautocheck" :value="client.rentaautocheck">
                        <input type="hidden" name="dealscheck" id="dealscheck" :value="client.dealscheck">
                        <input type="hidden" name="price" id="price" :value="client.price">
                        <input type="hidden" name="payinitpercentaje" id="payinitpercentaje" :value="client.payinitpercentaje">
                        <input type="hidden" name="payinit" id="payinit" :value="client.payinit">
                        <input type="hidden" name="adp1" id="adp1" :value="client.adp1">
                        <input type="hidden" name="dateadp1" id="dateadp1" :value="client.dateadp1">
                        <input type="hidden" name="adp2" id="adp2" :value="client.adp2">
                        <input type="hidden" name="dateadp2" id="dateadp2" :value="client.dateadp2">
                        <input type="hidden" name="adp3" id="adp3" :value="client.adp3">
                        <input type="hidden" name="dateadp3" id="dateadp3" :value="client.dateadp3">
                        <input type="hidden" name="tarifacierre" id="tarifacierre" :value="client.tarifacierre">
                        <input type="hidden" name="status" id="status" :value="client.status">
                        <input type="hidden" name="financiamiento" id="financiamiento" :value="client.financiamiento">
                        <input type="hidden" name="numbermonth" id="numbermonth" :value="client.numbermonth">
                        <input type="hidden" name="montomensualidad" id="montomensualidad" :value="client.montomensualidad">
                        <input type="hidden" name="currency" id="currency" :value="client.currency">
                        <input type="hidden" name="intereses" id="intereses" :value="client.intereses">
                        <input type="hidden" name="factoria" id="factoria" :value="client.factoria">
                        <input type="hidden" name="primermensualidad" id="primermensualidad" :value="client.primermensualidad">
                        <input type="hidden" name="nacionalidadtitular" id="nacionalidadtitular" :value="client.nacionalidadtitular">
                        <input type="hidden" name="nacionalidadcotitular" id="nacionalidadcotitular" :value="client.nacionalidadcotitular">
                        <input type="hidden" name="typemembership" id="typemembership" :value="client.typemembership">
                        <input type="hidden" name="anios" id="anios" :value="client.anios">
                        <input type="hidden" name="beneficiario1" id="beneficiario1" :value="client.beneficiario1">
                        <input type="hidden" name="beneficiario2" id="beneficiario2" :value="client.beneficiario2">
                        <input type="hidden" name="beneficiario3" id="beneficiario3" :value="client.beneficiario3">
                        <input type="hidden" name="beneficiario4" id="beneficiario4" :value="client.beneficiario4">
                        <input type="hidden" name="beneficiario5" id="beneficiario5" :value="client.beneficiario5">
                        <input type="hidden" name="parentesco1" id="parentesco1" :value="client.parentesco1">
                        <input type="hidden" name="parentesco2" id="parentesco2" :value="client.parentesco2">
                        <input type="hidden" name="parentesco3" id="parentesco3" :value="client.parentesco3">
                        <input type="hidden" name="parentesco4" id="parentesco4" :value="client.parentesco4">
                        <input type="hidden" name="parentesco5" id="parentesco5" :value="client.parentesco5">
                        <input type="hidden" name="birthdate" id="birthdate" :value="client.birthdate">
                        <input type="hidden" name="birthdatecotitular" id="birthdatecotitular" :value="client.birthdatecotitular">
                        <input type="hidden" name="tableFriend" id="tableFriend" :value="tableFriend">
                        <input type="hidden" name="idFriend" id="idFriend" :value="friend.id_client">
                        <input type="hidden" name="automaticpay" id="automaticpay" :value="automaticpay">
                        <input type="hidden" name="periodicidad" id="periodicidad" :value="client.periodicidad">
                        <input type="hidden" name="international_code_home_coowner" id="international_code_home_coowner" :value="client.international_code_home_coowner">
                        <input type="hidden" name="numerocasacoowner" id="numerocasacoowner" :value="client.numerocasacoowner">
                    </form>
                
                    <div class="card-body pt-0 pb-0">
                        <button class="btn btn-primary" v-if="editForm" v-on:click="editFormulario()">Editar</button>
                        <button class="btn btn-danger" v-if="!editForm" v-on:click="cancelEditFormulario()">Cancel</button>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Login Id<span style="color:red">*</span></label>
                                    <input name="number_contract" type="string" autocomplete="off" required class="form-control" disabled="true" v-model="client.loginID" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Numero de Contrato<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Contract Number<span style="color:red">*</span></label>
                                    <input disabled="true" maxlength="15" @change="validData()" :onkeyup="validData()" name="number_contract" :class="contractvalid" type="string" autocomplete="off" required class="form-control" v-model="client.number_contract" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Fecha de Compra<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Purchase Date<span style="color:red">*</span></label>
                                    <input disabled="true" onkeydown="return false" name="fechadecompra" :max="theDate" @change="onChangeDate()" type="date" required :class="fechacompravalid" v-model="client.fechac" />
                                </div>
                            </div>
                            
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Fecha de Expiracionspan style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Expiration Date<span style="color:red">*</span></label>
                                    <input name="fechadecompra" type="date" required class="form-control" disabled="true" v-model="client.fecharenovacion" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Status<span style="color:red">*</span></label>
                                    <select disabled="true" @change="validData()" v-if="this.lang == 'es'" :class="statusvalid" v-model="client.status" name="idioma" required>
                                        <option value="">Seleccione una opcion:</option>
                                        <option v-for="option in status" :value="option.Id">{{option.Nombre}}</option>
                                    </select>
                                    <select disabled="true" v-if="this.lang == 'en'" @change="validData()" :class="statusvalid" v-model="client.status" name="idioma" required>
                                        <option value="">Select an option:</option>
                                        <option v-for="option in status" :value="option.Id">{{option.NombreEn}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <label for="name" class="form-label" v-if="this.lang == 'es'">Años<span style="color:red">*</span></label>
                                <label for="name" class="form-label" v-if="this.lang == 'en'">Years<span style="color:red">*</span></label>
                                <select disabled="true" @change="validData()" name="anios" :class="yearsvalid" v-model="client.anios">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="99">Lifetime</option>
                                </select>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" v-if="this.lang == 'es'" class="form-label">Tipo de Membresia<span style="color:red">*</span></label>
                                    <label for="name" v-else class="form-label">Membership Type<span style="color:red">*</span></label>
                                    <select disabled="true" @change="validData()" v-if="this.lang == 'es'" :class="membershipvalid" v-model="client.typemembership" name="typemembership" required>
                                        <option value="">Seleccione una opcion:</option>
                                        <option v-for="option in tipomembresias" :value="option.Id">{{option.Nombre}}</option>
                                    </select>
                                    <select disabled="true" v-if="this.lang == 'en'" @change="validData()" :class="membershipvalid" v-model="client.typemembership" name="typemembership" required>
                                        <option value="">Select an option:</option>
                                        <option v-for="option in tipomembresias" :value="option.Id">{{option.Nombre}}</option>
                                    </select>
                                </div>
                            </div>
                            

                            <div class="col">
                                <div class="form-group">
                                    <label for="name" v-if="this.lang == 'es'" class="form-label">Idioma<span style="color:red">*</span></label>
                                    <label for="name" v-if="this.lang == 'en'" class="form-label">Lang<span style="color:red">*</span></label>
                                    <select disabled="true" @change="validData()" v-if="this.lang == 'es'" :class="langvalid" v-model="client.lang" name="idioma" required>
                                        <option value="">Seleccione una opcion:</option>
                                        <option v-for="option in idiomas" :value="option.Id">{{option.Idioma}}</option>
                                    </select>
                                    <select disabled="true" v-if="this.lang == 'en'" @change="validData()" :class="langvalid" v-model="client.lang" name="idioma" required>
                                        <option value="">Select an option:</option>
                                       <option v-for="option in idiomas" :value="option.Id">{{option.IdiomaEn}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'es'">Informacion del socio</h3>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'en'">Member information</h3>
                        <h5><b>Owner</b></h5>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Nombre titular</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Owner Name<span style="color:red">*</span></label>
                                            <input :disabled="editForm" maxlength="50" @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.name,'name')" type="string" autocomplete="off" required :class="ownernamevalid" v-model="client.name" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Apellido titular</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Owner Last Name<span style="color:red">*</span></label>
                                            <input :disabled="editForm" maxlength="50" @change="validData()" name="apellidotitular" :onkeyup="capitalizarPrimeraLetra(client.apellidotitular,'apellido')" type="string" autocomplete="off" required :class="ownerlastnamevalid" v-model="client.apellidotitular" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Correo #1</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Owner Email<span style="color:red">*</span></label>
                                            <input :disabled="editForm" maxlength="50" @change="validData()" name="email1" type="string" autocomplete="off" required :class="owneremailvalid" v-model="client.email1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                     <div class="col-3">
                                        <label for="last_name" class="form-label" v-if="this.lang == 'es'">Codigo<span style="color:red">*</span></label>
                                        <label for="last_name" class="form-label" v-if="this.lang == 'en'">Code<span style="color:red">*</span></label>
                                        <select :disabled="editForm" @change="validData()" name="international_code" id="international_code" required :class="codeownerhomevalid" v-model="client.international_code_home">
                                            <option :value="null">Select an option:</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.Lada">{{ item.Nombre }} {{ item.Lada }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Telefono de casa<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Home Phone<span style="color:red">*</span></label>
                                            <input :disabled="editForm" maxlength="20" @change="validData()" type="text" autocomplete="off" required :class="numberhomevalid" v-model="client.numerocasa" @input="acceptNumber0"/>
                                        </div>
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                   <div class="col-3">
                                        <label for="last_name" class="form-label" v-if="this.lang == 'es'">Codigo</label>
                                        <label for="last_name" class="form-label" v-if="this.lang == 'en'">Code</label>
                                        <select :disabled="editForm" @change="validData()" name="international_code" id="international_code" required :class="international_code_cel_titular_valid" v-model="client.international_code_cel_titular">
                                            <option :value="null">Select an option:</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.Lada">{{ item.Nombre }} {{ item.Lada }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Telefono(titular)</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Phone Number</label>
                                            <input :disabled="editForm" maxlength="20" @change="validData()" name="telefonotitular" type="text" autocomplete="off" required :class="telefonotitularvalid" v-model="client.telefonotitular" @input="acceptNumber"/>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Fecha de Nacimiento(titular)</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Date of Birth</label>
                                            <input :disabled="editForm" :max="theDateBirt" name="birthdate" type="date" autocomplete="off" class="form-control" v-model="client.birthdate" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Nacionalidad titular</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Owner Nationality</label>
                                    <select :disabled="editForm" v-if="this.lang == 'es'" name="pais" class="form-control" v-model="client.nacionalidadtitular">
                                          <option  v-for="option in codes" :key="option.Id" :value="option.Id">
                                            {{ option.Nacionalidad }}
                                        </option>
                                       
                                    </select>
                                    <select :disabled="editForm" v-if="this.lang == 'en'" name="pais" class="form-control" v-model="client.nacionalidadtitular">
                                          <option v-for="option in codes" :key="option.Id" :value="option.Id">
                                            {{ option.NacionalidadEn }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div><br>
                        <h5 v-if="this.lang == 'en'"><b>Marital Status</b></h5>
                        <h5 v-if="this.lang == 'es'"><b>Estado Civil</b></h5>
                        <div class="row">
                            <div class="col-4">
                                <select :disabled="editForm" @change="validData()"  v-if="this.lang == 'es'" v-model="client.estadocivil" class="form-control">
                                    <option v-for="option in estadosciviles" :key="option.Id" :value="option.Id">
                                            {{ option.Nombre }}
                                    </option>
                                </select>
                                <select :disabled="editForm" @change="validData()"  v-if="this.lang == 'en'" v-model="client.estadocivil" class="form-control">
                                    <option v-for="option in estadosciviles" :key="option.Id" :value="option.Id">
                                            {{ option.NombreEn }}
                                    </option>
                                </select>
                            </div>
                        </div><br>
                    
                        <h5><b>Co-Owner</b></h5>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Nombre(co-titular)</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Co-Owner Name (Optional)</label>
                                    <input :disabled="editForm" maxlength="50" @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.namecotitular,'nameco')" name="namecotitular" type="string" autocomplete="off" required class="form-control" v-model="client.namecotitular" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Apellido(co-titular)</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Co-Owner Last Name (Optional)</label>
                                    <input :disabled="editForm" maxlength="50" :onkeyup="capitalizarPrimeraLetra(client.apellidocotitular,'apellidoco')" name="apellidocotitular" type="string" autocomplete="off" required class="form-control" v-model="client.apellidocotitular" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Correo #2 (Co-titular)(Opcional)</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Co-Owner Email (Optional)</label>
                                    <input :disabled="editForm" name="email2" type="string" autocomplete="off" required class="form-control" v-model="client.email2" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                     <div class="col-3">
                                        <label for="last_name" class="form-label" v-if="this.lang == 'es'">Codigo</label>
                                        <label for="last_name" class="form-label" v-if="this.lang == 'en'">Code</label>
                                        <select :disabled="editForm" @change="validData()" name="international_code" id="international_code" required :class="codecoownerhomevalid" v-model="client.international_code_home_coowner">
                                            <option :value="null">Select an option:</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.Lada">{{ item.Nombre }} {{ item.Lada }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Telefono de casa</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Home Phone</label>
                                            <input :disabled="editForm" maxlength="20" @change="validData()" type="text" autocomplete="off" required :class="numberhomecoownervalid" v-model="client.numerocasacoowner" @input="acceptNumber10"/>
                                        </div>
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-3">
                                        <label for="last_name" class="form-label" v-if="this.lang == 'es'">Codigo</label>
                                        <label for="last_name" class="form-label" v-if="this.lang == 'en'">Code</label>
                                        <select :disabled="editForm" @change="validData()" name="international_code" id="international_code" required :class="international_code_cel_cotitularvalid" v-model="client.international_code_cel_cotitular">
                                            <option value="" v-if="this.lang == 'es'">Seleccione una opcion:</option>
                                            <option value="" v-if="this.lang == 'en'">Select an option:</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.Lada">{{ item.Nombre }} {{ item.Lada }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label for="name" class="form-label" v-if="this.lang == 'es'">Telefono(co-titular)</label>
                                        <label for="name" class="form-label" v-if="this.lang == 'en'">Phone Number</label>
                                        <input :disabled="editForm" @change="validData()" maxlength="20" @input="acceptNumber2" name="telefonocotitular" type="string" autocomplete="off" required :class="telefonocotitularvalid" v-model="client.telefonocotitular" />
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="this.lang == 'es'">Fecha de Nacimiento(titular)</label>
                                            <label for="name" class="form-label" v-if="this.lang == 'en'">Date of Birth</label>
                                            <input :disabled="editForm" :max="theDateBirt" name="birthdatecotitular" type="date" autocomplete="off" class="form-control" v-model="client.birthdatecotitular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Nacionalidad cotitular</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Co-Owner Nationality</label>
                                    <select :disabled="editForm" v-if="this.lang == 'es'" name="pais" class="form-control" v-model="client.nacionalidadcotitular">
                                          <option  v-for="option in codes" :key="option.Id" :value="option.Id">
                                            {{ option.Nacionalidad }}
                                        </option>
                                       
                                    </select>
                                    <select :disabled="editForm" v-if="this.lang == 'en'" name="pais" class="form-control" v-model="client.nacionalidadcotitular">
                                          <option v-for="option in codes" :key="option.Id" :value="option.Id">
                                            {{ option.NacionalidadEn }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="pais" class="form-label" v-if="this.lang == 'es'">Pais<span style="color:red">*</span></label>
                                    <label for="pais" class="form-label" v-if="this.lang == 'en'">Country<span style="color:red">*</span></label>
                                    <select :disabled="editForm" name="pais" @change="getstates()" :class="countryvalid" v-model="client.country
">
                                        <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col" v-if="stateOptions.length">
                                <div class="form-group">
                                    <label for="estado" class="form-label" v-if="this.lang == 'es'">Estado<span style="color:red">*</span></label>
                                    <label for="estado" class="form-label" v-if="this.lang == 'en'">State<span style="color:red">*</span></label>
                                    <select :disabled="editForm" v-if="this.lang == 'es'" :class="statevalid" @change="validData()" id="state" name="state" aria-label="state" v-model="client.state">
                                        <option v-for="option in stateOptions" :key="option.Id" :value="option.Id">
                                            {{ option.Nombre }}
                                        </option>
                                    </select>
                                    <select :disabled="editForm" v-if="this.lang == 'en'" :class="statevalid" @change="validData()" id="state" name="state" aria-label="state" v-model="client.state">
                                        <option v-for="option in stateOptions" :key="option.Id" :value="option.Id">
                                            {{ option.NombreEng }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Ciudad<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">City<span style="color:red">*</span></label>
                                    <input :disabled="editForm" name="ciudad" @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.city,'city')" id="name" type="string" autocomplete="off" required :class="cityvalid" v-model="client.city" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Direccion<span style="color:red">*</span></label>
                                    <label for="name" v-if="this.lang == 'en'">Address<span style="color:red">*</span></label>
                                    <input :disabled="editForm" maxlength="80" name="direccion" @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.direccion,'direccion')" type="string" autocomplete="off" required :class="addressvalid" v-model="client.direccion" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Codigo postal<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Zip Code<span style="color:red">*</span></label>
                                    <input :disabled="editForm" maxlength="10" name="cp" type="string" @change="validData()" autocomplete="off" required :class="zipcodevalid" @input="acceptNumber3" v-model="client.cp" />
                                </div>
                            </div>
                        </div>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'es' && !this.tableFriend">Beneficiarios</h3>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'en' && !this.tableFriend">Beneficiaries</h3>
                        
                        <div class="row">
                            <div class="col">
                            <label v-if="this.lang == 'es'" style="font-size: 18px;">Nombre</label>
                            <label v-if="this.lang == 'en'" style="font-size: 18px;">Name</label>
                            </div>
                            <div class="col">
                            <label v-if="this.lang == 'es'" style="font-size: 18px;">Parentesco</label>
                            <label v-if="this.lang == 'en'" style="font-size: 18px;">Relation</label>
                            </div>
                            <div class="col">
                            <label v-if="this.lang == 'es'" style="font-size: 18px;">Apellido</label>
                            <label v-if="this.lang == 'en'" style="font-size: 18px;">Last Name</label>
                            </div>
                        </div>

                        <div class="row" v-for="bene in this.member.beneficiaries">
                            <div class="col">
                                
                                <input disabled="true" style="margin-bottom: 20px;" type="text" class="form-control" name="beneficiario1" v-model="bene.Nombre">
                            </div>
                            <div class="col">
                                <input disabled="true" style="margin-bottom: 20px;" type="text" class="form-control" name="beneficiario1" v-model="bene.ApellidoPaterno">
                            </div>
                            <div class="col">
                                <select disabled="true" class="form-control" name="" id="">
                                    <option v-for="option in parentesco" :selected="bene.Parentesco == option.Id" :value="option.Id">
                                            {{ lang == 'en'? option.NombreEn : option.Nombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <hr>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'es'">Beneficios de la membresia</h3>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'en'">Membership Benefits</h3>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Vip weeks por año</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Vip Weeks per Year<span style="color:red">*</span></label>
                                    <select disabled="true" @change="validData()" :class="vipclubweeksvalid" name="vipclubweeks" required class="form-control" v-model="client.vipclubweeks">
                                        <option value="" v-if="this.lang == 'es'">Seleccione una opcion:</option>
                                        <option value="" v-if="this.lang == 'en'">Select an option:</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="99">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Smart weeks por año</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Smart Weeks per Year<span style="color:red">*</span></label>
                                    <select disabled="true" @change="validData()" name="smartweeks" required class="form-control" v-model="client.smartweeks" :class="smartweeksvalid">
                                        <option value="" v-if="this.lang == 'es'">Seleccione una opcion:</option>
                                        <option value="" v-if="this.lang == 'en'">Select an option:</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="99">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Last minute weeks por año</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Last Minute Weeks per Year<span style="color:red">*</span></label>
                                    <select disabled="true" @change="validData()" :class="lastminuteweeksvalid" name="smartweeks" required class="form-control" v-model="client.lastminuteweeks">
                                        <option value="" v-if="this.lang == 'es'">Seleccione una opcion:</option>
                                        <option value="" v-if="this.lang == 'en'">Select an option:</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="99">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h5 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'es'">Incentivos</h5>
                        <h5 class="font-weight-bold" style="color: #00adef;" v-if="this.lang == 'en'">Incentives</h5>
                        <div class="row">
                            <div class="col">
                                Travel Voucher
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <select disabled="true" name="travelvouchers" required class="form-control" v-model="client.numTravelVoucher">
                                            <option value="" v-if="this.lang == 'es'">Seleccione una opcion:</option>
                                            <option value="" v-if="this.lang == 'en'">Select an option:</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input disabled="true" name="descTravelVoucher" id="descTravelVoucher" required class="form-control" v-model="client.descTravelVoucher" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="this.lang == 'es'">Agente de ventas</label>
                                    <label for="name" class="form-label" v-if="this.lang == 'en'">Sales Agent</label>
                                    <input disabled="true" name="agente" :onkeyup="capitalizarPrimeraLetra(client.agente,'agente')" type="string" autocomplete="off" required class="form-control" v-model="client.agente" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row" v-if="!editForm">
                        <div class="col-1"></div>
                        <div class="col-2">
                            <div class="form-group">
                                <a v-if="this.lang == 'es'" style="background-color: #00adef !important; width: 180px;" :disabled="loader" type="button" class="btn btn-primary" @click.prevent="sendForm">
                                    <i v-if="loader" class="fas fa-spin fa-spinner"></i>Guardar
                                </a>
                                <a v-if="this.lang == 'en'" style="background-color: #00adef !important; width: 180px;" :disabled="loader" type="button" class="btn btn-primary" @click.prevent="sendForm">
                                    <i v-if="loader" class="fas fa-spin fa-spinner"></i>Save
                                </a>
                            </div>
                        </div>
                        <div class="col-2">
                            <button class="btn btn-danger" v-on:click="cancelEditFormulario()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import serverData from './../mixins/serverData';
import * as moment from 'moment';
export default {
    mixins: [serverData],
    props: ['codes', 'years', 'now', 'lang', 'countriesgen','user','periodicidad','tipomembresias','status','idiomas','estadosciviles','parentesco','resort','moneda','member'],
    data() {
        return {
            client: {
                id:'',
                number_contract: '',
                fechac: '',
                fechae:'',
                fecharenovacion: '',
                lang: '',
                estadocivil: '',
                totalintervalinternacional: '',
                certificadoautosval: '',
                recompensayates: '',
                recompensayatesval: '',
                name: '',
                apellidotitular: '',
                email1: '',
                namecotitular: '',
                apellidocotitular: '',
                email2: '',
                numerocasa: '',
                telefonotitular: '',
                telefonocotitular: '',
                country: null,
                state: null,
                city: '',
                loginID:'',
                periodicidad: '',
                resort:'',
                direccion: '',
                cp: '',
                status: '',
                precio: null,
                moneda: null,
                vipclubweeks: null,
                lastminuteweeks: null,
                smartweeks: null,
                hotelescheck: 1,
                tourscheck: 1,
                cruceroscheck: 1,
                trasladoscheck: 1,
                rentaautocheck: 1,
                typemembership: '',
                dealscheck: 1,
                international_code_home_coowner: '',
                numerocasacoowner: '',
                descTravelVoucher: 0,
                intervalinternacional: '',
                certificadoautos: '',
                recompensayates: '',
                recompensacondominios: '',
                agente: '',
                international_code: '',
                international_code_cel_titular: '',
                international_code_cel_cotitular: '',
                numTravelVoucher: 0,
                price: '',
                payinitpercentaje: '',
                beneficiario1: '',
                beneficiario2: '',
                beneficiario3: '',
                beneficiario4: '',
                beneficiario5: '',
                parentesco1: '',
                parentesco2: '',
                parentesco3: '',
                parentesco4: '',
                parentesco5: '',
                payinit: '',
                adp1: 0,
                dateadp1: '',
                adp2: 0,
                dateadp2: '',
                adp3: 0,
                dateadp3: '',
                tarifacierre: '',
                financiamiento: '',
                numbermonth: '',
                montomensualidad: '',
                currency: 'USD',
                intereses: '',
                factoria: '',
                anios: '',
                primermensualidad: '',
                nacionalidadtitular: '',
                nacionalidadcotitular: '',
                birthdate: '',
                birthdatecotitular: '',
                international_code_home: '',
                telefonotitularhome: ''
            },
            stateOptions:[],
            friends:false,
            automaticpay:false,
            friendstext:'',
            theDate: moment().format('YYYY-MM-DD'),
            theDateBirt: moment().format('MM-DD-YYYY'),
            all: true,
            editForm:true,
            loader: false,
            disabledState: false,
            mensualidad: 0,
            estadocivilvalid: 'form-control is-invalid',
            fechacompravalid: 'form-control is-invalid',
            yearsvalid: 'form-control is-invalid',
            ownernamevalid: 'form-control is-invalid',
            ownerlastnamevalid: 'form-control is-invalid',
            owneremailvalid: 'form-control is-invalid',
            membershipvalid:'form-control is-invalid',
            codeownervalid: 'form-control is-invalid',
            codeownerhomevalid: 'form-control is-invalid',
            numberhomevalid: 'form-control is-invalid',
            numbervalid: 'form-control is-invalid',
            statusvalid: 'form-control is-invalid',
            countryvalid: 'form-control is-invalid',
            statevalid: 'form-control is-invalid',
            international_code_cel_cotitularvalid:'form-control',
            telefonocotitularvalid:'form-control',
            cityvalid: 'form-control is-invalid',
            addressvalid: 'form-control is-invalid',
            fechaexvalid: 'form-control is-invalid',
            zipcodevalid: 'form-control is-invalid',
            vipclubweeksvalid:'form-control is-invalid',
            smartweeksvalid:'form-control is-invalid',
            lastminuteweeksvalid:'form-control is-invalid',
            pricevalid: 'form-control is-invalid',
            currencyvalid: 'form-control is-invalid',
            international_code_cel_titular_valid: 'form-control',
            telefonotitularvalid: 'form-control',
            contractvalid: 'form-control is-invalid',
            birtvalid:'form-control',
            periodicidadvalid: 'form-control is-invalid',
            langvalid:'form-control is-invalid',
            codecoownerhomevalid:'form-control',
            numberhomecoownervalid:'form-control',
            errorResult:false,
            loaderparent:false,
            friend:[],
            tableFriend:false
        }
    },
    computed: {
        countryOptions() {
            if (this.lang == 'es') {
                return [{ text: 'Elija un pais', value: null }].concat(this.countriesgen.map(country => {
                    return {
                        text: country.Nombre.toUpperCase(),
                        value: country.Id
                    }
                }));
            } else {
                return [{ text: 'Choose a country', value: null }].concat(this.countriesgen.map(country => {
                    return {
                        text: country.NombreEn.toUpperCase(),
                        value: country.Id
                    }

                }));
            }
        },
        countrySelected() {
            if (this.client.country === null) return null;
            return this.countriesgen.find(c => c.id_pais == this.client.country);
        }
    },
    methods: {
         async getstates(){
            if (this.client.country) {
                this.stateOptions = [];
                this.countryvalid = "form-control";
                var statesFind = this.client.country;

                const { data } = await axios.post('/api-v1/getStates', {
                    params: {
                        statesFind
                    }
                });

               this.stateOptions = data;
            }
        },
        acceptNumber0() {
            this.client.numerocasa = this.client.numerocasa.replace(/\D/g, '');
        },
        acceptNumber() {
            this.client.telefonotitular = this.client.telefonotitular.replace(/\D/g, '');
        },
        acceptNumber2() {
            this.client.telefonocotitular = this.client.telefonocotitular.replace(/\D/g, '');
        },
        acceptNumber3() {
            this.client.cp = this.client.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
        },
        acceptNumber4() {
            this.client.price = this.client.price.replace(/\D/g, '');
        },
        acceptNumber5() {
            this.client.payinitpercentaje = this.client.payinitpercentaje.replace(/\D/g, '');
        },
        acceptNumber6() {
            this.client.adp1 = this.client.adp1.replace(/\D/g, '');
        },
        acceptNumber7() {
            this.client.adp2 = this.client.adp2.replace(/\D/g, '');
        },
        acceptNumber8() {
            this.client.adp3 = this.client.adp3.replace(/\D/g, '');
        },
        acceptNumber9() {
            this.client.tarifacierre = this.client.tarifacierre.replace(/\D/g, '');
        },
        acceptNumber10() {
            this.client.numerocasacoowner = this.client.numerocasacoowner.replace(/\D/g, '');
        },
        
        editFormulario(){
            this.editForm = false;
        },
        cancelEditFormulario(){
            this.editForm = true;
        },
        async searchFriend(){
            if(this.friendstext){
                this.tableFriend = false;
                this.loaderparent = true;
                var friend = this.friendstext;
                const { data } = await axios.post('/api-v1/searchFriend', {friend});

                if(data == 'error'){
                    this.errorResult = true;
                    this.loaderparent = false;
                    this.tableFriend = false;
                }else{
                    this.friend = data;
                    this.errorResult = false;
                    this.loaderparent = false;
                    this.tableFriend = true;
                }
            }
        },
          capitalizarPrimeraLetra(palabra, campo) {
            if (!palabra) return;
            var palabraOri = '';
            var mayuscula = "";
            var minuscula = "";
            var palabraConvert = '';

            palabraOri = palabra.split(' ');

            for (var i = 0; i < palabraOri.length; i++) {
                mayuscula = palabraOri[i].substring(0, 1).toUpperCase();
                minuscula = palabraOri[i].substring(1).toLowerCase();
                if(i < 1)
                {
                    palabraConvert += mayuscula.concat(minuscula);
                }else{
                    palabraConvert += ' '+mayuscula.concat(minuscula);
                }
            }

            if (campo == 'name') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.name = palabraConvert;
            } else if (campo == 'apellido') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.apellidotitular = palabraConvert;
            } else if (campo == 'nameco') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.namecotitular = palabraConvert;
            } else if (campo == 'apellidoco') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.apellidocotitular = palabraConvert;
            } else if (campo == 'city') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.city = palabraConvert;
            } else if (campo == 'direccion') {
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.direccion = palabraConvert;
            } else if (campo == 'beneficiario1') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.beneficiario1 = palabraConvert;
            } else if (campo == 'beneficiario2') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.beneficiario2 = palabraConvert;
            } else if (campo == 'beneficiario3') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.beneficiario3 = palabraConvert;
            } else if (campo == 'beneficiario4') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.beneficiario4 = palabraConvert;
            } else if (campo == 'beneficiario5') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.beneficiario5 = palabraConvert;
            } else if (campo == 'parentesco1') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.parentesco1 = palabraConvert;
            } else if (campo == 'parentesco2') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.parentesco2 = palabraConvert;
            } else if (campo == 'parentesco3') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.parentesco3 = palabraConvert;
            } else if (campo == 'parentesco4') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.parentesco4 = palabraConvert;
            } else if (campo == 'parentesco5') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.parentesco5 = palabraConvert;
            } else if (campo == 'agente') {
                palabraConvert = palabraConvert.replace(/[0-9]/g, '');
                palabraConvert = palabraConvert.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.client.agente = palabraConvert;
            }
        },
        onChangeMes() {
            if (this.client.numbermonth == 0) {
                this.client.intereses = 0;
                this.client.factoria = 0;
                this.client.montomensualidad = 0;
            } else {
                this.client.intereses = 0;
                this.client.factoria = 0;
                this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
            }
        },
        validData() {
            if (this.client.status) {
                this.statusvalid = "form-control";
            }


            if (this.client.international_code_home) {   
                this.codeownerhomevalid = "form-control";

                if(!this.client.numerocasa){
                    this.numberhomevalid = "form-control is-invalid";
                }
            }

            if (this.client.numerocasa) {
                this.numberhomevalid = "form-control";

                if(!this.client.international_code_home){
                    this.codeownerhomevalid = "form-control is-invalid";
                }
            }

            if (this.client.anios) {
                this.onChangeDate();
                this.yearsvalid = "form-control";
            }

            if (this.client.fechac) {
                this.fechacompravalid = "form-control";
            }
            
            if (this.client.fechae) {
                this.fechaexvalid = "form-control";
            }

            if (this.client.apellidotitular) {
                this.client.apellidotitular = this.client.apellidotitular.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.ownerlastnamevalid = "form-control";
            }

            if(this.client.namecotitular){
                this.client.namecotitular = this.client.namecotitular.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
            }

            if(this.client.international_code_cel_titular)
            {
                this.international_code_cel_titular_valid = "form-control";
                if(!this.client.telefonotitular){
                    this.telefonotitularvalid = "form-control is-invalid";
                }
            }

            if(this.client.telefonotitular){
                this.telefonotitularvalid =  "form-control";

                if(!this.client.international_code_cel_titular){
                    this.international_code_cel_titular_valid = "form-control is-invalid";
                }
            }

            if(this.client.apellidocotitular)
            {
                this.client.apellidocotitular = this.client.apellidocotitular.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
            }

            if (this.client.email1) {
                this.client.email1 = this.client.email1.replace(/\s/g, "");
                this.client.email1 = this.client.email1.replace(/[\^*!"#$%&/()=?¡!¿'\\´¨+,;:{}~`°]/g, '');
                this.owneremailvalid = "form-control";
            }
            if (this.client.email2) {
                this.client.email2 = this.client.email2.replace(/\s/g, "");
                this.client.email2 = this.client.email2.replace(/[\^*!"#$%&/()=?¡!¿'\\´¨+,;:{}~`°]/g, '');
            }
            if (this.client.name) {
                this.client.name = this.client.name.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.ownernamevalid = "form-control";
            }
            if (this.client.country) {
                this.countryvalid = "form-control";          
            }

            if(this.client.lang){
                this.langvalid = "form-control";
            }

            if (this.client.city) {
                this.cityvalid = "form-control";
            }

            if (this.client.direccion) {
                this.addressvalid = "form-control";
            }

            if (this.client.cp) {
                this.zipcodevalid = "form-control";
            }

            if (this.client.vipclubweeks) {
                this.vipclubweeksvalid = "form-control";
            }

            if (this.client.smartweeks) {
                this.smartweeksvalid = "form-control";
            }

            if (this.client.lastminuteweeks) {
                this.lastminuteweeksvalid = "form-control";
            }

            if (this.client.price) {
                this.pricevalid = "form-control";
            }

            if(this.client.international_code_cel_cotitular){
                this.international_code_cel_cotitularvalid = "form-control";

                if(!this.client.telefonocotitular){
                    this.telefonocotitularvalid = "form-control is-invalid";
                }
            }

            if(this.client.telefonocotitular){
                this.telefonocotitularvalid = "form-control";

                if(!this.client.international_code_cel_cotitular){
                    this.international_code_cel_cotitularvalid = "form-control is-invalid";
                }
            }

            if(this.client.typemembership){
                this.membershipvalid = "form-control";
            }

            if (this.client.currency) {
                this.currencyvalid = "form-control";
            }

            if (this.client.international_code_cel_titular) {
                this.codeownervalid = "form-control";
            }
            if (this.client.telefonotitular) {
                this.client.telefonotitular = this.client.telefonotitular.replace(/\s/g, "");
                this.client.telefonotitular = this.client.telefonotitular.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.numbervalid = "form-control";
            }

            if(this.client.estadocivil){
                this.estadocivilvalid = "form-control";
            }

            if (this.client.state) {
                this.statevalid = "form-control";
            }

            if(this.client.number_contract){
                this.client.number_contract = this.client.number_contract.replace(/\s/g, "");
                this.client.number_contract = this.client.number_contract.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.contractvalid  = "form-control";
            }

            if(this.client.periodicidad){
                this.periodicidadvalid = "form-control";
            }

            if(this.client.birthdate){
                this.birtvalid  = "form-control";
            }

            if(this.client.international_code_home_coowner){
                this.codecoownerhomevalid = "form-control";

                if(!this.client.numerocasacoowner){
                    this.numberhomecoownervalid = "form-control is-invalid";
                }
            }

            if(this.client.numerocasacoowner){
                this.numberhomecoownervalid = "form-control";

                if(!this.client.international_code_home_coowner){
                    this.codecoownerhomevalid = "form-control is-invalid";
                }

            }
        },
        async sendForm(){
            this.loader = true;
            this.$refs.paymentForm.submit();
        },
        onChangeDate() {
            if(this.client.anios && this.client.fechac){
                var fecha = new Date(this.client.fechac);
                var anios = parseInt(fecha.getFullYear());
                var aniossuma = parseInt(this.client.anios);
                var suma = anios+aniossuma
                var dia = fecha.getDate()>9?(parseInt(fecha.getDate())+1):('0'+(parseInt(fecha.getDate())+1));
                var mes = (parseInt(fecha.getMonth())+1)>9?(parseInt(fecha.getMonth())+1):('0'+(parseInt(fecha.getMonth())+1));
                var nuevafecha = new Date(suma+'-'+mes+'-'+dia);

                this.client.fecharenovacion = nuevafecha.toISOString().split('T')[0];
                this.fechacompravalid = "form-control";

                if (this.client.anios) {
                    this.yearsvalid = "form-control";
                }
            }
        },
        onChangePercentaje() {
            this.client.payinit = parseFloat(this.client.payinitpercentaje * (this.client.price / 100)).toFixed(2);
            this.client.financiamiento = parseFloat(this.client.price - this.client.payinit - this.client.adp1 - this.client.adp2 - this.client.adp3).toFixed(2);
        },
        allSelected() {
            if (!this.all) {
                this.client.hotelescheck = false;
                this.client.tourscheck = false;
                this.client.cruceroscheck = false;
                this.client.trasladoscheck = false;
                this.client.rentaautocheck = false;
                this.client.dealscheck = false;
            } else {
                this.client.hotelescheck = true;
                this.client.tourscheck = true;
                this.client.cruceroscheck = true;
                this.client.trasladoscheck = true;
                this.client.rentaautocheck = true;
                this.client.dealscheck = true;
            }
        },
        validateOldData() {
            this.loader = false;
            if (this.hasOld('nametitular')) {
                this.client.name = this.old('nametitular');
            }
            if(this.hasOld('automaticpay')){
                this.automaticpay = this.old('automaticpay');
            }
            if (this.hasOld('idioma')) {
                this.client.lang = this.old('idioma');
            }
            if (this.hasOld('fechacompra')) {
                this.client.fechac = this.old('fechacompra');
            }
            if (this.hasOld('fecharenovacion')) {
                this.client.fecharenovacion = this.old('fecharenovacion');
            }
            if (this.hasOld('apellidotitular')) {
                this.client.apellidotitular = this.old('apellidotitular');
            }
            if (this.hasOld('email')) {
                this.client.email1 = this.old('email');
            }
            if (this.hasOld('namecotitular')) {
                this.client.namecotitular = this.old('namecotitular');
            }
            if (this.hasOld('apellidocotitular')) {
                this.client.apellidocotitular = this.old('apellidocotitular');
            }
            if (this.hasOld('email2')) {
                this.client.email2 = this.old('email2');
            }
            if (this.hasOld('numerocasa')) {
                this.client.numerocasa = this.old('numerocasa');
            }
            if (this.hasOld('telefonotitular')) {
                this.client.telefonotitular = this.old('telefonotitular');
            }
            if (this.hasOld('telefonocotitular')) {
                this.client.telefonocotitular = this.old('telefonocotitular');
            }
            if (this.hasOld('country')) {
                this.client.country = this.old('country');
            }
            if (this.hasOld('state')) {
                this.client.state = this.old('state');
            }
            if (this.hasOld('city')) {
                this.client.city = this.old('city');
            }
            if (this.hasOld('direccion')) {
                this.client.direccion = this.old('direccion');
            }
            if (this.hasOld('cp')) {
                this.client.cp = this.old('cp');
            }
            if (this.hasOld('precio')) {
                this.client.precio = this.old('precio');
            }
            if (this.hasOld('moneda')) {
                this.client.moneda = this.old('moneda');
            }
            if (this.hasOld('vipclubweeks')) {
                this.client.vipclubweeks = this.old('vipclubweeks');
            }
            if (this.hasOld('smartweeks')) {
                this.client.smartweeks = this.old('smartweeks');
            }
            if (this.hasOld('hotelescheck')) {
                this.client.hotelescheck = this.old('hotelescheck');
            }
            if (this.hasOld('tourscheck')) {
                this.client.tourscheck = this.old('tourscheck');
            }
            if (this.hasOld('cruceroscheck')) {
                this.client.cruceroscheck = this.old('cruceroscheck');
            }
            if (this.hasOld('autoscheck')) {
                this.client.autoscheck = this.old('autoscheck');
            }
            if (this.hasOld('trasladoscheck')) {
                this.client.trasladoscheck = this.old('trasladoscheck');
            }
            if (this.hasOld('yatescheck')) {
                this.client.yatescheck = this.old('yatescheck');
            }
            if (this.hasOld('descTravelVoucher')) {
                this.client.descTravelVoucher = this.old('descTravelVoucher');
            }
            if (this.hasOld('intervalinternacional')) {
                this.client.intervalinternacional = this.old('intervalinternacional');
            }
            if (this.hasOld('certificadoautos')) {
                this.client.certificadoautos = this.old('certificadoautos');
            }
            if (this.hasOld('recompensayates')) {
                this.client.recompensayates = this.old('recompensayates');
            }
            if (this.hasOld('recompensacondominios')) {
                this.client.recompensacondominios = this.old('recompensacondominios');
            }
            if (this.hasOld('agente')) {
                this.client.agente = this.old('agente');
            }
            if (this.hasOld('international_code')) {
                this.client.international_code = this.old('international_code');
            }
            if (this.hasOld('international_code_home')) {
                this.client.international_code_home = this.old('international_code_home');
            }
            if (this.hasOld('numTravelVoucher')) {
                this.client.numTravelVoucher = this.old('numTravelVoucher');
            }
            if (this.hasOld('number_contract')) {
                this.client.number_contract = this.old('number_contract');
            }
            if (this.hasOld('totalintervalinternacional')) {
                this.client.totalintervalinternacional = this.old('totalintervalinternacional');
            }
            if (this.hasOld('certificadoautosval')) {
                this.client.certificadoautosval = this.old('certificadoautosval');
            }
            if (this.hasOld('recompensayatesval')) {
                this.client.recompensayatesval = this.old('recompensayatesval');
            }
            if (this.hasOld('status')) {
                this.client.status = this.old('status');
            }
            if (this.hasOld('lastminuteweeks')) {
                this.client.lastminuteweeks = this.old('lastminuteweeks');
            }
            if (this.hasOld('rentaautocheck')) {
                this.client.rentaautocheck = this.old('rentaautocheck');
            }
            if (this.hasOld('dealscheck')) {
                this.client.dealscheck = this.old('dealscheck');
            }
            if (this.hasOld('international_code_cel_titular')) {
                this.client.international_code_cel_titular = this.old('international_code_cel_titular');
            }
            if (this.hasOld('international_code_cel_cotitular')) {
                this.client.international_code_cel_cotitular = this.old('international_code_cel_cotitular');
            }
            if (this.hasOld('price')) {
                this.client.price = this.old('price');
            }
            if (this.hasOld('payinitpercentaje')) {
                this.client.payinitpercentaje = this.old('payinitpercentaje');
            }
            if (this.hasOld('payinit')) {
                this.client.payinit = this.old('payinit');
            }
            if (this.hasOld('adp1')) {
                this.client.adp1 = this.old('adp1');
            }
            if (this.hasOld('dateadp1')) {
                this.client.dateadp1 = this.old('dateadp1');
            }
            if (this.hasOld('adp2')) {
                this.client.adp2 = this.old('adp2');
            }
            if (this.hasOld('dateadp2')) {
                this.client.dateadp2 = this.old('dateadp2');
            }
            if (this.hasOld('adp3')) {
                this.client.adp3 = this.old('adp3');
            }
            if (this.hasOld('dateadp3')) {
                this.client.dateadp3 = this.old('dateadp3');
            }
            if (this.hasOld('tarifacierre')) {
                this.client.tarifacierre = this.old('tarifacierre');
            }
            if (this.hasOld('financiamiento')) {
                this.client.financiamiento = this.old('financiamiento');
            }
            if (this.hasOld('numbermonth')) {
                this.client.numbermonth = this.old('numbermonth');
            }
            if (this.hasOld('montomensualidad')) {
                this.client.montomensualidad = this.old('montomensualidad');
            }
            if (this.hasOld('currency')) {
                this.client.currency = this.old('currency');
            }
            if (this.hasOld('intereses')) {
                this.client.intereses = this.old('intereses');
            }
            if (this.hasOld('factoria')) {
                this.client.factoria = this.old('factoria');
            }
            if (this.hasOld('primermensualidad')) {
                this.client.primermensualidad = this.old('primermensualidad');
            }
            if (this.hasOld('nacionalidadtitular')) {
                this.client.nacionalidadtitular = this.old('nacionalidadtitular');
            }
            if (this.hasOld('nacionalidadcotitular')) {
                this.client.nacionalidadcotitular = this.old('nacionalidadcotitular');
            }
            if (this.hasOld('anios')) {
                this.client.anios = this.old('anios');
            }
            if (this.hasOld('beneficiario1')) {
                this.client.beneficiario1 = this.old('beneficiario1');
            }
            if (this.hasOld('beneficiario2')) {
                this.client.beneficiario2 = this.old('beneficiario2');
            }
            if (this.hasOld('beneficiario3')) {
                this.client.beneficiario3 = this.old('beneficiario3');
            }
            if (this.hasOld('beneficiario4')) {
                this.client.beneficiario4 = this.old('beneficiario4');
            }
            if (this.hasOld('beneficiario5')) {
                this.client.beneficiario5 = this.old('beneficiario5');
            }
            if (this.hasOld('parentesco1')) {
                this.client.parentesco1 = this.old('parentesco1');
            }
            if (this.hasOld('parentesco2')) {
                this.client.parentesco2 = this.old('parentesco2');
            }
            if (this.hasOld('parentesco3')) {
                this.client.parentesco3 = this.old('parentesco3');
            }
            if (this.hasOld('parentesco4')) {
                this.client.parentesco4 = this.old('parentesco4');
            }
            if (this.hasOld('parentesco5')) {
                this.client.parentesco5 = this.old('parentesco5');
            }
            if (this.hasOld('birthdate')) {
                this.client.birthdate = this.old('birthdate');
            }
            if (this.hasOld('birthdatecotitular')) {
                this.client.birthdatecotitular = this.old('birthdatecotitular');
            }
            if(this.old('estadocivil')) {
                this.client.estadocivil = this.old('estadocivil');
            }
            if(this.old('periodicidad')) {
                this.client.periodicidad = this.old('periodicidad');
            }
            if(this.old('typemembership')) {
                this.client.typemembership = this.old('typemembership');
            }
        }
    },
    mounted() {
        if(this.member.numberOfWeeks){
            this.client.vipclubweeks = this.member.numberOfWeeks.VipWeeks;
            this.client.smartweeks = this.member.numberOfWeeks.SmartWeeks;
            this.client.lastminuteweeks = this.member.numberOfWeeks.LastMinuteWeeks
        }

        this.client.currency = this.member.ContratoId.Moneda;
        this.client.agente = this.member.ContratoId.Vendedor;
        this.client.loginID = this.member.ContratoId.LoginID;
        this.client.id = this.member._id;
        this.client.name = this.member.Nombre;
        this.client.apellidotitular = this.member.ApellidoPaterno
        this.client.email1 = this.member.Email;
        this.client.country = this.member.Pais;
        this.getstates();
        this.client.state = this.member.Estado;
        this.client.city = this.member.Ciudad;
        this.client.number_contract = this.member.ContratoId.Numero;
        this.client.fecharenovacion = this.member.FechaExpiracion;
        this.client.lang = this.member.Idioma;
        this.client.estadocivil = this.member.EstadoCivil;
        this.client.periodicidad = this.member.ContratoId.Periodicidad;
        this.client.direccion = this.member.Direccion;
        this.client.cp = this.member.CodigoPostal;
        this.client.status = this.member.ContratoId.EstadoContrato;
        let date = new Date(this.member.ContratoId.FechaCompra);
        this.client.fechac = date.toISOString().split('T')[0];
        this.client.anios = this.member.ContratoId.Years;
        let date2 = new Date(this.member.ContratoId.FechaExpiracion);
        this.client.fecharenovacion = date2.toISOString().split('T')[0];
        this.client.typemembership = this.member.ContratoId.TipoMembresia;
        this.client.telefonotitular = this.member.Celular;
        this.client.international_code_cel_titular = this.member.LadaCelular;
        this.client.international_code_home = this.member.LadaTelefono;
        this.client.numerocasa = this.member.Telefono;
        this.client.nacionalidadtitular = this.member.Nacionalidad;
        
        if(this.member.FechaNacimiento){
            let date3 = new Date(this.member.FechaNacimiento);
            this.client.birthdate = date3.toISOString().split('T')[0];
        }

        if(this.member.coOwner){
            this.client.namecotitular = this.member.coOwner.Nombre;
            this.client.apellidocotitular = this.member.coOwner.ApellidoPaterno;
            this.client.email2 = this.member.coOwner.Email;
            this.client.telefonocotitular = this.member.coOwner.Celular;
            this.client.international_code_cel_cotitular = this.member.coOwner.LadaCelular;
            if(this.member.coOwner.FechaNacimiento)
            {
                let date4 = new Date(this.member.coOwner.FechaNacimiento);
                this.client.birthdatecotitular = date4.toISOString().split('T')[0];
            }
            this.client.nacionalidadcotitular = this.member.coOwner.Nacionalidad;
            this.client.international_code_home_coowner = this.member.coOwner.LadaTelefono;
            this.client.numerocasacoowner = this.member.coOwner.Telefono;
        }

        if(this.member.detailsContract){
            if(this.member.detailsContract.TravelVoucher){
                if(this.member.detailsContract.TravelVoucher[0]){
                    this.client.numTravelVoucher = this.member.detailsContract.TravelVoucher[0].Cantidad;
                    this.client.descTravelVoucher = this.member.detailsContract.TravelVoucher[0].Monto;
                }
            }
        }

        if (this.old('fechacompra')) {
            this.fechacompravalid = "form-control";
        }
        if (this.old('status')) {
            this.statusvalid = "form-control";
        }

        if (this.old('anios')) {
            this.yearsvalid = "form-control";
        }
        if (this.old('apellidotitular')) {
            this.ownerlastnamevalid = "form-control";
        }
        if (this.old('email')) {
            this.owneremailvalid = "form-control";
        }
        if (this.old('nametitular')) {
            this.ownernamevalid = "form-control";
        }
        if (this.old('country')) {
            this.countryvalid = "form-control";
        }
        if (this.old('city')) {
            this.cityvalid = "form-control";
        }
        if (this.old('direccion')) {
            this.addressvalid = "form-control";
        }
        if (this.old('cp')) {
            this.zipcodevalid = "form-control";
        }
        if (this.old('precio')) {
            this.pricevalid = "form-control";
        }
        if (this.old('moneda')) {
            this.currencyvalid = "form-control";
        }
        if (this.hasOld('telefonotitular')) {
            this.codeownervalid = "form-control";
        }
        if (this.hasOld('international_code')) {
            this.numbervalid = "form-control";
        }
        if (this.hasOld('number_contract') && this.old('number_contract')) {
            this.contractvalid = "form-control";
        }
        if(this.hasOld('birthdate')){
            this.birtvalid  = "form-control";
        }
        if(this.old('vipclubweeks')){
            this.vipclubweeksvalid  = "form-control";
        }
        if(this.old('smartweeks')){
            this.smartweeksvalid  = "form-control";
        }
        if(this.old('lastminuteweeks')){
            this.lastminuteweeksvalid  = "form-control";
        }
        this.validData();
    }
}

</script>
