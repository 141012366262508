<template>
  <div class="d-inline-block">
    <date-picker range input-class="form-control border-0 bg-gray-200" v-model="range"></date-picker>
    <input type="hidden" name="start" :value="startString">
    <input type="hidden" name="end" :value="endString">
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  props: {
    start: {
      type: String,
      default: function () {
        return moment();
      }
    },
    end: {
      type: String,
      default: function () {
        return moment();
      }
    }
  },
  data() {
    return {
      range: null,
      start_: null,
      end_: null
    };
  },
  mounted() {
    this.start_ = moment.isMoment(this.start) ? this.start : moment(this.start);
    this.end_ = moment.isMoment(this.end) ? this.end : moment(this.end);

    this.range = [this.start_.toDate(), this.end_.toDate()]; 
  },
  computed: {
    startString() {
      if (this.start_ === null) return null;
      return this.start_.format('YYYY-MM-DD');
    },
    endString() {
      if (this.end_ === null) return null;
      return this.end_.format('YYYY-MM-DD');
    }
  },
  watch: {
    range(newRange) {
      this.start_ = moment(newRange[0]);
      this.end_ = moment(newRange[1]);
    }
  }
}
</script>

<style scoped>
  .mx-datepicker-range {
    width: auto;
  }
</style>