<template>
  <div>
    <div class="form-group" :class="{row: horizontal}">
      <label :class="{'col-md-2': horizontal, 'col-form-label': horizontal}">{{ label }}</label>
      <div :class="{'col-md-10': horizontal}">
        <input type="hidden" :name="name" :value="value_.id" v-if="value_.id">
        <input type="hidden" :name="`${name}_label`" :value="value_.name" v-if="value_.name" />
        <v-select label="name" :options="options" @search="fetch" :filterable="false" v-model="value_"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        required: false,
        default: ''
      },
      url: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      initial: {
        type: Object,
        required: false,
        default: function () {
          return { id: null, name: '' };
        }
      },
      horizontal: {
        type: Boolean,
        required: false,
        default: false
      },
      params: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    data() {
      return {
        options: [],
        value_: {id: null, name: ''},
      }
    },
    mounted() {
      this.value_ = this.initial;
    },
    methods: {
      fetch(search, loading) {
        loading(true);
        this.search(loading, search, this);
      },
      search: _.debounce((loading, search, vm) => {
        axios.get(vm.url, {
          params: {
            ...vm.params,
            search
          }
        }).then((res) => {
          vm.options = res.data;
          loading(false);
        });
      }, 350)
    }
  }
</script>
