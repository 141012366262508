<template>
  <div>
    <input type="hidden" name="currency_code" :value="currency.code">
    <div class="form-group">
      <label for="name">Nombre</label>
      <input type="text" 
        class="form-control"
        :class="{'is_invalid': hasServerError('name')}"
        name="name" 
        id="name" 
        v-model="name"
      >
      <div class="invlaid-feedback" v-if="hasServerError('name')">
        {{ getError('name') }}
      </div>
    </div>

    <div class="form-group">
      <label for="name">Nombre Ingles</label>
      <input type="text" 
        class="form-control"
        :class="{'is_invalid': hasServerError('name_en')}"
        name="name_en" 
        id="name" 
        v-model="name_en"
      >
      <div class="invlaid-feedback" v-if="hasServerError('name_en')">
        {{ getError('name_en') }}
      </div>
    </div>

    <div class="form-group">
      <label for="registration_priceopenpay">Cobro Incripcion MXN</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">MXN</span>
        </div>
        <input type="number" 
          step=".01" 
          class="form-control"
          :class="{'is-invalid': hasServerError('registration_priceopenpay')}"
          name="registration_priceopenpay" 
          id="registration_priceopenpay"
          v-model="registration_priceopenpay"
        >
      </div>
    </div>
    
    <div class="form-group">
      <label for="registration_pricestripe">Cobro Incripcion USD</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">USD</span>
        </div>
        <input type="number" 
          step=".01" 
          class="form-control"
          :class="{'is-invalid': hasServerError('registration_pricestripe')}"
          name="registration_pricestripe" 
          id="registration_pricestripe"
          v-model="registration_pricestripe"
        >
      </div>
    </div>
  
    <div class="form-group">
      <label for="periodicity">Frecuencia de cobro</label>
      <select class="form-control"
        :class="{'is-invalid': hasServerError('periodicity')}"
        name="periodicity" 
        id="periodicity"
        v-model="periodicity"
      >
        <option value="month">Mensual</option>
        <option value="year">Anual</option>
      </select>
    </div>

    <div class="form-group">
      <label for="price">Cobro {{periodicity == 'month'? 'Mensual' : 'Anual'}} MXN </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">MXN</span>
        </div>
        <input type="number" 
          step=".01" 
          class="form-control"
          :class="{'is-invalid': hasServerError('price')}"
          name="price" 
          id="price"
          v-model="price"
        >
      </div>
    </div>

    <div class="form-group">
      <label for="stripe_price">Cobro {{periodicity == 'month'? 'Mensual' : 'Anual'}} USD</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">USD</span>
        </div>
        <input type="number" 
          step=".01" 
          class="form-control"
          :class="{'is-invalid': hasServerError('stripe_price')}"
          name="stripe_price" 
          id="stripe_price"
          v-model="stripe_price"
        >
      </div>
    </div>

    <div class="form-group">
      <label for="discount_type">Tipo de descuento</label>
      <div class="input group">
        <select name="discount_type" 
          id="discount_type"
          class="form-control"
          :class="{'is-invalid': hasServerError('discount_type')}" 
          v-model="discount_type" 
        >
          <option value="fixed">Fijo</option>
          <option value="percentaje">Porcentaje</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="discount">Valor de descuento</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">{{ discount_type === 'fixed' ? currency.code : '%' }}</span>
        </div>
        <input type="number" 
          step=".01" 
          class="form-control"
          :class="{'is-invalid': hasServerError('discount')}"
          name="discount" 
          id="discount" 
          v-model="discount"
        >
      </div>
    </div>

    <div class="form-group">
      <label for="trial_months">Meses de prueba</label>
      <select name="trial_months" 
        id="trial_months"
        class="form-control"
        :class="{'is-invalid': hasServerError('trial_months')}"
        v-model="trial_months"
      >
        <option v-for="month in [0,1,2,3,4,5,6]" :key="`month_${month}`" :value="month">
          {{ month }}
        </option>
      </select>
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 1</label>
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene1es')}" name="bene1es" placeholder="Español" id="bene1es" v-model="bene1es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene1en')}" name="bene1en" placeholder="Ingles" id="bene1en" v-model="bene1en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 2</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene2es')}" name="bene2es" id="bene2es" v-model="bene2es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene2en')}" name="bene2en" placeholder="Ingles" id="bene2en" v-model="bene2en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 3</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene3es')}" name="bene3es" id="bene3es" v-model="bene3es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene3en')}" name="bene3en" placeholder="Ingles" id="bene3en" v-model="bene3en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 4</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene4es')}" name="bene4es" id="bene4es" v-model="bene4es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene4en')}" name="bene4en" placeholder="Ingles" id="bene4en" v-model="bene4en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 5</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene5es')}" name="bene5es" id="bene5es" v-model="bene5es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene5en')}" name="bene5en" placeholder="Ingles" id="bene5en" v-model="bene5en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 6</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene6es')}" name="bene6es" id="bene6es" v-model="bene6es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene6en')}" name="bene6en" placeholder="Ingles" id="bene6en" v-model="bene6en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 7</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene7es')}" name="bene7es" id="bene7es" v-model="bene7es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene7en')}" name="bene7en" placeholder="Ingles" id="bene7en" v-model="bene7en">
    </div>
  
    <div class="form-group">
      <label for="name">Beneficio 8</label>
      <input type="text" placeholder="Español" class="form-control" :class="{'is-invalid': hasServerError('bene8es')}" name="bene8es" id="bene8es" v-model="bene8es">
      <input type="text" class="form-control" :class="{'is-invalid': hasServerError('bene8en')}" name="bene8en" placeholder="Ingles" id="bene8en" v-model="bene8en">
    </div>
    <div class="form-group">
      <label for="name">Color</label>
      <input type="color" id="bg_color" name="bg_color" v-model="bg_color" class="form-control">
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary" v-loader-on-click>Guardar</button>
    </div>
  </div>   
</template>

<script>
import serverData from './../mixins/serverData';

export default {
  mixins: [serverData],
  props: {
    currency: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: '',
      name_en:'',
      periodicity: '',
      price: '',
      stripe_price: '',
      discount_type: '',
      registration_priceopenpay: 0,
      registration_pricestripe: 0,
      trial_months: 0,
      discount: '',
      bene1es: '',
      bene1en: '',
      bene2es: '',
      bene2en: '',
      bene3es: '',
      bene3en: '',
      bene4es: '',
      bene4en: '',
      bene5es: '',
      bene5en: '',
      bene6es: '',
      bene6en: '',
      bene7es: '',
      bene7en: '',
      bene8es: '',
      bene8en: '',
      bg_color: '',
    }
  },
  mounted() {
    this.name = this.old('name', '');
    this.name_en = this.old('name_en', '');
    this.periodicity = this.old('periodicity', 'month');
    this.price = this.old('price', '');
    this.discount_type = this.old('discount_type', 'fixed');
    this.discount = this.old('discount', '');
    this.bene1es = this.old('bene1es', '');
    this.bene1en = this.old('bene1en', '');
    this.bene2es = this.old('bene2es', '');
    this.bene2en = this.old('bene2en', '');
    this.bene3es = this.old('bene3es', '');
    this.bene3en = this.old('bene3en', '');
    this.bene4es = this.old('bene4es', '');
    this.bene4en = this.old('bene4en', '');
    this.bene5es = this.old('bene5es', '');
    this.bene5en = this.old('bene5en', '');
    this.bene6es = this.old('bene6es', '');
    this.bene6en = this.old('bene6en', '');
    this.bene7es = this.old('bene7es', '');
    this.bene7en = this.old('bene7en', '');
    this.bene8es = this.old('bene8es', '');
    this.bene8en = this.old('bene8en', '');
    this.bg_color = this.old('bg_color', '#f6b73c')
  }
}
</script>
