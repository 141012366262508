<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card border-0 shadow-sm" v-if="transportationCategories.length > 0">
        <div class="card-body">
          <table class="table table-outlined">
            <thead>
              <tr>
                <th>Categoria</th>
                <th>descripción</th>
                <th>Pax</th>
                <td></td>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transportationCategory in transportationCategories" :key="transportationCategory.id">
                <td>
                  <div v-if="editing === transportationCategory.id">
                    <input class="form-control form-control-sm" v-model="name" name="name" />
                  </div>
                  <div v-else>
                    {{ transportationCategory.name }}
                  </div>
                </td>
                <td>
                  <div v-if="editing === transportationCategory.id">
                    <textarea class="form-control form-control-sm" v-model="description" name="description"></textarea>
                  </div>
                  <div v-else>
                    {{ transportationCategory.description }}
                  </div>
                </td>
                <td>
                  <div v-if="editing === transportationCategory.id">
                    <input type="number" class="form-control form-control-sm" v-model="maxPax" name="max_pax" />
                  </div>
                  <div v-else>
                    {{ transportationCategory.max_pax }}
                  </div>
                </td>
                <td>
                  <a :href="transportationCategory.vehicles_url">Vehiculos</a>
                </td>
                <td>
                  <div v-if="editing === null">
                    <button type="button" class="btn text-info btn-link btn-sm" @click="edit(transportationCategory)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <form class="d-inline" :action="transportationCategory.delete_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="DELETE"></request-method>
                      <button type="submit" class="btn text-danger btn-link btn-sm">
                          <i class="fas fa-trash"></i>
                      </button>
                    </form>
                  </div>

                  <div v-if="editing === transportationCategory.id">
                    <form :action="transportationCategory.update_url" method="POST">
                      <csrf-token></csrf-token>
                      <request-method method="PUT"></request-method>
                      <input type="hidden" name="name" :value="name">
                      <input type="hidden" name="description" :value="description">
                      <input type="hidden" name="max_pax" :value="maxPax">
                      <button type="submit" class="btn text-primary btn-link btn-sm">
                        <i class="fas fa-save"></i> Guardar
                      </button>
                    </form>
                    <button type="button" class="btn text-danger btn-link btn-sm" @click="cancel">
                      <i class="fas fa-times"></i> Cancelar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <slot name="links">

          </slot>
        </div>
      </div>
    
      <div class="alert alert-info" v-else>Aún no hay categories</div>
      
    </div>
    <div class="col-md-3">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">Agregar categories</h5>
          <form :action="storeUrl" method="POST">
            <div v-for="(error, index) in errors" :key="index" class="text-danger">
              <i class="fas fa-times"></i> {{ error }}
            </div>
            <csrf-token></csrf-token>
            <div class="form-group">
              <label for="name">Nombre</label>
              <input type="text" class="form-control" name="name" :class="{'is-invalid': errors.name}">
            </div>
            <div class="form-group">
              <label for="description">Descripción</label>
              <textarea type="text" class="form-control"  :class="{'is-invalid': errors.description}" name="description"></textarea>
            </div>
            <div class="form-group">
              <label for="max_pax">Pax</label>
              <input type="number" class="form-control" name="max_pax">
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      transportationCategories: {
        type: Array,
        required: true,
      },
      errors: {
        type: Array,
        required: true
      },
      storeUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        editing: null,
        name: null,
        description: null,
        maxPax: null
      }
    },
    methods: {
      edit(category) {
        this.editing = category.id;
        this.name = category.name;
        this.description = category.description;
        this.maxPax = category.max_pax;

      },
      cancel() {
        this.editing = null;
        this.name = null;
        this.description = null;
        this.maxPax = null;
      }
    }
  }
</script>
