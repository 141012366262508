<template>
  <form class="d-inline-block" :action="url" method="POST" :ref="`fd${unique}`">
    <csrf-token></csrf-token>
    <request-method method="DELETE"></request-method>
    <div v-if="! confirming">
      <button type="button" class="btn" :class="{...customClass, 'text-danger': links, 'btn-outline-danger': !links}" @click="confirming = true">
        <slot>Delete</slot>
      </button>
    </div>
    <div v-else>
      <button type="button" class="btn btn-outline-primary" :class="{...customClass, 'text-primary': links}" @click="submit" v-loader-on-click>
        <slot name="confirm">Confirm</slot>
      </button>
      <button type="button" class="btn" :class="{...customClass, 'text-danger': links, 'btn-danger': !links}" @click="confirming = false">
        <slot name="cancel">Cancel</slot>
        </button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    url: {
      required: true,
      type: String
    }, 
    unique: {
      required: true
    },
    size: {
      required: false,
      type: String,
      default: 'md'
    },
    links: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirming: false
    }
  },
  methods: {
    submit() {
      this.$refs[`fd${this.unique}`].submit()
    }
  },
  computed: {
    sizeClass() {
      return `btn-${this.size}`;
    },
    customClass() {
      return {
        [this.sizeClass] : true,
        'btn-link': this.links,
      }
    }
  }
}
</script>