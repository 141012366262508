<template>
  <div class="overflow-auto">
    <availability-row title="Areas" :categories="categories">
      <template v-slot:title="{ title }" class="text-center" :title="{ title }">
        <div class="text-center text-primary">{{ title }}</div>
      </template>
    </availability-row>
    <availability-body :areas="areas_" :categories="categories" :loading="loading" @area-updated="areaUpdated"></availability-body>
  </div>
</template>

<script>
import AvailabilityRow from './AvailabilityAdmin/AvailabilityRow.vue';
import AvailabilityBody from './AvailabilityAdmin/AvailabilityBody.vue';

export default {
  props: {
    areas: {
      required: true,
      type: Array,
    },
    categories: {
      required: true,
      type: Array,
    },
    transportationType: {
      required: true,
      type: Number,
    }
  },
  data() {
    return {
      areas_: [],
      loading: false
    }
  },
  components: {
    AvailabilityRow,
    AvailabilityBody,
  },
  mounted() {
    this.areas_ = this.areas.map(a => ({...a}));
  },
  methods: {
    async areaUpdated(data) {
      let post = {...data, 'transportation_type': this.transportationType};
      this.loading = true;

      let { data: area } = await axios.post('/transportation-availability', post);
      this.areas_ = this.areas_.map(a => {
        if (a.id == area.id) {
          return area;
        }

        return a;
      });

      this.loading = false;
    }
  },
}
</script>
