<template>
  <div>
    <form :action="url" method="POST" enctype="multipart/form-data">
      <csrf-token></csrf-token>
      <div class="d-flex flex-column box-upload justify-content-center align-items-center overflow-hidden" :class="{ border: filePreview === null }">
        <input type="file" name="image" accept="image/*" class="input-file" ref="file" @change="handleChange">
        <div v-if="filePreview">
          <img :src="filePreview" class="img-fluid rounded" />
        </div>
        <div v-else>
          <i class="fas fa-upload"></i>
          <div>Select image</div>
        </div>
      </div>
      <input type="text" placeholder="Nombre" v-model="name" class="form-control mt-2" name="name">
      <button type="submit" class="btn btn-info btn-block mt-2 text-white" :disabled="invalid">Guardar vehiculo</button>
    </form>
  </div>
</template>

<script>
export default {
  props: ['url'],
  data() {
    return {
      file: null,
      name: null,
      filePreview: null
    }
  },
  methods: {
    handleChange(event) {
      this.file = this.$refs.file.files[0];
      this.filePreview = URL.createObjectURL(this.file);
    }
  },
  computed: {
    invalid() {
      return ! this.name || ! this.file;
    }
  }
}
</script>

<style scoped>
  .box-upload {
    width: 100%;
    height: 7rem;
    text-align: center;
    cursor: pointer;
    position: relative;
  }

  .input-file {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 8rem;
    height: 8rem;
  }
</style>
