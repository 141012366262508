<template>
  <div>
    <slot name="controls" :chanels="chanels" :chanelSelected="chanelSelected" :users="users" :onChangeChanel="onChangeChanel"></slot>
  </div>
</template>
<script>
export default{
  props: {
    chanels: {
      type: Array,
      required: false,
      default: function(){
        return [];
      }
    },
  },
  data(){
    return {
      chanelSelected: '',
      userSelected: '',
      users: []
    }
  },
  mounted() {

  },
  methods: {
    onChangeChanel(val){
      this.chanelSelected = val;
      this.chanels.forEach((item, i) => {
        if(item.id === val){
          if(item.users != null) this.users = item.users;
        }
      });
    }
  }
}
</script>
