<template>
  <div>
    <div class="row">
      <div class="col-md-9" :class="{'col-md-9': canCreate || canUpdate, 'col-md-12': !canCreate || !canUpdate}">
        <div class="card border-0 shadow-sm" v-if="items.length > 0">
          <div class="card-body pt-0 pb-0">
            <div class="table-responsive">
              <table class="table table-outlined mb-0">
                <thead>
                  <tr>
                    <th class="border-0" v-for="f in fields_" :key="f.key">
                      <div v-if="!f.hidden" class="text-center">{{ f.label }}</div>
                    </th>
                    <th v-if="actionable" class="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id" :class="{'bg-warning': item.id == editing}">
                    <td v-for="field in fields_" :key="`td${field.key}`">
                      <slot :name="`att-${field.key}`" :data="item[field.key]" :item="item">
                        <div v-if="! field.hidden">
                          {{ item[field.key] }}
                        </div>
                      </slot>
                    </td>
                    <td v-if="actionable">
                      <slot name="actions" :item="item" :edit="edit" :showInModal="showInModal">
    
                      </slot>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <slot name="links">
  
            </slot>
          </div>
        </div>
      
        <div class="d-flex align-items-center justify-content-center flex-column h-100 bg-white shadow-sm py-3" v-else>
          <img class="w-25" src="/img/undraw_no_data_qbuo.svg"/>
          <div class="text-center text-muted mt-3">
            <slot name="emptyMessage">No hay información por mostrar</slot>
          </div>
        </div>
        
      </div>
      <div class="col-md-3 mt-3 mt-md-0" v-if="canUpdate || canCreate">
        <div class="card border-0 shadow-sm">
          <slot name="card-header" :item="editable"></slot>
          <div class="card-body">
            <div v-if="editing">
              <h5 class="card-title">
                <slot name="updateTitle">Update</slot>
              </h5>
              <form :action="editable.update_url" method="POST" :enctype="enctype">
                <csrf-token></csrf-token>
                <request-method method="PUT"></request-method>
                <div v-for="field in fields_" :key="field.key">
                  <slot :name="`update-${field.key}`" :value="editable[field.key]" :field="field.label" :item="editable"></slot>
                </div>
                <div>
                  <slot name="extra-update-field" :item="editable" :extra="extra"></slot>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary" v-loader-on-click>
                    <slot name="update-save-text">Save</slot>
                  </button>
                  <button type="button" class="btn btn-danger" @click="cancelEdition">
                    <slot name="update-cancel-text">Cancel</slot>
                  </button>
                </div>
              </form>
            </div>
            <div v-else>
              <h5 class="card-title">
                <slot name="newTitle">New</slot>
              </h5>
              <form :action="storeUrl" method="POST" :enctype="enctype">
                <csrf-token></csrf-token>
                <slot name="newForm"></slot>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal 
    ref="modal-send" 
    size="lg" 
    :no-close-on-backdrop="false"
    :no-close-on-esc="false" 
    :hide-header-close="false">
      <template v-slot:modal-title>
        <slot name="modal-header" :item="itemModal"></slot>
      </template>

      
      <slot name="modal-body" :item="itemModal"></slot>
      

      <template v-slot:modal-footer>
        <slot name="modal-footer" :item="itemModal" :closeModal="closeModal"></slot>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: false,
      default: []
    },
    actionable: {
      type: Boolean,
      required: false,
      default: true
    },
    storeUrl: {
      type: String,
      required: true
    },
    canUpdate: {
      type: Boolean,
      default: true
    },
    canCreate: {
      type: Boolean,
      default: true
    },
    formType: {
      type: String,
      default: 'application'
    }, 
    extra: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  data() {
    return {
      editing: null,
      editable: null,
      itemModal: {},
      showModal: false,
    }
  },
  computed: {
    items_() {
      return this.items.map(i => {
        return {
          ...i,
          editing: false
        };
      });
    },
    fields_() {
      if (this.fields.length === 0 && this.items.length > 0) {
        return Object.keys(this.items[0]).map(i => {
          return {key: i, label: i};
        });
      }

      return this.fields;
    },
    enctype() {
      if (this.formType == 'multipart') {
        return 'multipart/form-data';
      }

      return 'application/x-www-form-urlencoded';
    }
  },
  methods: {
    edit(item) {
      this.editable = {...item};
      this.editing = item.id;
    },
    cancelEdition() {
      this.editable = null;
      this.editing = null;
    },
    showInModal(item) {
      this.itemModal = item;
      this.$refs['modal-send'].show();
    },
    closeModal() {
      this.$refs['modal-send'].hide();
      window.location.reload();
    }
  }
}
</script>
