<template>
  <div>
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-md-3 p-0">
          <div class="overflow-auto" style="height: 500px;">
            <ul class="p-0">
              <li v-for="city in cities" :key="city.id" class="d-flex justify-content-between p-3 l-item border-bottom" @click="show(city)">
                <div>
                  <i class="fas fa-map-marker-alt"></i> {{ city.name }}
                </div>
                <div>
                  <strong class="text-primary">{{ city.reservations }}</strong> <span class="text-muted">reservaciones</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-9 p-0" style="height: 500px;">
          <GmapMap ref="mapRef"
            class="w-100 h-100"
            :center="{lat:0, lng:0}"
            :zoom="2"
            map-type-id="terrain"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false
            }"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center=m.position"
            />

            <!--<gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
            </gmap-info-window>-->
          </GmapMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    range: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      cities: [],
      /* markers: [
        {
          position: {
            lat: 21.160556,
            lng: -86.8475
          }
        }
      ],
      infoWindowPos: {
        lat: 21.160556,
        lng: -86.8475
      },
      infoWinOpen: true,
      infoOptions: {
        content: 'Info',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      } */
    }
  },
  mounted() {
    this.citiesData();
  },
  computed: {
    markers() {
      return this.cities
        .filter(c => !!c.lat && !!c.lng)
        .map(({id, lat, lng}) => {
          return {
            id,
            position: {
              lat, lng
            }
          };
        });
    }
  },
  methods: {
    async citiesData() {
      this.loading = true;
      const start = this.range.start.format('YYYY-MM-DD');
      const end = this.range.end.format('YYYY-MM-DD');
      const { data } = await axios.get('/api-v1/dashboard/cities-monthly', {
        params: {
          start,
          end
        }
      });

      this.cities = data.cities.map((c) => {
        if (c.reservations === null) {
          c.reservations = 0;
        }

        return c;
      });
    },
    show(city) {
      console.log(city.id);
      let marker = null;
      marker = this.markers.find(m => m.id == city.id);
      console.log(marker);
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(marker.position);
        map.setZoom(7);
      })
    }
  },
  watch: {
    range(newRange, oldRange) {
      console.log('range');
      this.citiesData();
    },
  }
}
</script>

<style>
.l-item:hover {
  background-color: #F0F5F5;
}
</style>