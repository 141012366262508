var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid bg-white"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 p-0"},[_c('div',{staticClass:"overflow-auto",staticStyle:{"height":"500px"}},[_c('ul',{staticClass:"p-0"},_vm._l((_vm.cities),function(city){return _c('li',{key:city.id,staticClass:"d-flex justify-content-between p-3 l-item border-bottom",on:{"click":function($event){return _vm.show(city)}}},[_c('div',[_c('i',{staticClass:"fas fa-map-marker-alt"}),_vm._v(" "+_vm._s(city.name)+"\n              ")]),_vm._v(" "),_c('div',[_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(city.reservations))]),_vm._v(" "),_c('span',{staticClass:"text-muted"},[_vm._v("reservaciones")])])])}),0)])]),_vm._v(" "),_c('div',{staticClass:"col-md-9 p-0",staticStyle:{"height":"500px"}},[_c('GmapMap',{ref:"mapRef",staticClass:"w-100 h-100",attrs:{"center":{lat:0, lng:0},"zoom":2,"map-type-id":"terrain","options":{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false
          }}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center=m.position}}})}),1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }