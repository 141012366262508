<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card border-0 shadow-sm py-2">
                    <form :action="`/new-membership-year-save`" method="POST" id="payment-form" ref="paymentForm">
                        <csrf-token></csrf-token>
                        <input type="hidden" name="number_contract" id="number_contract" :value="client.number_contract">
                        <input type="hidden" name="fechacompra" id="fechacompra" :value="client.fechac">
                        <input type="hidden" name="fecharenovacion" id="fecharenovacion" :value="client.fecharenovacion">
                        <input type="hidden" name="idioma" id="idioma" :value="client.lang">
                        <input type="hidden" name="estadocivilsoltero" id="estadocivilsoltero" :value="client.estadocivilsoltero">
                        <input type="hidden" name="estadocivilcasado" id="estadocivilcasado" :value="client.estadocivilcasado">
                        <input type="hidden" name="estadocivilconcubinato" id="estadocivilconcubinato" :value="client.estadocivilconcubinato">
                        <input type="hidden" name="nametitular" id="nametitular" :value="client.name">
                        <input type="hidden" name="apellidotitular" id="apellidotitular" :value="client.apellidotitular">
                        <input type="hidden" name="email" id="email" :value="client.email1">
                        <input type="hidden" name="namecotitular" id="namecotitular" :value="client.namecotitular">
                        <input type="hidden" name="apellidocotitular" id="apellidocotitular" :value="client.apellidocotitular">
                        <input type="hidden" name="email2" id="email2" :value="client.email2">
                        <input type="hidden" name="numerocasa" id="numerocasa" :value="client.numerocasa">
                        <input type="hidden" name="telefonotitular" id="telefonotitular" :value="client.telefonotitular">
                        <input type="hidden" name="telefonocotitular" id="telefonocotitular" :value="client.telefonocotitular">
                        <input type="hidden" name="country" id="country" :value="client.country">
                        <input type="hidden" name="state" id="state" :value="client.state">
                        <input type="hidden" name="city" id="city" :value="client.city">
                        <input type="hidden" name="direccion" id="direccion" :value="client.direccion">
                        <input type="hidden" name="cp" id="cp" :value="client.cp">
                        <input type="hidden" name="precio" id="precio" :value="client.precio">
                        <input type="hidden" name="moneda" id="moneda" :value="client.moneda">
                        <input type="hidden" name="vipclubweeks" id="vipclubweeks" :value="client.vipclubweeks">
                        <input type="hidden" name="smartweeks" id="smartweeks" :value="client.smartweeks">
                        <input type="hidden" name="hotelescheck" id="hotelescheck" :value="client.hotelescheck">
                        <input type="hidden" name="tourscheck" id="tourscheck" :value="client.tourscheck">
                        <input type="hidden" name="cruceroscheck" id="cruceroscheck" :value="client.cruceroscheck">
                        <input type="hidden" name="autoscheck" id="autoscheck" :value="client.autoscheck">
                        <input type="hidden" name="trasladoscheck" id="trasladoscheck" :value="client.trasladoscheck">
                        <input type="hidden" name="yatescheck" id="yatescheck" :value="client.yatescheck">
                        <input type="hidden" name="descTravelVoucher" id="descTravelVoucher" :value="client.descTravelVoucher">
                        <input type="hidden" name="intervalinternacional" id="intervalinternacional" :value="client.intervalinternacional">
                        <input type="hidden" name="certificadoautos" id="certificadoautos" :value="client.certificadoautos">
                        <input type="hidden" name="recompensayates" id="recompensayates" :value="client.recompensayates">
                        <input type="hidden" name="recompensacondominios" id="recompensacondominios" :value="client.recompensacondominios">
                        <input type="hidden" name="agente" id="agente" :value="client.agente">
                        <input type="hidden" name="international_code" id="international_code" :value="client.international_code">
                        <input type="hidden" name="numTravelVoucher" id="numTravelVoucher" :value="client.numTravelVoucher">
                        <input type="hidden" name="chanelId" id="chanelId" :value="this.chanel">
                        <input type="hidden" name="totalintervalinternacional" id="totalintervalinternacional" :value="client.totalintervalinternacional">
                        <input type="hidden" name="certificadoautosval" id="certificadoautosval" :value="client.certificadoautosval">
                        <input type="hidden" name="recompensayatesval" id="recompensayatesval" :value="client.recompensayatesval">
                        <input type="hidden" name="resort" id="resort" :value="groupSelect">
                        <input type="hidden" name="status" id="status" :value="client.status">
                        <input type="hidden" name="lastminuteweeks" id="lastminuteweeks" :value="client.lastminuteweeks">
                        <input type="hidden" name="rentaautocheck" id="rentaautocheck" :value="client.rentaautocheck">
                        <input type="hidden" name="dealscheck" id="dealscheck" :value="client.dealscheck">
                        <input type="hidden" name="international_code_cel_titular" id="international_code_cel_titular" :value="client.international_code_cel_titular">
                        <input type="hidden" name="international_code_cel_cotitular" id="international_code_cel_cotitular" :value="client.international_code_cel_cotitular">
                        <input type="hidden" name="price" id="price" :value="client.price">
                        <input type="hidden" name="payinitpercentaje" id="payinitpercentaje" :value="client.payinitpercentaje">
                        <input type="hidden" name="payinit" id="payinit" :value="client.payinit">
                        <input type="hidden" name="adp1" id="adp1" :value="client.adp1">
                        <input type="hidden" name="dateadp1" id="dateadp1" :value="client.dateadp1">
                        <input type="hidden" name="adp2" id="adp2" :value="client.adp2">
                        <input type="hidden" name="dateadp2" id="dateadp2" :value="client.dateadp2">
                        <input type="hidden" name="adp3" id="adp3" :value="client.adp3">
                        <input type="hidden" name="dateadp3" id="dateadp3" :value="client.dateadp3">
                        <input type="hidden" name="tarifacierre" id="tarifacierre" :value="client.tarifacierre">
                        <input type="hidden" name="status" id="status" :value="client.status">
                        <input type="hidden" name="financiamiento" id="financiamiento" :value="client.financiamiento">
                        <input type="hidden" name="numbermonth" id="numbermonth" :value="client.numbermonth">
                        <input type="hidden" name="montomensualidad" id="montomensualidad" :value="client.montomensualidad">
                        <input type="hidden" name="currency" id="currency" :value="client.currency">
                        <input type="hidden" name="intereses" id="intereses" :value="client.intereses">
                        <input type="hidden" name="factoria" id="factoria" :value="client.factoria">
                        <input type="hidden" name="primermensualidad" id="primermensualidad" :value="client.primermensualidad">
                        <input type="hidden" name="nacionalidadtitular" id="nacionalidadtitular" :value="client.nacionalidadtitular">
                        <input type="hidden" name="nacionalidadcotitular" id="nacionalidadcotitular" :value="client.nacionalidadcotitular">
                        <input type="hidden" name="typemembership" id="typemembership" :value="client.typemembership">
                        <input type="hidden" name="anios" id="anios" :value="client.anios">
                        <input type="hidden" name="beneficiario1" id="beneficiario1" :value="client.beneficiario1">
                        <input type="hidden" name="beneficiario2" id="beneficiario2" :value="client.beneficiario2">
                        <input type="hidden" name="beneficiario3" id="beneficiario3" :value="client.beneficiario3">
                        <input type="hidden" name="beneficiario4" id="beneficiario4" :value="client.beneficiario4">
                        <input type="hidden" name="beneficiario5" id="beneficiario5" :value="client.beneficiario5">
                        <input type="hidden" name="parentesco1" id="parentesco1" :value="client.parentesco1">
                        <input type="hidden" name="parentesco2" id="parentesco2" :value="client.parentesco2">
                        <input type="hidden" name="parentesco3" id="parentesco3" :value="client.parentesco3">
                        <input type="hidden" name="parentesco4" id="parentesco4" :value="client.parentesco4">
                        <input type="hidden" name="parentesco5" id="parentesco5" :value="client.parentesco5">
                        <input type="hidden" name="groupSelect" id="groupSelect" :value="groupSelect">
                        <input type="hidden" name="birthdate" id="birthdate" :value="client.birthdate">
                        <input type="hidden" name="birthdatecotitular" id="birthdatecotitular" :value="client.birthdatecotitular">
                        <input type="hidden" name="cdp1" id="cdp1" :value="client.cdp1">
                        <input type="hidden" name="datecdp1" id="datecdp1" :value="client.datecdp1">
                        <input type="hidden" name="cdp2" id="cdp2" :value="client.cdp2">
                        <input type="hidden" name="datecdp2" id="datecdp2" :value="client.datecdp2">
                        <input type="hidden" name="cdp3" id="cdp3" :value="client.cdp3">
                        <input type="hidden" name="datecdp3" id="datecdp3" :value="client.datecdp3">
                        <input type="hidden" name="referencia" id="referencia" :value="client.referencia">
                    </form>
                    <div v-if="!this.groupSelect">
                        <div class="col-3">
                            <label for="">Grupo</label>
                            <select name="" id="" class="form-control" v-model="groupSelect" name="groupSelect">
                                <option value="">Seleccione una opcion</option>
                                <option v-for="option in gruposSelect" :value="option.name">{{option.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body pt-0 pb-0" v-if="this.groupSelect">
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es'">Nuevo Socio {{this.groupSelect}}</h3>
                        <h3 class="font-weight-bold" style="color: #00adef; display: inline-block;" v-else>New Member</h3>
                        <br>
                        <span v-if="lang == 'es'">(El asterisco rojo (*) indica campos obligatorios que deben completarse antes de poder continuar.*)</span>
                        <span v-else>(The red asterisk (*) indicates required fields that must be completed before you can continue.*)</span>
                        <br><br>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Tipo de Membresia<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Membership Type<span style="color:red">*</span></label>
                                    <select @change="validData()" name="typemembership" :class="typemembershipvalid" v-model="client.typemembership">
                                        <option value="Exit">Exit</option>
                                        <option value="Regular">Regular</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Idioma<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Lang<span style="color:red">*</span></label>
                                    <select @change="validData()" name="lang" :class="langvalid" v-model="client.lang">
                                        <option value="en">Ingles</option>
                                        <option value="es">Español</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Numero de Contrato<span style="color:red">*</span></label>
                                    <input @change="validData()" name="number_contract" :class="contractvalid" type="string" autocomplete="off" required class="form-control" v-model="client.number_contract" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Fecha de Compra<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Purchase Date<span style="color:red">*</span></label>
                                    <input onkeydown="return false" name="fechadecompra" :max="theDate" @change="onChangeDate()" type="date" required :class="fechacompravalid" v-model="client.fechac" />
                                </div>
                            </div>
                            <div class="col">
                                <label for="name" class="form-label" v-if="lang == 'es'">Años<span style="color:red">*</span></label>
                                <label for="name" class="form-label" v-else>Years<span style="color:red">*</span></label>
                                <select name="anios" @change="onChangeDate()" :class="yearsvalid" v-model="client.anios">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="lifetime">Lifetime</option>
                                </select>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Estatus<span style="color:red">*</span></label>
                                    <select v-if="lang == 'es'" :class="statusvalid" v-model="client.status" name="idioma" required>
                                        <option value="">Seleccione una opcion:</option>
                                        <option value="Pendiente">Pendiente</option>
                                        <option value="Procesable">Procesable</option>
                                    </select>
                                    <select v-else @change="validData()" :class="statusvalid" v-model="client.status" name="idioma" required>
                                        <option value="">Seleccione una opcion:</option>
                                        <option value="Pendiente">Pender</option>
                                        <option value="Procesable">Processable</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es'">Informacion del Socio</h3>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-else>Member Information</h3>
                        <h5 v-if="this.lang == 'en'"><b>Owner</b></h5>
                        <h5 v-if="this.lang == 'es'"><b>Titular</b></h5>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Nombre Titular<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-else>Owner Name<span style="color:red">*</span></label>
                                            <input @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.name,'name')" type="string" autocomplete="off" required :class="ownernamevalid" v-model="client.name" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Apellido Titular<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-else>Owner Last Name<span style="color:red">*</span></label>
                                            <input @change="validData()" name="apellidotitular" :onkeyup="capitalizarPrimeraLetra(client.apellidotitular,'apellido')" type="string" autocomplete="off" required :class="ownerlastnamevalid" v-model="client.apellidotitular" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Correo de Titular<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-else>Owner Email<span style="color:red">*</span></label>
                                            <input @change="validData()" name="email1" type="string" autocomplete="off" required :class="owneremailvalid" v-model="client.email1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-3">
                                        <label for="last_name" class="form-label" v-if="lang == 'es'">Código<span style="color:red">*</span></label>
                                        <label for="last_name" class="form-label" v-else>Code<span style="color:red">*</span></label>
                                        <select @change="validData()" name="international_code" id="international_code" required :class="codeownervalid" v-model="client.international_code_cel_titular">
                                            <option :value="null">Seleccione una opcion:</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Telefono<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-else>Phone Number<span style="color:red">*</span></label>
                                            <input @change="validData()" name="telefonotitular" type="string" autocomplete="off" required :class="numbervalid" v-model="client.telefonotitular" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Fecha de Nacimiento<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-else>Date of Birth<span style="color:red">*</span></label>
                                            <input @change="validData()" :max="theDateBirt" name="birthdate" type="date" autocomplete="off" required :class="birtvalid" v-model="client.birthdate" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-7">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Nacionalidad</label>
                                    <label for="name" class="form-label" v-else>Owner Nationality</label>
                                    <select @change="validData()" name="pais" class="form-control" v-model="client.nacionalidadtitular">
                                        <option v-for="option in countryOptions" :key="option.value" :value="option.text">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div><br>
                        <h5 v-if="this.lang == 'en'"><b>Marital Status</b></h5>
                        <h5 v-if="this.lang == 'es'"><b>Estado Civil</b></h5>
                        <div class="row">
                            <div class="col-2">
                                <span style="margin-left: 10px;">
                                    <input @change="selectStateC('soltero')" type="checkbox" v-model="client.estadocivilsoltero" />
                                    <label for="" v-if="lang == 'es'">Soltero</label>
                                    <label for="" v-else>Single</label>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <span style="margin-left: 10px;"><input @change="selectStateC('casado')" v-model="client.estadocivilcasado" type="checkbox" />
                                    <label for="" v-if="lang == 'es'">Casado</label>
                                    <label for="" v-else>Married</label>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <span style="margin-left: 10px;"><input @change="selectStateC('concubinato')" type="checkbox" v-model="client.estadocivilconcubinato" />
                                    <label for="" v-if="lang == 'es'">Concubinato</label>
                                    <label for="" v-else>Living Together</label>
                                </span>
                            </div>
                        </div>
                        <h5 v-if="this.lang == 'en'"><b>Co Owner</b></h5>
                        <h5 v-if="this.lang == 'es'"><b>Co Titular</b></h5>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Nombre(co-titular)</label>
                                    <label for="name" class="form-label" v-else>Co-Owner Name (Optional)</label>
                                    <input @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.namecotitular,'nameco')" name="namecotitular" type="string" autocomplete="off" required class="form-control" v-model="client.namecotitular" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Apellido(co-titular)</label>
                                    <label for="name" class="form-label" v-else>Co-Owner Last Name (Optional)</label>
                                    <input :onkeyup="capitalizarPrimeraLetra(client.apellidocotitular,'apellidoco')" name="apellidocotitular" type="string" autocomplete="off" required class="form-control" v-model="client.apellidocotitular" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Correo (Co-titular)(Opcional)</label>
                                    <label for="name" class="form-label" v-else>Co-Owner Email (Optional)</label>
                                    <input name="email2" type="string" autocomplete="off" required class="form-control" v-model="client.email2" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-3">
                                        <label for="last_name" class="form-label" v-if="lang == 'es'">Código</label>
                                        <label for="last_name" class="form-label" v-else>Code<span style="color:red">*</span></label>
                                        <select name="international_code" id="international_code" required class="form-control" v-model="client.international_code">
                                            <option value="" v-if="lang == 'es'">Seleccione una opcion:</option>
                                            <option value="" v-else>Select an option:</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label for="name" class="form-label" v-if="lang == 'es'">Numero de Casa</label>
                                        <label for="name" class="form-label" v-else>Phone Number<span style="color:red">*</span></label>
                                        <input name="numerocasa" type="string" autocomplete="off" required class="form-control" v-model="client.numerocasa" />
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Fecha de Nacimiento</label>
                                            <label for="name" class="form-label" v-else>Date of Birth<span style="color:red">*</span></label>
                                            <input @change="validData()" :max="theDateBirt" name="birthdatecotitular" type="date" autocomplete="off" required class="form-control" v-model="client.birthdatecotitular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-7">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Nacionalidad</label>
                                    <label for="name" class="form-label" v-else>Co-Owner Nationality</label>
                                    <select name="pais" class="form-control" v-model="client.nacionalidadcotitular">
                                        <option v-for="option in countryOptions" :key="option.value" :value="option.text">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="pais" class="form-label" v-if="lang == 'es'">Pais<span style="color:red">*</span></label>
                                    <label for="pais" class="form-label" v-else>Country<span style="color:red">*</span></label>
                                    <select name="pais" @change="validData()" :class="countryvalid" v-model="client.country">
                                        <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="estado" class="form-label" v-if="lang == 'es'">Estado<span style="color:red">*</span></label>
                                    <label for="estado" class="form-label" v-else>State<span style="color:red">*</span></label>
                                    <select :class="statevalid" @change="validData()" id="state" name="state" aria-label="state" v-model="client.state" :disabled="this.disabledState">
                                        <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Ciudad<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>City<span style="color:red">*</span></label>
                                    <input name="ciudad" @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.city,'city')" id="name" type="string" autocomplete="off" required :class="cityvalid" v-model="client.city" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Dirección<span style="color:red">*</span></label>
                                    <label for="name" v-else>Address<span style="color:red">*</span></label>
                                    <input name="direccion" @change="validData()" :onkeyup="capitalizarPrimeraLetra(client.direccion,'direccion')" type="string" autocomplete="off" required :class="addressvalid" v-model="client.direccion" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Código Postal<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Zip Code<span style="color:red">*</span></label>
                                    <input name="cp" type="string" @change="validData()" autocomplete="off" required :class="zipcodevalid" v-model="client.cp" />
                                </div>
                            </div>
                        </div>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es'">Beneficiarios</h3>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-else>Friends and Family</h3>
                        <div class="row">
                            <div class="col">
                                <label v-if="lang == 'es'" style="font-size: 18px;">Nombre</label>
                                <label v-else style="font-size: 18px;">Name</label>
                                <input :onkeyup="capitalizarPrimeraLetra(client.beneficiario1,'beneficiario1')" type="text" class="form-control" name="beneficiario1" v-model="client.beneficiario1"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.beneficiario2,'beneficiario2')" type="text" class="form-control" name="beneficiario2" v-model="client.beneficiario2"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.beneficiario3,'beneficiario3')" type="text" class="form-control" name="beneficiario3" v-model="client.beneficiario3"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.beneficiario4,'beneficiario4')" type="text" class="form-control" name="beneficiario4" v-model="client.beneficiario4"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.beneficiario5,'beneficiario5')" type="text" class="form-control" name="beneficiario5" v-model="client.beneficiario5"><br>
                            </div>
                            <div class="col">
                                <label v-if="lang == 'es'" style="font-size: 18px;">Parentesco</label>
                                <label v-else style="font-size: 18px;">Relation</label>
                                <input :onkeyup="capitalizarPrimeraLetra(client.parentesco1,'parentesco1')" type="text" class="form-control" name="parentesco1" v-model="client.parentesco1"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.parentesco2,'parentesco2')" type="text" class="form-control" name="parentesco2" v-model="client.parentesco2"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.parentesco3,'parentesco3')" type="text" class="form-control" name="parentesco3" v-model="client.parentesco3"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.parentesco4,'parentesco4')" type="text" class="form-control" name="parentesco4" v-model="client.parentesco4"><br>
                                <input :onkeyup="capitalizarPrimeraLetra(client.parentesco5,'parentesco5')" type="text" class="form-control" name="parentesco5" v-model="client.parentesco5"><br>
                            </div>
                        </div>
                        <hr>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es'">Beneficios de la Membresia</h3>
                        <h3 class="font-weight-bold" style="color: #00adef;" v-else>Membership Benefits</h3>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Vip Weeks por Año<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Vip Weeks per Year<span style="color:red">*</span></label>
                                    <select name="vipclubweeks" @change="validData()" :class="vipclubweeksvalid" required class="form-control" v-model="client.vipclubweeks">
                                        <option value="" v-if="lang == 'es'">Seleccione una opcion:</option>
                                        <option value="" v-else>Select an option:</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="Unlimited">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Smart Weeks Por Año<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Smart Weeks per Year<span style="color:red">*</span></label>
                                    <select name="smartweeks" @change="validData()" :class="smartweeksvalid" required class="form-control" v-model="client.smartweeks">
                                        <option value="" v-if="lang == 'es'">Seleccione una opcion:</option>
                                        <option value="" v-else>Select an option:</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="Unlimited">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Last Minute Weeks por Año<span style="color:red">*</span></label>
                                    <label for="name" class="form-label" v-else>Last Minute Weeks per Year<span style="color:red">*</span></label>
                                    <select name="lastminuteweeks" @change="validData()" :class="lastminuteweeksvalid" required class="form-control" v-model="client.lastminuteweeks">
                                        <option value="" v-if="lang == 'es'">Seleccione una opcion:</option>
                                        <option value="" v-else>Select an option:</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="Unlimited">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h5 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es'">Incentivos</h5>
                        <h5 class="font-weight-bold" style="color: #00adef;" v-else>Incentives</h5>
                        <div class="row">
                            <div class="col">
                                Travel Voucher
                            </div>
                            <div class="col">
                                Interval International
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <select name="travelvouchers" required class="form-control" v-model="client.numTravelVoucher">
                                            <option value="" v-if="lang == 'es'">Seleccione una opcion:</option>
                                            <option value="" v-else>Select an option:</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input name="descTravelVoucher" id="descTravelVoucher" required class="form-control" v-model="client.descTravelVoucher" />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-3">
                                        <input type="checkbox" name="travelvouchers" required class="form-control" v-model="client.totalintervalinternacional" />
                                    </div>
                                    <div class="col">
                                        <input name="intervalinternacional" type="string" autocomplete="off" required class="form-control" v-model="client.intervalinternacional" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <h5 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es'">Precios de la Membresia</h5>
                            <h5 class="font-weight-bold" style="color: #00adef;" v-else>Membership Payment Details</h5>
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Precio<span style="color:red">*</span></label>
                                            <label for="name" class="form-label" v-else>Price<span style="color:red">*</span></label>
                                            <input name="precio" type="string" @change="validData()" autocomplete="off" required :class="pricevalid" v-model="client.price" />
                                        </div>
                                        <div class="col-4">
                                            
                                            <h5 v-if="this.lang == 'en'"><label>Currency<span style="color:red">*</span></label></h5>
                                            <h5 v-if="this.lang == 'es'"><label>Moneda<span style="color:red">*</span></label></h5>
                                            <select required :class="currencyvalid" v-model="client.currency">
                                                <option value="MXN">MXN</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </div>
                                        <div class="col-3" v-if="this.groupSelect == 'Vacancy Rewards Costa Rica'">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Referencia De Pago</label>
                                            <label for="name" class="form-label" v-else>Payment Reference</label>
                                            <input name="referencia" type="string" autocomplete="off" required class="form-control" v-model="client.referencia" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3" v-if="this.groupSelect != 'Vacancy Rewards Costa Rica'">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Pago Inicial (%)</label>
                                    <label for="name" class="form-label" v-else>Initial Payment (%)</label>
                                    <input name="payinit" type="string" @change="onChangePercentaje()" autocomplete="off" required class="form-control" v-model="client.payinitpercentaje" />
                                </div>
                                <div class="col" v-if="this.groupSelect != 'Vacancy Rewards Costa Rica'">
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Pago Inicial ($)</label>
                                            <label for="name" class="form-label" v-else>Initial Payment ($)</label>
                                            <input name="payinit" type="string" autocomplete="off" required class="form-control" v-model="client.payinit" />
                                        </div>
                                    </div>
                                </div>
                            </div><br>
                            <div class="row" v-if="this.groupSelect != 'Vacancy Rewards Costa Rica'">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label">ADP1</label>
                                            <input name="adp1" type="string" autocomplete="off" required @change="onChangePercentaje()" class="form-control" v-model="client.adp1" />
                                        </div>
                                        <div class="col">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Pago ADP1</label>
                                            <label for="name" class="form-label" v-else>ADP1 Date</label>
                                            <input name="dateadp1" type="date" autocomplete="off" required class="form-control" v-model="client.dateadp1" />
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label">ADP2</label>
                                            <input name="adp2" type="string" autocomplete="off" required @change="onChangePercentaje()" class="form-control" v-model="client.adp2" />
                                        </div>
                                        <div class="col">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Pago ADP2</label>
                                            <label for="name" class="form-label" v-else>ADP2 Date</label>
                                            <input name="dateadp2" type="date" autocomplete="off" required class="form-control" v-model="client.dateadp2" />
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label">ADP3</label>
                                            <input name="adp3" type="string" autocomplete="off" required @change="onChangePercentaje()" class="form-control" v-model="client.adp3" />
                                        </div>
                                        <div class="col">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Pago ADP3</label>
                                            <label for="name" class="form-label" v-else>ADP3 Date</label>
                                            <input name="dateadp3" type="date" autocomplete="off" required class="form-control" v-model="client.dateadp3" />
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="name" class="form-label" v-if="lang == 'es'">Costo de Contrato</label>
                                            <label for="name" class="form-label" v-else>Contract Cost</label>
                                            <input name="tarifacierre" type="string" autocomplete="off" required class="form-control" v-model="client.tarifacierre" />
                                        </div>
                                        <div class="col">
                                            <label v-if="lang == 'es'">Saldo a Financiar</label>
                                            <label v-else>Balance to be Financed</label>
                                            <input name="financiamiento" type="text" autocomplete="off" required disabled="true" class="form-control" v-model="client.financiamiento" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <br>
                            <h5 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'es' && this.groupSelect != 'Vacancy Rewards Costa Rica'">Mensualidades</h5>
                            <h5 class="font-weight-bold" style="color: #00adef;" v-if="lang == 'en' && this.groupSelect != 'Vacancy Rewards Costa Rica'">Monthly Payments</h5>
                            <div class="row" v-if="this.groupSelect != 'Vacancy Rewards Costa Rica'">
                                <div class="col-2">
                                    <label v-if="lang == 'es'">Numero de Meses</label>
                                    <label v-else>Months</label>
                                    <select required @change="onChangeMes()" class="form-control" v-model="client.numbermonth">
                                        <option value="">Seleccione Opcion</option>
                                        <option v-if="groupSelect == 'Bajio' || client.resort == 'Bajio' || client.resort == 'Vacancy Rewards Costa Rica' || client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort == 'Crab House' || client.resort == 'Socios Monterrey' || client.resort == 'Peruvian Travel Club' || client.resort == 'Grupo Viaggi SAS'" value="6">6</option>
                                        <option v-if="groupSelect == 'Bajio' || client.resort == 'Bajio' || client.resort == 'Vacancy Rewards Costa Rica' || client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort == 'Crab House' || client.resort == 'Socios Monterrey' || client.resort2 == 'Vacancy Elite' || client.resort == 'Grupo Viaggi SAS'" value="12">12</option>
                                        <option v-if="groupSelect == 'Bajio' || client.resort == 'Bajio' || client.resort == 'Vacancy Rewards Costa Rica' || client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort == 'Crab House' || client.resort == 'Socios Monterrey' || client.resort2 == 'Vacancy Elite' || client.resort == 'Grupo Viaggi SAS'" value="18">18</option>
                                        <option v-if="groupSelect == 'Bajio' || client.resort == 'Bajio' || client.resort == 'Vacancy Rewards Costa Rica' || client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort2 == 'Vacancy Elite' || client.resort == 'Socios Monterrey' || client.resort == 'Grupo Viaggi SAS'" value="24">24</option>
                                        <option v-if="groupSelect == 'Bajio' || client.resort == 'Bajio' || client.resort == 'Vacancy Rewards Costa Rica' || client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR Rosarito' || client.resort == 'Socios Monterrey' || client.resort2 == 'Vacancy Elite' || client.resort == 'Grupo Viaggi SAS'" value="36">36</option>
                                    </select>
                                </div>
                                <div class="col-2">
                                    <label v-if="lang == 'es'">Intereses %</label>
                                    <label v-else>Interest %</label>
                                    <input name="montomensualidad" type="string" autocomplete="off" required class="form-control" disabled="true" v-model="client.intereses" />
                                </div>
                                <div class="col-2">
                                    <label>Factor IA %</label>
                                    <input name="factoria" type="string" autocomplete="off" required disabled="true" class="form-control" v-model="client.factoria" />
                                </div>
                                <div class="col-3">
                                    <label v-if="lang == 'es'">Primera Mensualidad</label>
                                    <label v-else>First Monthly Payment</label>
                                    <input name="primermensualidad" type="date" autocomplete="off" required class="form-control" v-model="client.primermensualidad" />
                                </div>
                                <div class="col">
                                    <div class="col">
                                        <label v-if="lang == 'es'">Monto Mensualidad</label>
                                        <label v-else>Monthly Payment</label>
                                        <input name="montomensualidad" type="string" autocomplete="off" required disabled="true" class="form-control" v-model="client.montomensualidad" />
                                    </div>
                                </div>
                            </div><br>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'es'">Agente de Ventas</label>
                                    <label for="name" class="form-label" v-else>Sales Agent</label>
                                    <input name="agente" :onkeyup="capitalizarPrimeraLetra(client.agente,'agente')" type="string" autocomplete="off" required class="form-control" v-model="client.agente" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <br>
                    <div class="row" v-if="this.groupSelect">
                        <div class="col-1"></div>
                        <div class="col-2">
                            <div class="form-group">
                                <a v-if="lang == 'es'" style="background-color: #00adef !important; width: 180px;" :disabled="loader" type="button" class="btn btn-primary" @click.prevent="sendForm">
                                    <i v-if="loader" class="fas fa-spin fa-spinner"></i>Guardar
                                </a>
                                <a v-else style="background-color: #00adef !important; width: 180px;" :disabled="loader" type="button" class="btn btn-primary" @click.prevent="sendForm">
                                    <i v-if="loader" class="fas fa-spin fa-spinner"></i>Save
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import serverData from './../mixins/serverData';
import * as moment from 'moment';
export default {
    mixins: [serverData],
    props: ['codes', 'years', 'now', 'sandbox', 'openid', 'publicopen', 'countries', 'totalvoucherpersale', 'maximumdiscountvoucher', 'maximumexpirationdate', 'typeofcharge', 'freetime', 'currencies', 'registrationapplies', 'cliente', 'chanel', 'lang', 'countriesgen', 'errors', 'user', 'grupos','grupselected'],
    data() {
        return {
            client: {
                number_contract: '',
                fechac: '',
                fecharenovacion: '',
                lang: '',
                estadocivilsoltero: false,
                estadocivilcasado: false,
                estadocivilconcubinato: false,
                totalintervalinternacional: '',
                certificadoautosval: '',
                recompensayates: '',
                recompensayatesval: '',
                name: '',
                apellidotitular: '',
                email1: '',
                namecotitular: '',
                apellidocotitular: '',
                email2: '',
                numerocasa: '',
                telefonotitular: '',
                telefonocotitular: '',
                country: null,
                state: null,
                city: '',
                direccion: '',
                cp: '',
                status: '',
                precio: null,
                moneda: null,
                vipclubweeks: '',
                lastminuteweeks: '',
                smartweeks: '',
                hotelescheck: 1,
                tourscheck: 1,
                cruceroscheck: 1,
                trasladoscheck: 1,
                rentaautocheck: 1,
                typemembership: '',
                dealscheck: 1,
                descTravelVoucher: 0,
                intervalinternacional: '',
                certificadoautos: '',
                recompensayates: '',
                recompensacondominios: '',
                agente: '',
                international_code: null,
                international_code_cel_titular: null,
                international_code_cel_cotitular: null,
                numTravelVoucher: 0,
                price: '',
                payinitpercentaje: '',
                beneficiario1: '',
                beneficiario2: '',
                beneficiario3: '',
                beneficiario4: '',
                beneficiario5: '',
                parentesco1: '',
                parentesco2: '',
                parentesco3: '',
                parentesco4: '',
                parentesco5: '',
                payinit: '',
                adp1: 0,
                dateadp1: '',
                adp2: 0,
                dateadp2: '',
                adp3: 0,
                dateadp3: '',
                tarifacierre: '',
                financiamiento: '',
                numbermonth: '',
                montomensualidad: '',
                currency: '',
                intereses: '',
                factoria: '',
                anios: '',
                primermensualidad: '',
                nacionalidadtitular: '',
                nacionalidadcotitular: '',
                birthdate: '',
                birthdatecotitular: '',
                cdp1: 0,
                datecdp1: '',
                cdp2: 0,
                datecdp2: '',
                cdp3: 0,
                datecdp3: '',
                referencia:''
            },
            theDate: moment().format('YYYY-MM-DD'),
            theDateBirt: moment().format('MM-DD-YYYY'),
            all: true,
            loader: false,
            disabledState: false,
            mensualidad: 0,
            fechacompravalid: 'form-control is-invalid',
            yearsvalid: 'form-control is-invalid',
            ownernamevalid: 'form-control is-invalid',
            ownerlastnamevalid: 'form-control is-invalid',
            owneremailvalid: 'form-control is-invalid',
            codeownervalid: 'form-control is-invalid',
            numbervalid: 'form-control is-invalid',
            statusvalid: 'form-control is-invalid',
            countryvalid: 'form-control is-invalid',
            statevalid: 'form-control is-invalid',
            cityvalid: 'form-control is-invalid',
            addressvalid: 'form-control is-invalid',
            zipcodevalid: 'form-control is-invalid',
            pricevalid: 'form-control is-invalid',
            currencyvalid: 'form-control is-invalid',
            contractvalid: 'form-control is-invalid',
            typemembershipvalid: 'form-control is-invalid',
            langvalid: 'form-control is-invalid',
            birtvalid: 'form-control is-invalid',
            vipclubweeksvalid:'form-control is-invalid',
            smartweeksvalid:'form-control is-invalid',
            lastminuteweeksvalid:'form-control is-invalid',
            groupSelect: '',
            gruposSelect: []
        }
    },
    computed: {
        countryOptions() {
            if (this.lang == 'es') {
                return [{ text: 'Elija un pais', value: null }].concat(this.countriesgen.map(country => {
                    return {
                        text: country.Nombre.toUpperCase(),
                        value: country.id_pais
                    }
                }));
            } else {
                return [{ text: 'Choose a country', value: null }].concat(this.countriesgen.map(country => {
                    return {
                        text: country.Nombre.toUpperCase(),
                        value: country.id_pais
                    }

                }));
            }
        },
        countrySelected() {
            if (this.client.country === null) return null;
            return this.countriesgen.find(c => c.id_pais == this.client.country);
        },
        stateOptions() {
            let options = [];
            if (this.countrySelected !== null) {
                if (this.countrySelected.states.length == 0) {
                    this.disabledState = true;
                } else {
                    this.disabledState = false;
                }
                options = options.concat(this.countrySelected.states.map(state => {
                    return {
                        text: state.Nombre.toUpperCase(),
                        value: state.Id_estado
                    }
                }));

                if (options.length > 0) {
                    options = [{ text: 'Elija un estado', value: null }].concat(options);
                }
            }

            return options;
        }
    },
    methods: {
          capitalizarPrimeraLetra(palabra, campo) {
            if (!palabra) return;
            var palabraOri = '';
            var mayuscula = "";
            var minuscula = "";
            var palabraConvert = '';

            palabraOri = palabra.split(' ');

            for (var i = 0; i < palabraOri.length; i++) {
                mayuscula = palabraOri[i].substring(0, 1).toUpperCase();
                minuscula = palabraOri[i].substring(1).toLowerCase();
                if(i < 1)
                {
                    palabraConvert += mayuscula.concat(minuscula);
                }else{
                    palabraConvert += ' '+mayuscula.concat(minuscula);
                }
            }

            if (campo == 'name') {
                this.client.name = palabraConvert;
            } else if (campo == 'apellido') {
                this.client.apellidotitular = palabraConvert;
            } else if (campo == 'nameco') {
                this.client.namecotitular = palabraConvert;
            } else if (campo == 'apellidoco') {
                this.client.apellidocotitular = palabraConvert;
            } else if (campo == 'city') {
                this.client.city = palabraConvert;
            } else if (campo == 'direccion') {
                this.client.direccion = palabraConvert;
            } else if (campo == 'beneficiario1') {
                this.client.beneficiario1 = palabraConvert;
            } else if (campo == 'beneficiario2') {
                this.client.beneficiario2 = palabraConvert;
            } else if (campo == 'beneficiario3') {
                this.client.beneficiario3 = palabraConvert;
            } else if (campo == 'beneficiario4') {
                this.client.beneficiario4 = palabraConvert;
            } else if (campo == 'beneficiario5') {
                this.client.beneficiario5 = palabraConvert;
            } else if (campo == 'parentesco1') {
                this.client.parentesco1 = palabraConvert;
            } else if (campo == 'parentesco2') {
                this.client.parentesco2 = palabraConvert;
            } else if (campo == 'parentesco3') {
                this.client.parentesco3 = palabraConvert;
            } else if (campo == 'parentesco4') {
                this.client.parentesco4 = palabraConvert;
            } else if (campo == 'parentesco5') {
                this.client.parentesco5 = palabraConvert;
            } else if (campo == 'agente') {
                this.client.agente = palabraConvert;
            }
        },
        onChangeMes() {
            if (this.client.group == 'VR La Isla') {
                if (this.client.numbermonth == 6) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 12) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 24) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                }
            }else if (this.client.resort2 == 'Vacancy Elite') {
                if (this.client.numbermonth == 6) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 12) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 24) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 36) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                }
            }else {
                if (this.client.numbermonth == 6) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;

                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 12) {
                    this.client.intereses = 8;
                    this.client.factoria = 0.0869444;

                    this.mensualidad = (parseFloat(this.client.factoria) * parseFloat(this.client.financiamiento));
                    this.client.montomensualidad = this.mensualidad.toFixed(2);

                } else if (this.client.numbermonth == 24) {
                    this.client.intereses = 12;
                    this.client.factoria = 0.046875;
                    this.mensualidad = (parseFloat(this.client.factoria) * parseFloat(this.client.financiamiento));
                    this.client.montomensualidad = this.mensualidad.toFixed(2);
                } else if (this.client.numbermonth == 36) {
                    this.client.intereses = 14;
                    this.client.factoria = 0.0337731;
                    this.mensualidad = (parseFloat(this.client.factoria) * parseFloat(this.client.financiamiento));
                    this.client.montomensualidad = this.mensualidad.toFixed(2);
                }
            }    
        },
        validData() {
            if (this.client.status) {
                this.statusvalid = "form-control";
            }
            if (this.client.anios) {
                this.yearsvalid = "form-control";
            }

            if (this.client.fechac) {
                this.fechacompravalid = "form-control";
            }

            if (this.client.apellidotitular) {
                this.ownerlastnamevalid = "form-control";
            }


            if (this.client.email1) {
                this.owneremailvalid = "form-control";
            }

            if (this.client.name) {
                this.ownernamevalid = "form-control";
            }

            if (this.client.vipclubweeks) {
                this.vipclubweeksvalid = "form-control";
            }

            if (this.client.smartweeks) {
                this.smartweeksvalid = "form-control";
            }

            if (this.client.lastminuteweeks) {
                this.lastminuteweeksvalid = "form-control";
            }

            if (this.client.country) {
                this.countryvalid = "form-control";
            }

            if (this.client.city) {
                this.cityvalid = "form-control";
            }

            if (this.client.direccion) {
                this.addressvalid = "form-control";
            }

            if (this.client.cp) {
                this.zipcodevalid = "form-control";
            }

            if (this.client.price) {
                this.pricevalid = "form-control";
            }


            if (this.client.currency) {
                this.currencyvalid = "form-control";
            }

            if (this.client.international_code_cel_titular) {
                this.codeownervalid = "form-control";
            }
            if (this.client.telefonotitular) {
                this.numbervalid = "form-control";
            }

            if (this.client.state) {
                this.statevalid = "form-control";
            }

            if (this.client.number_contract) {
                this.client.number_contract = this.client.number_contract.replace(/\s/g, "");
                this.client.number_contract = this.client.number_contract.replace(/[\^*@!"#$%&/()=?¡!¿'\\´¨+.,;:{}~`°]/g, '');
                this.contractvalid = "form-control";
            }

            if (this.client.birthdate) {
                this.birtvalid = "form-control";
            }

            if (this.client.typemembership) {
                this.typemembershipvalid = "form-control";
            }

            if (this.client.lang) {
                this.langvalid = "form-control";
            }
        },
        async sendForm() {
            this.loader = true;
            var apellidoti = this.client.apellidotitular;
            var apellidoco = this.client.apellidocotitular;
            var nameti = this.client.name;
            var nameco = this.client.namecotitular;

            const { data } = await axios.post('/api-v1/getCoincidencies', {
                params: {
                    apellidoti,
                    apellidoco,
                    nameti,
                    nameco
                }
            });

            if (!data) {
                this.$refs.paymentForm.submit();
            } else {
                this.loader = false;
                Swal.fire({
                    title: 'Contract with same Name is already created. Are you are you sure you want to continue?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Continue',
                    denyButtonText: `Cancel`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.loader = true;
                        this.$refs.paymentForm.submit();
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        },
        onChangeDate() {
            var fecha = new Date(this.client.fechac);
            var dias = 365; // Número de días a agregar
            fecha.setDate(fecha.getDate() + dias);
            this.client.fecharenovacion = fecha.toISOString().split('T')[0];
            this.fechacompravalid = "form-control";

            if (this.client.anios) {
                this.yearsvalid = "form-control";
            }
        },
        onChangePercentaje() {
            this.client.payinit = parseFloat(this.client.payinitpercentaje * (this.client.price / 100)).toFixed(2);
            this.client.financiamiento = parseFloat(this.client.price - this.client.payinit - this.client.adp1 - this.client.adp2 - this.client.adp3 - this.client.cdp1 - this.client.cdp2 - this.client.cdp3).toFixed(2);
        },
        selectStateC($stateC) {
            if ($stateC == 'soltero') {
                this.client.estadocivilcasado = false;
                this.client.estadocivilconcubinato = false;
            } else if ($stateC == 'casado') {
                this.client.estadocivilsoltero = false;
                this.client.estadocivilconcubinato = false;
            } else if ($stateC == 'concubinato') {
                this.client.estadocivilsoltero = false;
                this.client.estadocivilcasado = false;
            } else {
                this.client.estadocivilcasado = false;
                this.client.estadocivilconcubinato = false;
                this.client.estadocivilsoltero = false;
            }
        },
        allSelected() {
            if (!this.all) {
                this.client.hotelescheck = false;
                this.client.tourscheck = false;
                this.client.cruceroscheck = false;
                this.client.trasladoscheck = false;
                this.client.rentaautocheck = false;
                this.client.dealscheck = false;
            } else {
                this.client.hotelescheck = true;
                this.client.tourscheck = true;
                this.client.cruceroscheck = true;
                this.client.trasladoscheck = true;
                this.client.rentaautocheck = true;
                this.client.dealscheck = true;
            }
        },
        validateOldData() {
            this.client.estadocivilcasado = false;
            this.client.estadocivilconcubinato = false;
            this.client.estadocivilsoltero = false;
            this.loader = false;
            if (this.hasOld('nametitular')) {
                this.client.name = this.old('nametitular');
            }
            if (this.hasOld('referencia')) {
                this.client.referencia = this.old('referencia');
            }
            if (this.hasOld('idioma')) {
                this.client.lang = this.old('idioma');
            }
            if (this.hasOld('fechacompra')) {
                this.client.fechac = this.old('fechacompra');
            }
            if (this.hasOld('fecharenovacion')) {
                this.client.fecharenovacion = this.old('fecharenovacion');
            }
            if (this.hasOld('estadocivilsoltero')) {
                this.client.estadocivilsoltero = this.old('estadocivilsoltero');
            }
            if (this.hasOld('estadocivilcasado')) {
                this.client.estadocivilcasado = this.old('estadocivilcasado');
            }
            if (this.hasOld('estadocivilconcubinato')) {
                this.client.estadocivilconcubinato = this.old('estadocivilconcubinato');
            }
            if (this.hasOld('apellidotitular')) {
                this.client.apellidotitular = this.old('apellidotitular');
            }
            if (this.hasOld('email')) {
                this.client.email1 = this.old('email');
            }
            if (this.hasOld('namecotitular')) {
                this.client.namecotitular = this.old('namecotitular');
            }
            if (this.hasOld('apellidocotitular')) {
                this.client.apellidocotitular = this.old('apellidocotitular');
            }
            if (this.hasOld('email2')) {
                this.client.email2 = this.old('email2');
            }
            if (this.hasOld('numerocasa')) {
                this.client.numerocasa = this.old('numerocasa');
            }
            if (this.hasOld('telefonotitular')) {
                this.client.telefonotitular = this.old('telefonotitular');
            }
            if (this.hasOld('telefonocotitular')) {
                this.client.telefonocotitular = this.old('telefonocotitular');
            }
            if (this.hasOld('country')) {
                this.client.country = this.old('country');
            }
            if (this.hasOld('state')) {
                this.client.state = this.old('state');
            }
            if (this.hasOld('city')) {
                this.client.city = this.old('city');
            }
            if (this.hasOld('direccion')) {
                this.client.direccion = this.old('direccion');
            }
            if (this.hasOld('cp')) {
                this.client.cp = this.old('cp');
            }
            if (this.hasOld('precio')) {
                this.client.precio = this.old('precio');
            }
            if (this.hasOld('moneda')) {
                this.client.moneda = this.old('moneda');
            }
            if (this.hasOld('vipclubweeks')) {
                this.client.vipclubweeks = this.old('vipclubweeks');
            }
            if (this.hasOld('smartweeks')) {
                this.client.smartweeks = this.old('smartweeks');
            }
            if (this.hasOld('hotelescheck')) {
                this.client.hotelescheck = this.old('hotelescheck');
            }
            if (this.hasOld('tourscheck')) {
                this.client.tourscheck = this.old('tourscheck');
            }
            if (this.hasOld('cruceroscheck')) {
                this.client.cruceroscheck = this.old('cruceroscheck');
            }
            if (this.hasOld('autoscheck')) {
                this.client.autoscheck = this.old('autoscheck');
            }
            if (this.hasOld('trasladoscheck')) {
                this.client.trasladoscheck = this.old('trasladoscheck');
            }
            if (this.hasOld('yatescheck')) {
                this.client.yatescheck = this.old('yatescheck');
            }
            if (this.hasOld('descTravelVoucher')) {
                this.client.descTravelVoucher = this.old('descTravelVoucher');
            }
            if (this.hasOld('intervalinternacional')) {
                this.client.intervalinternacional = this.old('intervalinternacional');
            }
            if (this.hasOld('certificadoautos')) {
                this.client.certificadoautos = this.old('certificadoautos');
            }
            if (this.hasOld('recompensayates')) {
                this.client.recompensayates = this.old('recompensayates');
            }
            if (this.hasOld('recompensacondominios')) {
                this.client.recompensacondominios = this.old('recompensacondominios');
            }
            if (this.hasOld('agente')) {
                this.client.agente = this.old('agente');
            }
            if (this.hasOld('international_code')) {
                this.client.international_code = this.old('international_code');
            }
            if (this.hasOld('numTravelVoucher')) {
                this.client.numTravelVoucher = this.old('numTravelVoucher');
            }
            if (this.hasOld('number_contract')) {
                this.client.number_contract = this.old('number_contract');
            }
            if (this.hasOld('totalintervalinternacional')) {
                this.client.totalintervalinternacional = this.old('totalintervalinternacional');
            }
            if (this.hasOld('certificadoautosval')) {
                this.client.certificadoautosval = this.old('certificadoautosval');
            }
            if (this.hasOld('recompensayatesval')) {
                this.client.recompensayatesval = this.old('recompensayatesval');
            }
            if (this.hasOld('status')) {
                this.client.status = this.old('status');
            }
            if (this.hasOld('lastminuteweeks')) {
                this.client.lastminuteweeks = this.old('lastminuteweeks');
            }
            if (this.hasOld('rentaautocheck')) {
                this.client.rentaautocheck = this.old('rentaautocheck');
            }
            if (this.hasOld('dealscheck')) {
                this.client.dealscheck = this.old('dealscheck');
            }
            if (this.hasOld('international_code_cel_titular')) {
                this.client.international_code_cel_titular = this.old('international_code_cel_titular');
            }
            if (this.hasOld('international_code_cel_cotitular')) {
                this.client.international_code_cel_cotitular = this.old('international_code_cel_cotitular');
            }
            if (this.hasOld('price')) {
                this.client.price = this.old('price');
            }
            if (this.hasOld('payinitpercentaje')) {
                this.client.payinitpercentaje = this.old('payinitpercentaje');
            }
            if (this.hasOld('payinit')) {
                this.client.payinit = this.old('payinit');
            }
            if (this.hasOld('adp1')) {
                this.client.adp1 = this.old('adp1');
            }
            if (this.hasOld('dateadp1')) {
                this.client.dateadp1 = this.old('dateadp1');
            }
            if (this.hasOld('adp2')) {
                this.client.adp2 = this.old('adp2');
            }
            if (this.hasOld('dateadp2')) {
                this.client.dateadp2 = this.old('dateadp2');
            }
            if (this.hasOld('adp3')) {
                this.client.adp3 = this.old('adp3');
            }
            if (this.hasOld('dateadp3')) {
                this.client.dateadp3 = this.old('dateadp3');
            }
            if (this.hasOld('cdp1')) {
                this.client.cdp1 = this.old('cdp1');
            }
            if (this.hasOld('datecdp1')) {
                this.client.datecdp1 = this.old('datecdp1');
            }
            if (this.hasOld('cdp2')) {
                this.client.cdp2 = this.old('cdp2');
            }
            if (this.hasOld('datecdp2')) {
                this.client.datecdp2 = this.old('datecdp2');
            }
            if (this.hasOld('cdp3')) {
                this.client.cdp3 = this.old('cdp3');
            }
            if (this.hasOld('datecdp3')) {
                this.client.datecdp3 = this.old('datecdp3');
            }
            if (this.hasOld('tarifacierre')) {
                this.client.tarifacierre = this.old('tarifacierre');
            }
            if (this.hasOld('financiamiento')) {
                this.client.financiamiento = this.old('financiamiento');
            }
            if (this.hasOld('numbermonth')) {
                this.client.numbermonth = this.old('numbermonth');
            }
            if (this.hasOld('montomensualidad')) {
                this.client.montomensualidad = this.old('montomensualidad');
            }
            if (this.hasOld('currency')) {
                this.client.currency = this.old('currency');
            }
            if (this.hasOld('intereses')) {
                this.client.intereses = this.old('intereses');
            }
            if (this.hasOld('factoria')) {
                this.client.factoria = this.old('factoria');
            }
            if (this.hasOld('primermensualidad')) {
                this.client.primermensualidad = this.old('primermensualidad');
            }
            if (this.hasOld('nacionalidadtitular')) {
                this.client.nacionalidadtitular = this.old('nacionalidadtitular');
            }
            if (this.hasOld('nacionalidadcotitular')) {
                this.client.nacionalidadcotitular = this.old('nacionalidadcotitular');
            }
            if (this.hasOld('anios')) {
                this.client.anios = this.old('anios');
            }
            if (this.hasOld('beneficiario1')) {
                this.client.beneficiario1 = this.old('beneficiario1');
            }
            if (this.hasOld('beneficiario2')) {
                this.client.beneficiario2 = this.old('beneficiario2');
            }
            if (this.hasOld('beneficiario3')) {
                this.client.beneficiario3 = this.old('beneficiario3');
            }
            if (this.hasOld('beneficiario4')) {
                this.client.beneficiario4 = this.old('beneficiario4');
            }
            if (this.hasOld('beneficiario5')) {
                this.client.beneficiario5 = this.old('beneficiario5');
            }
            if (this.hasOld('parentesco1')) {
                this.client.parentesco1 = this.old('parentesco1');
            }
            if (this.hasOld('parentesco2')) {
                this.client.parentesco2 = this.old('parentesco2');
            }
            if (this.hasOld('parentesco3')) {
                this.client.parentesco3 = this.old('parentesco3');
            }
            if (this.hasOld('parentesco4')) {
                this.client.parentesco4 = this.old('parentesco4');
            }
            if (this.hasOld('parentesco5')) {
                this.client.parentesco5 = this.old('parentesco5');
            }
            if (this.hasOld('groupSelect')) {
                this.groupSelect = this.old('groupSelect');
            }

            if (this.hasOld('birthdate')) {
                this.client.birthdate = this.old('birthdate');
            }
            if (this.hasOld('birthdatecotitular')) {
                this.client.birthdatecotitular = this.old('birthdatecotitular');
            }
            if (this.hasOld('typemembership')) {
                this.client.typemembership = this.old('typemembership');
            }
        }
    },
    mounted() {
        if(this.grupselected){
            this.groupSelect = this.grupselected;
        }

        if (this.user.group != 'all') {
            this.groupSelect = this.user.group_pert.name;
        } else {
            for (var i = 0; i < this.grupos.length; i++) {
                if (this.grupos[i].resort.name == this.user.club) {
                    this.gruposSelect.push(this.grupos[i]);
                }
            }
        }

        if (this.old('fechacompra')) {
            this.fechacompravalid = "form-control";
        }
        if (this.old('status')) {
            this.statusvalid = "form-control";
        }

        if (this.old('anios')) {
            this.yearsvalid = "form-control";
        }
        if (this.old('apellidotitular')) {
            this.ownerlastnamevalid = "form-control";
        }
        if (this.old('email')) {
            this.owneremailvalid = "form-control";
        }
        if (this.old('nametitular')) {
            this.ownernamevalid = "form-control";
        }

        if (this.old('country')) {
            this.countryvalid = "form-control";
        }
        if (this.old('city')) {
            this.cityvalid = "form-control";
        }
        if (this.old('direccion')) {
            this.addressvalid = "form-control";
        }
        if (this.old('cp')) {
            this.zipcodevalid = "form-control";
        }
        if (this.old('precio')) {
            this.pricevalid = "form-control";
        }
        if (this.old('moneda')) {
            this.currencyvalid = "form-control";
        }
        if (this.hasOld('telefonotitular')) {
            this.codeownervalid = "form-control";
        }
        if (this.hasOld('international_code')) {
            this.numbervalid = "form-control";
        }
        if (this.hasOld('number_contract') && this.old('number_contract')) {
            this.contractvalid = "form-control";
        }
        if (this.hasOld('birthdate')) {
            this.birtvalid = "form-control";
        }
        if(this.old('vipclubweeks')){
            this.vipclubweeksvalid  = "form-control";
        }
        if(this.old('smartweeks')){
            this.smartweeksvalid  = "form-control";
        }
        if(this.old('lastminuteweeks')){
            this.lastminuteweeksvalid  = "form-control";
        }


        this.validateOldData();

        if (this.old('estadocivilsoltero') == 'true') {
            this.client.estadocivilsoltero = true;
            this.client.estadocivilcasado = false;
            this.client.estadocivilconcubinato = false;
        } else if (this.old('estadocivilcasado') == 'true') {
            this.client.estadocivilcasado = true;
            this.client.estadocivilsoltero = false;
            this.client.estadocivilconcubinato = false;
        } else if (this.old('estadocivilconcubinato') == 'true') {
            this.client.estadocivilconcubinato = true;
            this.client.estadocivilsoltero = false;
            this.client.estadocivilcasado = false;
        } else {
            this.client.estadocivilcasado = false;
            this.client.estadocivilconcubinato = false;
            this.client.estadocivilsoltero = false;
        }
    }
}

</script>
