<template>
  <div class="container mt-3">
    <div class="card border-0 shadow-sm">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div v-if="loadingYearly">
              Cargando
            </div>
            <year-membership-statistics v-else
              :memberships="membershipYearlyData"
              :colors="['rgba(54, 162, 235, 1)','rgba(255, 99, 132, 1)','rgba(255, 206, 86, 1)','rgba(75, 192, 192, 1)','rgba(153, 102, 255, 1)','rgba(255, 159, 64, 1)']"  
            ></year-membership-statistics>
          </div>

          <div class="col-md-6">
            <div v-if="loadingMonthly">
              Cargando
            </div>
            <div class="row" v-else>
              <div class="col-md-6" v-for="(d, index) in membershipMonthyData" :key="index">
                <month-membership-statistics
                  :title="d.title" 
                  :labels="d.labels" 
                  :subscriptions="d.subscriptions"
                ></month-membership-statistics>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  props: {
    range: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      membershipYearlyData: [],
      membershipMonthyData: [],
      loadingYearly: false,
      loadingMonthly: false,
    };
  },
  mounted() {
    this.loadMembershipsYearlyData();
    this.loadMembershipsMontlyData();
  },
  methods: {
    async loadMembershipsYearlyData() {
      this.loadingYearly = true;
      let year = this.range.start.year();
      let { data } = await axios.get('/api-v1/dashboard/memberships-yearly', {
        params: {
          year
        }
      });

      let ms = _.groupBy(data.memberships, 'membership_name');

      this.membershipYearlyData = Object.keys(ms).map(m => {
        let sorted = ms[m].sort((x, y) => x.month_number < y.month_number);
        return {
          label: m,
          subscriptions: sorted.map(s => s.created_month)
        };
      });

      this.loadingYearly = false;
    },
    async loadMembershipsMontlyData() {
      this.loadingMonthly = true;
      let { data } = await axios.get('/api-v1/dashboard/memberships-monthly', {
        params: {
          start: this.range.start.format('YYYY-MM-DD'),
          end: this.range.end.format('YYYY-MM-DD')
        }
      });


      
      this.membershipMonthyData = data.memberships.map(m => {
        return {
          title: m.name,
          labels: [`Altas: ${m.created}`, `Bajas ${m.unsubscribed}`],
          subscriptions: [m.created, m.unsubscribed],
        }
      });

      this.loadingMonthly = false;
    },

  },
  watch: {
    range(newRange, oldRange) {
      if (newRange.start.year() !== oldRange.start.year()) {
        this.loadMembershipsYearlyData();
      }

      this.loadMembershipsMontlyData();
    },
  }
}
</script>
