/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import * as VueGoogleMaps from 'vue2-google-maps'
const BootstrapVue = require('bootstrap-vue');
const vSelect = require('vue-select');
const DatePicker = require('vue2-datepicker');
require('vue2-datepicker/locale/es');

Vue.use(BootstrapVue)
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDm4ZyGGAY9uNdGKswb4CP2uLNZ5wiepHE'
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
let token = document.head.querySelector('meta[name="csrf-token"]').content;

Vue.component('csrf-token', {
    functional: true,
    render: function(h) {
        return h('input', {
            attrs: {
                type: 'hidden',
                name: '_token',
                value: token
            }
        });
    }
});

Vue.component('request-method', {
    functional: true,
    props: {
        method: {
            type: String,
            required: true
        }
    },
    render: function(h, context) {
        return h('input', {
            attrs: {
                type: 'hidden',
                name: '_method',
                value: context.props.method
            }
        })
    }
});

Vue.directive('loaderOnClick', {
    inserted: function(el) {
        el.addEventListener('click', function(e) {
            let container = document.createElement('div');
            container.setAttribute('class', 'd-flex flex-column justify-content-center align-items-center position-fixed fixed-top w-100 h-100 bg-loader');

            let loaderContainer = document.createElement('div');
            let textContainer = document.createElement('div');
            textContainer.setAttribute('class', 'text-white h5')

            let text = document.createTextNode('Espere...');

            let loader = document.createElement('div');
            loader.setAttribute('class', 'spinner-grow text-white');

            let loader2 = document.createElement('div');
            loader2.setAttribute('class', 'spinner-grow text-white');

            let loader3 = document.createElement('div');
            loader3.setAttribute('class', 'spinner-grow text-white');

            loaderContainer.appendChild(loader);
            loaderContainer.appendChild(loader2);
            loaderContainer.appendChild(loader3);

            textContainer.appendChild(text);

            container.appendChild(loaderContainer);
            container.appendChild(textContainer);

            document.body.appendChild(container);
        });
    }
});

Vue.component('new-master-detail-membership-referidos', require('./components/NewMasterDetailMembershipReferidos.vue').default);
Vue.component('new-master-detail-membership', require('./components/NewMasterDetailMembership.vue').default);
Vue.component('new-master-detail-membership-master', require('./components/NewMasterDetailMembershipMaster.vue').default);
Vue.component('new-master-detail-membership-master-api', require('./components/NewMasterDetailMembershipMasterApi.vue').default);
Vue.component('new-master-detail-membership-elite', require('./components/NewMasterDetailMembershipElite.vue').default);
Vue.component('new-master-detail-membership-kovay', require('./components/NewMasterDetailMembershipKovay.vue').default);
Vue.component('new-master-detail-membership-kovay-api', require('./components/NewMasterDetailMembershipKovayApi.vue').default);
Vue.component('new-master-detail-membership-monterrey', require('./components/NewMasterDetailMembershipMonterrey.vue').default);
Vue.component('new-master-detail-membership-cr', require('./components/NewMasterDetailMembershipCR.vue').default);
Vue.component('view-detail-membership', require('./components/ViewDetailMembership.vue').default);
Vue.component('new-master-detail-membership-mundo', require('./components/NewMasterDetailMembershipMundo.vue').default);
Vue.component('new-master-detail-membership-casa', require('./components/NewMasterDetailMembershipCasaLinda.vue').default);
Vue.component('new-master-detail-membership-new', require('./components/NewMasterDetailMembershipNew.vue').default);
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('stripe-checkoutr', require('./components/StripeCheckoutR.vue').default);
Vue.component('stripe-checkout', require('./components/StripeCheckout.vue').default);
Vue.component('card-component', require('./components/Card.js').default);
Vue.component('form-coupon', require('./components/FormCoupons.vue').default);
Vue.component('date-picker', DatePicker.default);
Vue.component('form-datepicker', require('./components/FormDatepicker.vue').default);
Vue.component('v-select', vSelect.default);
Vue.component('resource-select', require('./components/ResourceSelect.vue').default);
Vue.component('upload', require('./components/Upload.vue').default);
Vue.component('delete-form-confirmation', require('./components/DeleteFormConfirmation.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('coupons', require('./components/Coupons.vue').default);
Vue.component('coupon-types', require('./components/CouponTypes.vue').default);
Vue.component('membership-types', require('./components/MembershipTypes.vue').default);
Vue.component('transportation-categories', require('./components/TransportationCategories').default);
Vue.component('new-vehicle-form', require('./components/NewVehicleForm.vue').default);
Vue.component('availability-admin', require('./components/AvailabilityAdmin.vue').default);
Vue.component('master-detail', require('./components/MasterDetail.vue').default);
Vue.component('master-detail-membership', require('./components/MasterDetailMembership.vue').default);
Vue.component('master-detail-condos', require('./components/MasterDetailCondos.vue').default);
Vue.component('banner', require('./components/Banner.vue').default);
Vue.component('year-membership-statistics', require('./components/charts/YearMembershipStatistics.vue').default);
Vue.component('month-membership-statistics', require('./components/charts/MonthMembershipStatistics.vue').default);
Vue.component('dashboard', require('./components/Dashboard.vue').default);
Vue.component('country-cities', require('./components/CountryCities.vue').default);
Vue.component('images-carrousel', require('./components/Images.vue').default);
Vue.component('new-membership-type', require('./components/NewMembershipType.vue').default);
Vue.component('new-select-list', require('./components/NewSelectList.vue').default);
Vue.component('membership-type-form', require('./components/MembershipTypeForm.vue').default);
Vue.component('find-sms-user-chanel', require('./components/FindSmsUserChanel.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
