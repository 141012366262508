<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ['memberships', 'colors'],
  mounted() {
    
    let data = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: this.memberships.map((m, i) => {
            let color = this.colors[i % this.colors.length];
            return {
                fill: false,
                label: m.label,
                data: m.subscriptions,
                backgroundColor: [
                    color
                    /*'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'*/
                ],
                borderColor: [
                    color
                    /*'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'*/
                ],
                borderWidth: 1
            }
        })
    };

    let options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 3,
        title: {
            display: true,
            text: 'Anual'
        },
        scales: {
            x: {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Meses'
                }
            },
            y: {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Suscripciones'
                }
            }
        }
    };

    this.renderChart(data, options);
  }
}
</script>