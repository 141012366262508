<template>
  <div>
    <div v-if="this.mostrarInputs" class="row mb-3">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm py-2">
          <div class="card-body pt-0 pb-0">
            <div class="mb-4">
              <h2 class="text-primary font-weight-bold">Membresias</h2>
            </div>
            <div class="card">
              <div class="row" style="margin-right: 4px; margin-left: 4px; margin-top: 10px;">
                <div class="col-md-2">
                  <label for="name" class="form-label">Usuario 1: </label>
                </div>
                <div class="form-group col-md-5">
                  <label for="name" class="form-label">Nombre</label>
                  <input
                    name="name"
                    id="name"
                    type="string"
                    placeholder="John"
                    autocomplete="off"
                    required
                    data-msg="Please enter your name"
                    class="form-control"
                    v-model="client.name"
                  />
                </div>
                  <div class="form-group col-md-5">
                    <label for="last_name" class="form-label">Apellidos</label>
                    <input
                      name="last_name"
                      id="last_name"
                      type="string"
                      placeholder="Doe"
                      autocomplete="off"
                      required
                      data-msg="Please enter your last name"
                      class="form-control"
                      v-model="client.last_name"
                    />
                  </div>
              </div>
              <div class="row" style="margin-right: 4px; margin-left: 4px;">
                <div class="form-group col-md-4">
                  <label for="name" class="form-label">Correo</label>
                  <input
                    name="mail"
                    id="name"
                    type="string"
                    placeholder="John@mail.com"
                    autocomplete="off"
                    required
                    data-msg="Please enter your name"
                    class="form-control"
                    v-model="client.email"
                  />
                  <center><label for="name" class="form-label">{{this.validMail}}</label></center>
                </div>
                <div class="form-group col-md-2"><br>
                  <button :disabled="loader" type="button" class="btn btn-outline-primary" @click="onChangeMail">
                    <i v-if="loader" class="fas fa-spin fa-spinner"></i>Validar correo
                  </button>
                </div>
                 <div class="form-group col-md-2">
                  <label for="last_name" class="form-label">Codigo De Pais</label>
                  <select
                    name="international_code"
                    id="international_code"
                    required
                    class="form-control"
                    v-model="client.international_code"
                  >
                    <option :value="null">Seleccione una opcion</option>
                    <option :value="'+52'">MEXICO +52</option>
                    <option :value="'+1'">UNITED STATES +1</option>
                    <option :value="'+1'">CANADA +1</option>
                    <option :value="'+57'">COLOMBIA +57</option>
                    <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                <label for="cellphone_1" class="form-label">Celular #1</label>
                  <input
                    name="cellphone_1"
                    id="cellphone_1"
                    type="string"
                    placeholder="999 555 6226"
                    autocomplete="off"
                    required
                    data-msg="Please enter cellphone_1"
                    class="form-control"
                    v-model="client.cellphone_1"
                  />
                </div>
              </div>
            </div><br>
            <div class="card">
              <div class="row" style="margin-right: 4px; margin-left: 4px; margin-top: 10px;">
                <div class="col-md-2">
                  <label for="related_person_name" class="form-label">Usuario 2: </label>
                </div>
                <div class="form-group col-md-5">
                  <label for="related_person_name" class="form-label">Nombre</label>
                  <input
                    name="related_person_name"
                    id="related_person_name"
                    type="string"
                    placeholder="John"
                    autocomplete="off"
                    required
                    data-msg="Please enter your name"
                    class="form-control"
                    v-model="client.related_person_name"
                  />
                </div>
                  <div class="form-group col-md-5">
                    <label for="related_person_last_name" class="form-label">Apellidos</label>
                    <input
                      name="related_person_last_name"
                      id="related_person_last_name"
                      type="string"
                      placeholder="Doe"
                      autocomplete="off"
                      required
                      data-msg="Please enter your last name"
                      class="form-control"
                      v-model="client.related_person_last_name"
                    />
                  </div>
              </div>
              <div class="row" style="margin-right: 4px; margin-left: 4px;">
                <div class="form-group col-md-6">
                  <label for="cellphone_2" class="form-label">Celular #2</label>
                  <input
                    name="cellphone_2"
                    id="cellphone_2"
                    type="string"
                    placeholder="999 555 6226"
                    autocomplete="off"
                    required
                    data-msg="Please enter cellphone_2"
                    class="form-control"
                    v-model="client.cellphone_2"
                  />
                </div>
                 <div class="form-group col-md-6">
                  <label for="mail_2" class="form-label">Correo 2</label>
                  <input
                    name="mail_2"
                    id="mail_2"
                    type="string"
                    placeholder="John@mail.com"
                    autocomplete="off"
                    required
                    class="form-control"
                    v-model="client.mail_2"
                  />
                </div>
              </div>
            </div><br>
            <div class="row">
              <div class="form-group col-md-3">
                <label for="last_name" class="form-label">Telefono de casa</label>
                <input
                  name="phone_number"
                  id="phone_number"
                  type="string"
                  placeholder="999 555 6226"
                  autocomplete="off"
                  required
                  data-msg="Please enter phone_number"
                  class="form-control"
                  v-model="client.phone_number"
                />
              </div>
              <div class="form-group col-md-7">
                <label for="name" class="form-label">Direccion</label>
                <input
                  name="address"
                  id="address"
                  type="string"
                  placeholder="av. comlomb"
                  autocomplete="off"
                  required
                  data-msg="Please enter your address"
                  class="form-control"
                  v-model="client.address"
                />
              </div>
               <div class="form-group col-md-2">
                <label for="name" class="form-label">C.P.</label>
                <input
                  name="cp"
                  id="cp"
                  type="string"
                  autocomplete="off"
                  required
                  data-msg="Please enter your cp"
                  class="form-control"
                  v-model="client.zip_code"
                />
              </div>
             
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label for="country" class="form-label">Pais</label>
                <select 
                  class="form-control" 
                  id="country"
                  name="country"
                  aria-label="country"
                  v-model="client.country"
                >
                  <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="state" class="form-label">Estado</label>
                <select 
                  class="form-control" 
                  id="state"
                  name="state"
                  aria-label="state"
                  v-model="client.state"
                >
                  <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="city" class="form-label">Ciudad</label>
                <input type="text"
                  class="form-control" 
                  id="city"
                  name="city"
                  aria-label="city"
                  autocomplete="off"
                  v-model="client.city"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="last_name" class="form-label">Idioma</label>
                <select
                  name="international_lang"
                  id="international_lang"
                  required
                  class="form-control"
                  v-model="client.lang"
                >
                  <option value="es">Español</option>
                  <option value="en">Ingles</option>
                </select>
              </div>
            </div>
            <div class="row">
               <div class="form-group col-md-3">
                <label for="name" class="form-label">Membresia</label>
                <select
                    name="membership_type"
                    id="membership_type"
                    required
                    class="form-control"
                    v-model="client.membership_type"
                  >
                    <option :value="null">Seleccione una opcion</option>
                    <option v-for="(item, index) in membershipTypes" :key="index" :value="item.id">{{ item.name }} / {{ item.name_en }}</option>
                  </select>
              </div>
              <div class="form-group col-md-2">
                <label for="name" class="form-label">Tipo cobro</label>
                <select
                    v-if="this.typeofcharge == 'ma' || this.typeofcharge == ''"
                    name="membership_type_pay"
                    id="membership_type_pay"
                    required
                    class="form-control"
                    @change="onChange"
                    v-model="client.membership_type_pay"
                  >
                    <option value="null">Seleccione una opcion</option>
                    <option value="M">Mensual</option>
                    <option value="A">Anual</option>
                </select>
                <select
                    v-if="this.typeofcharge == 'm'"
                    name="membership_type_pay"
                    id="membership_type_pay"
                    required
                    class="form-control"
                    @change="onChange"
                    v-model="client.membership_type_pay"
                  >
                    <option selected="true" value="M">Mensual</option>
                </select>
                <select
                    v-if="this.typeofcharge == 'a'"
                    name="membership_type_pay"
                    id="membership_type_pay"
                    required
                    class="form-control"
                    @change="onChange"
                    v-model="client.membership_type_pay"
                  >
                    <option selected="true" value="A">Anual</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                  <label for="last_name" class="form-label">Tiempo de prueba</label>
                  <select
                    v-if="this.freetime == ''"
                    name="international_code"
                    id="international_code"
                    required
                    class="form-control"
                    v-model="client.free_trial_months"
                  >
                    <option value="null">Seleccione una opcion</option>
                    <option v-if="this.mostrarPruebas" value="1">1 Mes</option>
                    <option v-if="this.mostrarPruebas" value="2">2 Mes</option>
                    <option v-if="this.mostrarPruebas" value="3">3 Mes</option>
                    <option v-if="this.mostrarPruebas" value="4">4 Mes</option>
                    <option v-if="this.mostrarPruebas" value="5">5 Mes</option>
                    <option v-if="this.mostrarPruebas" value="6">6 Mes</option>
                    <option v-if="this.mostrarPruebas" value="7">7 Mes</option>
                    <option v-if="this.mostrarPruebas" value="8">8 Mes</option>
                  </select>

                  <select
                    v-if="this.freetime != ''"
                    name="international_code"
                    id="international_code"
                    required
                    class="form-control"
                    v-model="client.free_trial_months"
                  >
                    <option value="null">Seleccione una opcion</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 1" value="1">1 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 2" value="2">2 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 3" value="3">3 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 4" value="4">4 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 5" value="5">5 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 6" value="6">6 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 7" value="7">7 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 8" value="8">8 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 9" value="9">9 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 10" value="10">10 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 11" value="11">11 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 12" value="12">12 Mes</option>
                  </select>
              </div>
              <div class="form-group col-md-2">
                <label for="last_name" class="form-label"># Travel Vouchers</label>
                <select
                  v-if="this.totalvoucherpersale == ''"
                  name="international_lang"
                  id="international_lang"
                  required
                  class="form-control"
                  v-model="client.numTravelVoucher"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <select
                  v-if="this.totalvoucherpersale != ''"
                  name="international_lang"
                  id="international_lang"
                  required
                  class="form-control"
                  v-model="client.numTravelVoucher"
                >
                  <option v-if="this.totalvoucherpersale >= 0" value="0">0</option>
                  <option v-if="this.totalvoucherpersale >= 1" value="1">1</option>
                  <option v-if="this.totalvoucherpersale >= 2" value="2">2</option>
                  <option v-if="this.totalvoucherpersale >= 3" value="3">3</option>
                  <option v-if="this.totalvoucherpersale >= 4" value="4">4</option>
                  <option v-if="this.totalvoucherpersale >= 5" value="5">5</option>
                  <option v-if="this.totalvoucherpersale >= 6" value="6">6</option>
                  <option v-if="this.totalvoucherpersale >= 7" value="7">7</option>
                  <option v-if="this.totalvoucherpersale >= 8" value="8">8</option>
                  <option v-if="this.totalvoucherpersale >= 9" value="9">9</option>
                  <option v-if="this.totalvoucherpersale >= 10" value="10">10</option>
                  <option v-if="this.totalvoucherpersale >= 11" value="11">11</option>
                  <option v-if="this.totalvoucherpersale >= 12" value="12">12</option>
                  <option v-if="this.totalvoucherpersale >= 13" value="13">13</option>
                  <option v-if="this.totalvoucherpersale >= 14" value="14">14</option>
                  <option v-if="this.totalvoucherpersale >= 15" value="15">15</option>
                  <option v-if="this.totalvoucherpersale >= 16" value="16">16</option>
                  <option v-if="this.totalvoucherpersale >= 17" value="17">17</option>
                  <option v-if="this.totalvoucherpersale >= 18" value="18">18</option>
                  <option v-if="this.totalvoucherpersale >= 19" value="19">19</option>
                  <option v-if="this.totalvoucherpersale >= 20" value="20">20</option>
                  <option v-if="this.totalvoucherpersale >= 21" value="21">21</option>
                  <option v-if="this.totalvoucherpersale >= 22" value="22">22</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="last_name" class="form-label">Descuento Vouchers</label>
                <input v-if="this.maximumdiscountvoucher == ''" class="form-control" type="text" v-model="client.descTravelVoucher"/>
                <select
                  v-if="this.maximumdiscountvoucher != ''"
                  name="descTravelVoucher"
                  id="descTravelVoucher"
                  required
                  class="form-control"
                  v-model="client.descTravelVoucher"
                >
                  <option v-if="this.maximumdiscountvoucher >= 25" value="25">25</option>
                  <option v-if="this.maximumdiscountvoucher >= 50" value="50">50</option>
                  <option v-if="this.maximumdiscountvoucher >= 75" value="75">75</option>
                  <option v-if="this.maximumdiscountvoucher >= 100" value="100">100</option>
                  <option v-if="this.maximumdiscountvoucher >= 125" value="125">125</option>
                  <option v-if="this.maximumdiscountvoucher >= 150" value="150">150</option>
                  <option v-if="this.maximumdiscountvoucher >= 175" value="175">175</option>
                  <option v-if="this.maximumdiscountvoucher >= 200" value="200">200</option>
                  <option v-if="this.maximumdiscountvoucher >= 225" value="225">225</option>
                  <option v-if="this.maximumdiscountvoucher >= 250" value="250">250</option>
                  <option v-if="this.maximumdiscountvoucher >= 275" value="275">275</option>
                  <option v-if="this.maximumdiscountvoucher >= 300" value="300">300</option>
                  <option v-if="this.maximumdiscountvoucher >= 325" value="325">325</option>
                  <option v-if="this.maximumdiscountvoucher >= 350" value="350">350</option>
                  <option v-if="this.maximumdiscountvoucher >= 375" value="375">375</option>
                  <option v-if="this.maximumdiscountvoucher >= 400" value="400">400</option>
                  <option v-if="this.maximumdiscountvoucher >= 425" value="425">425</option>
                  <option v-if="this.maximumdiscountvoucher >= 450" value="450">450</option>
                  <option v-if="this.maximumdiscountvoucher >= 475" value="475">475</option>
                  <option v-if="this.maximumdiscountvoucher >= 500" value="500">500</option>
                  <option v-if="this.maximumdiscountvoucher >= 525" value="525">525</option>
                  <option v-if="this.maximumdiscountvoucher >= 550" value="550">550</option>
                  <option v-if="this.maximumdiscountvoucher >= 575" value="575">575</option>
                  <option v-if="this.maximumdiscountvoucher >= 600" value="600">600</option>
                </select>
              </div>

            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label for="name" class="form-label">Moneda</label>
                  <select
                    v-if="this.currencies == 'mxus' || this.currencies == ''"
                    name="currency"
                    id="currency"
                    required
                    @change="onChange"
                    class="form-control"
                    :value="client.currency"
                    v-model="client.currency"
                  >
                    <option :value="null">Seleccione una opcion</option>
                    <option :value="'usd'">USD</option>
                    <option :value="'mxn'">MXN</option>
                  </select>
                <select
                    v-if="this.currencies == 'mx'"
                    name="currency"
                    id="currency"
                    required
                    @change="onChange"
                    class="form-control"
                    :value="client.currency"
                    v-model="client.currency"
                  >
                    <option selected="true" :value="'mxn'">MXN</option>
                  </select>
                  <select
                    v-if="this.currencies == 'us'"
                    name="currency"
                    id="currency"
                    required
                    @change="onChange"
                    class="form-control"
                    :value="client.currency"
                    v-model="client.currency"
                  >
                    <option selected="true" :value="'usd'">USD</option>
                  </select>
              </div>
              <div class="form-group col-md-3">
                <label v-if="this.mostrarMeses == true" for="name" class="form-label">Cobro mensual</label>
                <input
                    v-if="this.mostrarMeses == true"
                    name="price"
                    id="price"
                    type="string"
                    placeholder="$1"
                    autocomplete="off"
                    required
                    data-msg="Please enter phone_number"
                    class="form-control"
                    v-model="client.price"
                  />

                <label v-if="this.mostrarAnios == true" for="name" class="form-label">Cobro Anual</label>
                <select v-if="this.mostrarAnios == true" name="price" id="price" class="form-control"
                    v-model="client.price">
                  <option v-if="this.mostrarPreciosUSD" value="750">750 USD</option>
                  <option v-if="this.mostrarPreciosUSD" value="1000">1000 USD</option>
                  <option v-if="this.mostrarPreciosUSD" value="1499">1499 USD</option>

                  <option v-if="this.mostrarPreciosMXN" value="15000">15000 MXN</option>
                  <option v-if="this.mostrarPreciosMXN" value="20000">20000 MXN</option>
                  <option v-if="this.mostrarPreciosMXN" value="30000">30000 MXN</option>

                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="inscription" class="form-label">Incripción</label>
                <input
                  v-if="this.registrationapplies == 1 || this.registrationapplies == ''"
                  name="incription"
                  id="inscription"
                  type="string"
                  autocomplete="off"
                  class="form-control"
                  v-model="client.inscription"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="contract_duration" class="form-label">Duración de contrato</label>
                <select
                    v-if="this.maximumexpirationdate == ''"
                    name="contract_duration"
                    id="contract_duration"
                    required
                    @change="onChange"
                    class="form-control"
                    :value="client.contract_duration"
                    v-model="client.contract_duration"
                  >
                    <option :value="1">1 año</option>
                    <option :value="2">2 años</option>
                    <option :value="3">3 años</option>
                    <option :value="4">4 años</option>
                    <option :value="5">5 años</option>
                    <option :value="6">6 años</option>
                  </select>
                  <select
                    v-if="this.maximumexpirationdate != ''"
                    name="contract_duration"
                    id="contract_duration"
                    required
                    @change="onChange"
                    class="form-control"
                    v-model="client.contract_duration"
                  >
                    <option v-if="this.maximumexpirationdate >= 1" value="1">1 año</option>
                    <option v-if="this.maximumexpirationdate >= 2" value="2">2 años</option>
                    <option v-if="this.maximumexpirationdate >= 3" value="3">3 años</option>
                    <option v-if="this.maximumexpirationdate >= 4" value="4">4 años</option>
                    <option v-if="this.maximumexpirationdate >= 5" value="5">5 años</option>
                    <option v-if="this.maximumexpirationdate >= 6" value="6">6 años</option>
                  </select>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
    
    <div v-if="this.mostrarInputs == false" class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body pt-0 pb-0 row" style="margin-top: 20px;">
            <br>
            <div class="form-group col">
                  <label for="last_name" class="form-label">Tiempo de prueba</label>
                  <select
                    v-if="this.freetime == ''"
                    name="international_code"
                    id="international_code"
                    required
                    class="form-control"
                    v-model="client.free_trial_months"
                  >
                    <option value="null">Seleccione una opcion</option>
                    <option value="1">1 Mes</option>
                    <option value="2">2 Mes</option>
                    <option value="3">3 Mes</option>
                    <option value="4">4 Mes</option>
                    <option value="5">5 Mes</option>
                    <option value="6">6 Mes</option>
                    <option value="7">7 Mes</option>
                    <option value="8">8 Mes</option>
                  </select>

                  <select
                    v-if="this.freetime != ''"
                    name="international_code"
                    id="international_code"
                    required
                    class="form-control"
                    v-model="client.free_trial_months"
                  >
                    <option value="null">Seleccione una opcion</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 1" value="1">1 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 2" value="2">2 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 3" value="3">3 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 4" value="4">4 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 5" value="5">5 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 6" value="6">6 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 7" value="7">7 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 8" value="8">8 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 9" value="9">9 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 10" value="10">10 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 11" value="11">11 Mes</option>
                    <option v-if="this.mostrarPruebas && this.freetime >= 12" value="12">12 Mes</option>
                  </select>
            </div>
            <div class="form-group col">
              <label for="last_name" class="form-label"># Travel Vouchers</label>
              <select
                v-if="this.totalvoucherpersale == ''"
                name="international_lang"
                id="international_lang"
                required
                class="form-control"
                v-model="client.numTravelVoucher"
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <select
                v-if="this.totalvoucherpersale != ''"
                name="international_lang"
                id="international_lang"
                required
                class="form-control"
                v-model="client.numTravelVoucher"
              >
                <option v-if="this.totalvoucherpersale >= 0" value="0">0</option>
                <option v-if="this.totalvoucherpersale >= 1" value="1">1</option>
                <option v-if="this.totalvoucherpersale >= 2" value="2">2</option>
                <option v-if="this.totalvoucherpersale >= 3" value="3">3</option>
                <option v-if="this.totalvoucherpersale >= 4" value="4">4</option>
                <option v-if="this.totalvoucherpersale >= 5" value="5">5</option>
                <option v-if="this.totalvoucherpersale >= 6" value="6">6</option>
                <option v-if="this.totalvoucherpersale >= 7" value="7">7</option>
                <option v-if="this.totalvoucherpersale >= 8" value="8">8</option>
                <option v-if="this.totalvoucherpersale >= 9" value="9">9</option>
                <option v-if="this.totalvoucherpersale >= 10" value="10">10</option>
                <option v-if="this.totalvoucherpersale >= 11" value="11">11</option>
                <option v-if="this.totalvoucherpersale >= 12" value="12">12</option>
                <option v-if="this.totalvoucherpersale >= 13" value="13">13</option>
                <option v-if="this.totalvoucherpersale >= 14" value="14">14</option>
                <option v-if="this.totalvoucherpersale >= 15" value="15">15</option>
                <option v-if="this.totalvoucherpersale >= 16" value="16">16</option>
                <option v-if="this.totalvoucherpersale >= 17" value="17">17</option>
                <option v-if="this.totalvoucherpersale >= 18" value="18">18</option>
                <option v-if="this.totalvoucherpersale >= 19" value="19">19</option>
                <option v-if="this.totalvoucherpersale >= 20" value="20">20</option>
                <option v-if="this.totalvoucherpersale >= 21" value="21">21</option>
                <option v-if="this.totalvoucherpersale >= 22" value="22">22</option>
              </select>
            </div>
            <div class="form-group col">
              <label for="last_name" class="form-label">Descuento Vouchers</label>
              <input v-if="this.maximumdiscountvoucher == ''" class="form-control" type="text" v-model="client.descTravelVoucher"/>
              <select
                v-if="this.maximumdiscountvoucher != ''"
                name="descTravelVoucher"
                id="descTravelVoucher"
                required
                class="form-control"
                v-model="client.descTravelVoucher"
              >
                <option v-if="this.maximumdiscountvoucher >= 25" value="25">25</option>
                <option v-if="this.maximumdiscountvoucher >= 50" value="50">50</option>
                <option v-if="this.maximumdiscountvoucher >= 75" value="75">75</option>
                <option v-if="this.maximumdiscountvoucher >= 100" value="100">100</option>
                <option v-if="this.maximumdiscountvoucher >= 125" value="125">125</option>
                <option v-if="this.maximumdiscountvoucher >= 150" value="150">150</option>
                <option v-if="this.maximumdiscountvoucher >= 175" value="175">175</option>
                <option v-if="this.maximumdiscountvoucher >= 200" value="200">200</option>
                <option v-if="this.maximumdiscountvoucher >= 225" value="225">225</option>
                <option v-if="this.maximumdiscountvoucher >= 250" value="250">250</option>
                <option v-if="this.maximumdiscountvoucher >= 275" value="275">275</option>
                <option v-if="this.maximumdiscountvoucher >= 300" value="300">300</option>
                <option v-if="this.maximumdiscountvoucher >= 325" value="325">325</option>
                <option v-if="this.maximumdiscountvoucher >= 350" value="350">350</option>
                <option v-if="this.maximumdiscountvoucher >= 375" value="375">375</option>
                <option v-if="this.maximumdiscountvoucher >= 400" value="400">400</option>
                <option v-if="this.maximumdiscountvoucher >= 425" value="425">425</option>
                <option v-if="this.maximumdiscountvoucher >= 450" value="450">450</option>
                <option v-if="this.maximumdiscountvoucher >= 475" value="475">475</option>
                <option v-if="this.maximumdiscountvoucher >= 500" value="500">500</option>
                <option v-if="this.maximumdiscountvoucher >= 525" value="525">525</option>
                <option v-if="this.maximumdiscountvoucher >= 550" value="550">550</option>
                <option v-if="this.maximumdiscountvoucher >= 575" value="575">575</option>
                <option v-if="this.maximumdiscountvoucher >= 600" value="600">600</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.client.currency == 'mxn' && this.client.price != null && this.validCorreo || this.mostrarOpen == true">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body pt-0 pb-0">
          <div class="mb-4">
            <h2 class="text-primary font-weight-bold">Formato de cobro</h2>
          </div>
          <card-component #default="{holderName, cardNumber, cvv2, expirationYear, expirationMonth, states, handlers, validators, monthOptions, yearOptions}"
            :years="years"
            :now="now"
            >
            <img width="150px;" src="/img/cards/logo.png" />
            <img width="150px;" src="/img/cards/tarjetas1.png" />
            <div class="alert alert-danger" v-if="inscription_error">
              {{ inscription_error }}
            </div>
            <form :action="`/paymentStripeMembership`" method="POST" id="payment-form" ref="paymentForm">
              <csrf-token></csrf-token>
              <input type="hidden" name="type_payment" id="type_payment" :value="'openPay'">
              <input type="hidden" name="token_id" id="token_id" :value="token">
              <input type="hidden" name="device_data_id" :value="deviceDataId">
              <input type="hidden" name="id_client" :value="client.id">
              <input type="hidden" name="type" :value="type">
              <input type="hidden" name="payment_id" :value="client.payment_id">
              <input type="hidden" name="total" :value="client.price" />
              <input type="hidden" name="currency" :value="client.currency" />
              <input type="hidden" name="name" :value="client.name" />
              <input type="hidden" name="last_name" :value="client.last_name" />
              <input type="hidden" name="email" :value="client.email" />
              <input type="hidden" name="phone_number" :value="client.phone_number" />
              <input type="hidden" name="membership_type" :value="client.membership_type" />
              <input type="hidden" name="international_code" :value="client.international_code" />

              <input type="hidden" name="related_person_name" :value="client.related_person_name" />
              <input type="hidden" name="related_person_last_name" :value="client.related_person_last_name" />
              <input type="hidden" name="free_trial_months" :value="client.free_trial_months" />
              <input type="hidden" name="address" :value="client.address" />
              <input type="hidden" name="country" :value="client.country" />
              <input type="hidden" name="city" :value="client.city" />
              <input type="hidden" name="state" :value="client.state" />
              <input type="hidden" name="zip_code" :value="client.zip_code" />
              <input type="hidden" name="lang" :value="client.lang" />
              <input type="hidden" name="membership_type_pay" :value="client.membership_type_pay" />
              <input type="hidden" name="inscription" :value="client.inscription" />
              <input type="hidden" name="cellphone_1" :value="client.cellphone_1" />
              <input type="hidden" name="cellphone_2" :value="client.cellphone_2" />
              <input type="hidden" name="mail_2" :value="client.mail_2" />
              <input type="hidden" name="contract_duration" :value="client.contract_duration" />

              <input type="hidden" name="numTravelVoucher" :value="client.numTravelVoucher" />
              <input type="hidden" name="descTravelVoucher" :value="client.descTravelVoucher" />

            <div class="row" id="addNewCard">
              <div class="form-group col-md-6">
                <label for="holder_name" class="form-label">Nombre del titular</label>
                <input type="text" name="holder_name" id="holder_name"
                    class="form-control" data-openpay-card="holder_name">
              </div>
              <div class="form-group col-md-6">
                <label for="card_number" class="form-label">Numero de tarjeta</label>
                <input maxlength="16" type="text" name="card_number" placeholder="XXXXXXXXXXXXXXXX" id="card_number"
                  class="form-control" @input="handlers.cardNumber" @blur="validators.cardNumber" :value="cardNumber"
                data-openpay-card="card_number">
                <div v-if="!states.cardNumber" class="text-danger">
                  Invalid card number
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="expiration_month" class="form-label">Mes de expiracion</label>
                    <select :value="expirationMonth" @change="handlers.expirationMonth" @blur="validators.expirationDate" name="expiration_month" class="form-control"
                      :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_month"
                    >
                      <option v-for="option in monthOptions" v-bind:value="option.value" :key="`monty_${option.value}`">
                          {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="expiration_year" class="form-label">Año de expiracion</label>
                    <select :value="expirationYear" @change="handlers.expirationYear" @blur="validators.expirationDate" name="expiration_year" class="form-control"
                        :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_year"
                    >
                      <option v-for="option in yearOptions" v-bind:value="option.value" :key="`year_${option.value}`">
                          {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="cvv2" class="form-label">CVC/CVV</label>
                <input maxlength="3" type="text" name="cvv2" placeholder="123" id="cvv2" class="form-control"
                  @input="handlers.cvv2" @blur="validators.cvv2" :value="cvv2"
                  :class="{'is-invalid': !states.cvv2}"
                  data-openpay-card="cvv2"
                >
                <div v-if="!states.cvv2" class="text-danger">
                  CVV Incorrect
                </div>
              </div>
            </div>
            </form>
          </card-component>
          <button :disabled="loader" type="submit" class="btn btn-primary" v-if="this.client.currency === 'mxn'" @click.prevent="makeToken"><i v-if="loader" class="fas fa-spin fa-spinner"></i>Pagar</button>
          </div>
        </div>
      </div>
    </div> 

    <div class="row" v-if="this.client.currency == 'usd' && this.client.price != null && this.validCorreo || this.mostrarstripe == true">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body pt-0 pb-0">
          <div class="mb-4">
              <h2 class="text-primary font-weight-bold">Formato de cobro</h2>
            </div>
            <form :action="`/paymentStripeMembership`" method="POST" id="payment-form" ref="paymentForm">
              <csrf-token></csrf-token>
              <input type="hidden" name="payment_method_id" :value="payment_method_id">
              <input type="hidden" name="type_payment" id="type_payment" :value="'stripe'">
              <input type="hidden" name="payment_id" :value="client.payment_id">
              <input type="hidden" name="total" :value="client.price" />
              <input type="hidden" name="currency" :value="client.currency" />
              <input type="hidden" name="name" :value="client.name" />
              <input type="hidden" name="last_name" :value="client.last_name" />

              <input type="hidden" name="type" :value="type">
              <input type="hidden" name="id_client" :value="client.id">

              <input type="hidden" name="related_person_name" :value="client.related_person_name" />
              <input type="hidden" name="related_person_last_name" :value="client.related_person_last_name" />
              <input type="hidden" name="free_trial_months" :value="client.free_trial_months" />
              <input type="hidden" name="address" :value="client.address" />
              <input type="hidden" name="country" :value="client.country" />
              <input type="hidden" name="city" :value="client.city" />
              <input type="hidden" name="state" :value="client.state" />
              <input type="hidden" name="zip_code" :value="client.zip_code" />
              <input type="hidden" name="lang" :value="client.lang" />


              <input type="hidden" name="email" :value="client.email" />
              <input type="hidden" name="phone_number" :value="client.phone_number" />
              <input type="hidden" name="membership_type" :value="client.membership_type" />
              <input type="hidden" name="international_code" :value="client.international_code" />
              <input type="hidden" name="membership_type_pay" :value="client.membership_type_pay" />
              <input type="hidden" name="inscription" :value="client.inscription" />
              <input type="hidden" name="cellphone_1" :value="client.cellphone_1" />
              <input type="hidden" name="cellphone_2" :value="client.cellphone_2" />
              <input type="hidden" name="mail_2" :value="client.mail_2" />
              <input type="hidden" name="contract_duration" :value="client.contract_duration" />

              <input type="hidden" name="numTravelVoucher" :value="client.numTravelVoucher" />
              <input type="hidden" name="descTravelVoucher" :value="client.descTravelVoucher" />

              <div class="container"> 
                  <div class="col-md-12">
                    <stripe-checkout 
                      :total="this.client.membership_type_pay == 'M' ? client.inscription : client.price" 
                      :currency="client.currency"
                      @payment-successfully="paymentSuccessfully"
                      @create-payment-method-successfully="createPaymentMethodSuccessfully"
                      :subscribible="this.client.membership_type_pay == 'M'"
                      :type="this.client.membership_type_pay == 'M' && client.inscription == 0 ? 'createMethod' : 'payment'"
                    ></stripe-checkout>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serverData from './../mixins/serverData';

export default {
  mixins: [serverData],
  props: ['membershipTypes', 'membershipTypeSelected', 'codes', 'years', 'now', 'sandbox', 'openid', 'publicopen', 'countries','totalvoucherpersale','maximumdiscountvoucher','maximumexpirationdate','typeofcharge','freetime','currencies','registrationapplies','cliente'],
  data() {
    return {
    client: {
        id:'',
        name: '',
        last_name: '',
        related_person_name: '',
        related_person_last_name: '',
        email: '',
        phone_number: '',
        free_trial_months:null,
        address: '',
        zip_code: '',
        deviceDataId: '',
        numTravelVoucher:0,
        descTravelVoucher:0,
        passport: '',
        lang:'es',
        password_confirmation: '',
        membership_type: null,
        membership_type_pay:null,
        country: null,
        city: null,
        state: null,
        international_code: null,
        currency:null,
        price:null,
        payment_id:null,
        inscription: 0,
        cellphone_1: '',
        cellphone_2: '',
        mail_2: '',
        contract_duration: 1
      },
      type:'',
      payment_method_id: null,
      mostrarInputs:false,
      mostrarOpen:false,
      mostrarstripe:false,
      inscription_error:null,
      token:null,
      validMail:null,
      validCorreo:false,
      loader: false,
      mostrarPruebas:false,
      mostrarAnios:false,
      mostrarMeses:false,
    }
  },
  computed: {
    countryOptions() {
      return [{text: 'Elija un pais', value: null}].concat(this.countries.map(country => {
        return {
          text: country.country_name.toUpperCase(),
          value: country.id
        }
      }));
    },
    countrySelected() {
      if (this.client.country === null) return null;

      return this.countries.find(c => c.id == this.client.country);
    },
    cityOptions() {
      let options = [{text: 'Elija una ciudad', value: null}];
      if (this.countrySelected !== null) {
        options = options.concat(this.countrySelected.cities.map(city => {
          return {
            text: city.city_name.toUpperCase(),
            value: city.id
          }
        }));
      }

      return options;
    },
    stateOptions() {
      let options = [];
      if (this.countrySelected !== null) {
        options = options.concat(this.countrySelected.states.map(state => {
          return {
            text: state.name.toUpperCase(),
            value: state.id
          }
        }));

        if (options.length > 0) {
          options = [{text: 'Elija un estado', value: null}].concat(options);
        }
      }

      return options;
    },
  },
  methods: {
    validateOldData() {
      if (this.hasOld('name')) {
        this.client.name = this.old('name');
      }
      if (this.hasOld('last_name')) {
        this.client.last_name = this.old('last_name');
      }
      if (this.hasOld('related_person_name')) {
        this.client.related_person_name = this.old('related_person_name');
      }
      if (this.hasOld('related_person_last_name')) {
        this.client.related_person_last_name = this.old('related_person_last_name');
      }
      if (this.hasOld('email')) {
        this.client.email = this.old('email');
      }
      if (this.hasOld('international_code')) {
        this.client.international_code = this.old('international_code');
      }
      if (this.hasOld('phone_number')) {
        this.client.phone_number = this.old('phone_number');
      }
      if (this.hasOld('type_payment')) {
        this.client.type_payment = this.old('type_payment');
      }
    },
    edit(item) {
      this.editable = {...item};
      this.editing = item.id;
    },
    makeToken: function (stop = false) {
      this.inscription_error = null;
      this.loader = true;
      const success = (response) => {
          this.token = response.data.id;
          this.$nextTick(() => {
              this.$refs.paymentForm.submit();
          });
      }
      const err = (err) => {
          console.log('error ', err);
          this.loader = false;
      }
      const createSecondToken = async (response) => {
        let self = this;
        try {
          await axios.post(`/incriptions/${self.client.membership_type}/payment`, {
            token_id: response.data.id,
            device_data_id: self.deviceDataId,
            total: this.client.inscription,
            name: this.client.name,
            last_name: this.client.last_name,
            phone_number: this.client.phone_number,
            email: this.client.email
          }).then(function (response) {
            self.client.payment_id = response.data.data;
          });
        } catch (error) {
          this.loader = false;
          if (error.response) {
            self.inscription_error = error.response.data.message;
          }  else {
            self.inscription_error = __('*We could not make the payment, try again.');
          }
          return;
        }
        // Nos aseguramos que hayan pasado al menos 2 segundos antes de generar el siguiente token
        setTimeout(() => {
          // this.token_subscription = response.data.id;
          OpenPay.token.extractFormAndCreate('payment-form', success, err);
        }, 2000); 
      }

      const total = parseFloat(this.client.inscription);

      OpenPay.token.extractFormAndCreate('payment-form', 
        (! isNaN(total) && total > 0) 
          ? createSecondToken 
          : success, 
        err
      );
    },
    cancelEdition() {
      this.editable = null;
      this.editing = null;
    },
    showInModal(item) {
      this.itemModal = item;
      this.$refs['modal-send'].show();
    },
    closeModal() {
      this.$refs['modal-send'].hide();
      window.location.reload();
    },
    createPaymentMethodSuccessfully(paymentMethod) {
      this.payment_method_id = paymentMethod.id;
      this.$nextTick(() => {
        this.$refs['paymentForm'].submit();
      });
    },
    paymentSuccessfully(paymentResult) {
      this.client.payment_id = paymentResult.id;
      this.$nextTick(() => {
        this.$refs.paymentForm.submit();
      })
    },
    onChange() {
      if(this.client.membership_type_pay == "M"){
        this.mostrarAnios = false; 
        this.mostrarMeses = true;
        this.mostrarPruebas = true;
        for (var i = 0; i < this.membershipTypes.length; i++) {
          if(this.membershipTypes[i].id == this.client.membership_type){
            if(this.client.currency == "usd"){
              this.client.price = parseFloat(this.membershipTypes[i].stripe_price);
            }else{
              this.client.price = parseFloat(this.membershipTypes[i].price);
            } 
          }
        }
      }else{
        this.mostrarAnios = true;
        this.mostrarMeses = false;
        this.mostrarPruebas = false;
      }
      
      if(this.client.currency == 'usd'){
        this.mostrarPreciosUSD = true;
        this.mostrarPreciosMXN = false;
      }else{
        this.mostrarPreciosUSD = false;
        this.mostrarPreciosMXN = true;
      }

    },
    async onChangeMail(){
      this.loader = true;
      this.validMail = null;
      this.validCorreo = false;
      var email = this.client.email;
      const { data } = await axios.get('/getEmailInfo', {
        params: {
          email
        }
      });
      this.validMail = data;
      if(data == "Correo Valido"){
        this.validCorreo = true;
      }
      this.loader = false;
    },
  },
  mounted() {
    OpenPay.setId(this.openid);
    OpenPay.setApiKey(this.publicopen);
    OpenPay.setSandboxMode(this.sandbox);
    this.deviceDataId = OpenPay.deviceData.setup();
    this.validateOldData();
    if(this.cliente.id){
        this.client.membership_type_pay = 'M';
        this.client.inscription = 0;
        this.loader = false;
        this.type = 'renovacion';
        this.mostrarInputs = false;
        this.client.id = this.cliente.id;

        if(this.cliente.integracion == 'openpay'){
          this.client.currency = 'mxn';
          this.mostrarOpen = true;
        }else{
          this.client.currency = 'usd';
          this.mostrarstripe = true;
          this.client.price = 0;
        }
    }else{
      this.mostrarInputs = true;
      this.type = 'nuevo';
    }
  }
}
</script>