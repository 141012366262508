<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card border-0 shadow-sm py-2">
                    <div class="row pt-0 pb-0" style="margin-left: 10px;">
                        <div class="col-12" v-if="viewGroup">
                            <h3 class="font-weight-bold  pt-0 pb-0" style="color: #025680;">
                                <span v-if="langselect == 'en'">
                                    New contract
                                </span>
                                <span v-else>
                                    Nuevo socio
                                </span>
                                <span v-show="client.resort">:
                                    {{client.resort}}
                                </span>
                            </h3><br>
                        </div>
                        <div class="col-4" v-if="!client.resort && !this.client.resort2">
                            <h5 class="font-weight-bold" style="color: #025680;">Resort</h5>
                            <select @change="changeResor()" name="resort" class="form-control" v-model="client.resort2">
                                <option v-if="usertype == 'Vacancy Elite'" value="">Select Option</option>
                                <option v-else value="">Seleccione Opcion</option>
                                <option v-for="(resort, index) in this.resorts" :value="resort.name">
                                    {{ resort.name }}
                                </option>
                            </select>
                        </div>
                        <div v-if="viewGroup && usertype == 'all'" class="col-4">
                            <h5 class="font-weight-bold" style="color: #025680;" v-if="langselect == 'en'">Group</h5>
                            <h5 class="font-weight-bold" style="color: #025680;" v-else>Grupo</h5>
                            <select @change="changeClub()" v-model="client.group" name="group" id="" class="form-control">
                                <option>Select option</option>
                                <option v-for="(gruposSel, index) in this.gruposSelect" :value="gruposSel">{{gruposSel}}</option>
                            </select>
                        </div>
                        <div v-if="viewGroup && usertype != 'all'" class="col-4">
                            <h5 class="font-weight-bold" style="color: #025680;" v-if="langselect == 'en'">Group</h5>
                            <h5 class="font-weight-bold" style="color: #025680;" v-else>Grupo</h5>
                            <select @change="changeClub()" v-model="client.group" name="group" id="" class="form-control">
                                <option>Select option</option>
                                <option v-for="(gruposSel, index) in this.gruposSelect" :value="gruposSel">{{gruposSel}}</option>
                            </select>
                        </div>
                    </div><br>
                    <div v-if="client.group && client.resort2 && client.resort2 != 'Vacancy Elite' && client.resort2 != 'The Club At Casa Linda' && client.resort != 'Vacancy Rewards Costa Rica' && client.resort != 'Bajio' && client.resort != 'Mundo Viajero' && client.resort != 'CR Costa Rica' && client.group != 'Referidos' && client.group != 'Socios Monterrey' && client.resort != 'Kovay Vacations'">
                          <new-master-detail-membership 
                              :codes="codes"
                              :years="years"
                              :now="now"
                              sandbox="sandbox"
                              openid="openid"
                              publicopen="publicopen"
                              :countries="countries"
                              totalvoucherpersale="totalvoucherpersale"
                              maximumdiscountvoucher="maximumdiscountvoucher"
                              maximumexpirationdate="maximumexpirationdate"
                              typeofcharge="typeofcharge"
                              freetime="freetime"
                              currencies="currencies"
                              registrationapplies="registrationapplies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership>

                    </div>
                    <div v-if="client.group &&  client.resort2 && client.resort == 'Kovay Vacations'">
                        <new-master-detail-membership-kovay-api 
                              :codes="codesapi"
                              :years="years"
                              :now="now"
                              :resorts="resorts"
                              resort="96213bc2-b460-414e-980a-d0bd99d0869c"
                              :countriesgen="countriesgen"
                              :user="user"
                              :lang="this.lang?this.lang:'en'"
                              :periodicidad="this.periodicidad"
                              :tipomembresias="this.tipomembresias"
                              :status="this.status"
                              :idiomas="this.idiomas"
                              :estadosciviles="this.estadosciviles"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              :moneda="this.moneda"
                              >
                            </new-master-detail-membership-kovay-api>
                    </div>
                    <div v-if="client.group &&  client.resort2 && client.group == 'Socios Monterrey'">
                         <new-master-detail-membership-monterrey 
                              :codes="codes"
                              :countries="countries"
                              typeofcharge="typeofcharge"
                              currencies="currencies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-monterrey>
                    </div>
                    <div v-if="client.group &&  client.resort2 && client.resort == 'Mundo Viajero'">
                          <new-master-detail-membership-mundo 
                              :codes="codes"
                              :years="years"
                              :now="now"
                              sandbox="sandbox"
                              openid="openid"
                              publicopen="publicopen"
                              :countries="countries"
                              totalvoucherpersale="totalvoucherpersale"
                              maximumdiscountvoucher="maximumdiscountvoucher"
                              maximumexpirationdate="maximumexpirationdate"
                              typeofcharge="typeofcharge"
                              freetime="freetime"
                              currencies="currencies"
                              registrationapplies="registrationapplies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-mundo>
                    </div>
                    <div v-if="client.group &&  client.resort2 && client.resort2 == 'Vacancy Elite'">
                          <new-master-detail-membership-elite 
                              :codes="codes"
                              :countries="countries"
                              typeofcharge="typeofcharge"
                              currencies="currencies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-elite>
                    </div>
                    <div v-if="client.group &&  client.resort2 && client.group == 'Referidos'">
                          <new-master-detail-membership-referidos 
                              :codes="codes"
                              :countries="countries"
                              typeofcharge="typeofcharge"
                              currencies="currencies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-referidos>
                    </div>
                    <div v-if="client.group &&  client.resort2 && client.resort2 == 'CR Costa Rica'">
                          <new-master-detail-membership-cr 
                              :codes="codes"
                              :years="years"
                              :now="now"
                              sandbox="sandbox"
                              openid="openid"
                              publicopen="publicopen"
                              :countries="countries"
                              totalvoucherpersale="totalvoucherpersale"
                              maximumdiscountvoucher="maximumdiscountvoucher"
                              maximumexpirationdate="maximumexpirationdate"
                              typeofcharge="typeofcharge"
                              freetime="freetime"
                              currencies="currencies"
                              registrationapplies="registrationapplies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-cr>
                    </div>
                    <div v-if="client.group && client.resort2 && client.resort2 == 'The Club At Casa Linda'">
                          <new-master-detail-membership-casa 
                            :codes="codes"
                              :countries="countries"
                              typeofcharge="typeofcharge"
                              currencies="currencies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-casa>
                    </div>
                    <div v-if="client.group && client.resort2 && client.resort == 'Vacancy Rewards Costa Rica'">
                          <new-master-detail-membership-new 
                              :codes="codes"
                              :years="years"
                              :now="now"
                              sandbox="sandbox"
                              openid="openid"
                              publicopen="publicopen"
                              :countries="countries"
                              totalvoucherpersale="totalvoucherpersale"
                              maximumdiscountvoucher="maximumdiscountvoucher"
                              maximumexpirationdate="maximumexpirationdate"
                              typeofcharge="typeofcharge"
                              freetime="freetime"
                              currencies="currencies"
                              registrationapplies="registrationapplies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-new>
                    </div>
                    <div v-if="client.group && client.resort2 && client.resort == 'Bajio'">
                          <new-master-detail-membership-new 
                              :codes="codes"
                              :years="years"
                              :now="now"
                              sandbox="sandbox"
                              openid="openid"
                              publicopen="publicopen"
                              :countries="countries"
                              totalvoucherpersale="totalvoucherpersale"
                              maximumdiscountvoucher="maximumdiscountvoucher"
                              maximumexpirationdate="maximumexpirationdate"
                              typeofcharge="typeofcharge"
                              freetime="freetime"
                              currencies="currencies"
                              registrationapplies="registrationapplies"
                              :cliente="cliente"
                              chanel="chanel"
                              usertype="usertype"
                              :resorts="resorts"
                              :countriesgen="countriesgen"
                              :user="user"
                              :grupos="grupos"
                              :grupselected="client.group"
                              :lang="this.langselect?this.langselect:'es'"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              >
                            </new-master-detail-membership-new>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import serverData from './../mixins/serverData';

export default {
    mixins: [serverData],
    props: ['codes', 'countries', 'typeofcharge', 'currencies', 'cliente', 'chanel', 'usertype', 'resorts','countriesgen','user','grupos','lang','parentesco','states','codesapi','years','now','periodicidad','tipomembresias','status','idiomas','estadosciviles','parentesco','moneda'],
    data() {
        return {
            client: {
                group:'',
                resort: '',
                resort2: ''
            },
            langselect:'',
            viewGroup:false,
            loader: false,
            gruposSelect:[]
        }
    },
    computed: {
    },
    methods: {
        changeResor(){
            this.gruposSelect = [];
            for (var i = 0; i < this.resorts.length; i++){
                if(this.resorts[i].name == this.client.resort2){
                    for(var ig = 0; ig < this.grupos.length; ig++){
                        if(this.grupos[ig].club == this.resorts[i].id){
                            this.gruposSelect.push(this.grupos[ig].name);
                        }
                    
                    }
                    this.viewGroup = true;
                }
            }
        },
        changeClub(){

            if(this.client.group == ""){

            }else{
                this.client.resort = this.client.group;
                this.viewGroup = false;
                for(var ig = 0; ig < this.grupos.length; ig++){
                    if(this.grupos[ig].name == this.client.group){
                        this.mydeals = this.grupos[ig].mydeals;
                    }
                
                }
            }
        }
    },
    mounted() {
        for(var i = 0; i < this.resorts.length; i++) {
            if(this.resorts[i].name == this.user.club){
                for(var ig = 0; ig < this.grupos.length; ig++){
                    if(this.grupos[ig].club == this.resorts[i].id){
                        this.gruposSelect.push(this.grupos[ig].name);
                    }
                }
                this.viewGroup = true;
            }
        }

        if(this.user.club != 'all'){
            this.client.resort2 = this.user.club;
        }

        if(this.user.group != 'all'){
            for(var ig = 0; ig < this.grupos.length; ig++){
                if(this.grupos[ig].id == this.user.group){
                    this.client.group = this.user.group;
                    this.client.resort = this.grupos[ig].name;
                    this.mydeals = this.grupos[ig].mydeals;
                    this.viewGroup = false;
                    break;
                }
            }
        }
        this.langselect = this.lang;
    }
}

</script>
