<template>
  <div class="d-flex text-left" @mouseenter="onEnter" @mouseleave="onLeave">
    <div class="input-group input-group-lg">
      <div class="input-group-prepend">
        <span class="input-group-text border-0">USD</span>
      </div>
      <input type="text" v-model="value_" class="form-control text-center bg-light" />
      <div class="input-group-append">
        <button type="button" v-if="edited" class="btn btn-outline-primary" @click="$emit('input', value_)" :disabled="loading">
          <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fas fa-save" v-else></i>
        </button>
        <button type="button" v-if="edited" class="btn btn-outline-danger" @click="value_ = value">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number | Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      value_: null,
      hover: false,
    }
  },
  computed: {
    edited() {
      return this.value !== this.value_;
    }
  },
  mounted() {
    this.value_ = this.value;
  },
  methods: {
    onEnter() {
      this.hover = true;
      this.$emit('hovered');
    },
    onLeave() {
      this.hover = false;
      this.$emit('unhovered');
    }
  },
  watch: {
    value(newValue) {
      this.value_ = newValue;
    }
  }
}
</script>

<style scoped>
  .form-control:focus {
    box-shadow: none;
  }
</style>
