<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card border-0 shadow-sm py-2">
                    <form :action="`/new-membership-year-save`" method="POST" id="payment-form" ref="paymentForm">
                        <csrf-token></csrf-token>
                        <input type="hidden" name="monto1" id="monto1" :value="client.monto1">
                        <input type="hidden" name="type1" id="type1" :value="client.type1">
                        <input type="hidden" name="comments1" id="comments1" :value="client.comments1">
                        <input type="hidden" name="monto2" id="monto2" :value="client.monto2">
                        <input type="hidden" name="type2" id="type2" :value="client.type2">
                        <input type="hidden" name="comments2" id="comments2" :value="client.comments2">
                        <input type="hidden" name="monto3" id="monto3" :value="client.monto3">
                        <input type="hidden" name="type3" id="type3" :value="client.type3">
                        <input type="hidden" name="comments3" id="comments3" :value="client.comments3">
                        <input type="hidden" name="monto4" id="monto4" :value="client.monto4">
                        <input type="hidden" name="type4" id="type4" :value="client.type4">
                        <input type="hidden" name="comments4" id="comments4" :value="client.comments4">
                        <input type="hidden" name="monto5" id="monto5" :value="client.monto5">
                        <input type="hidden" name="type5" id="type5" :value="client.type5">
                        <input type="hidden" name="comments5" id="comments5" :value="client.comments5">
                        <input type="hidden" name="number_contract" id="number_contract" :value="client.number_contract">
                        <input type="hidden" name="fechacompra" id="fechacompra" :value="client.fechac">
                        <input type="hidden" name="fecharenovacion" id="fecharenovacion" :value="client.fecharenovacion">
                        <input type="hidden" name="idioma" id="idioma" :value="client.lang">
                        <input type="hidden" name="estadocivilsoltero" id="estadocivilsoltero" :value="client.estadocivilsoltero">
                        <input type="hidden" name="estadocivilcasado" id="estadocivilcasado" :value="client.estadocivilcasado">
                        <input type="hidden" name="estadocivilconcubinato" id="estadocivilconcubinato" :value="client.estadocivilconcubinato">
                        <input type="hidden" name="nametitular" id="nametitular" :value="client.name">
                        <input type="hidden" name="apellidotitular" id="apellidotitular" :value="client.apellidotitular">
                        <input type="hidden" name="email" id="email" :value="client.email1">
                        <input type="hidden" name="namecotitular" id="namecotitular" :value="client.namecotitular">
                        <input type="hidden" name="apellidocotitular" id="apellidocotitular" :value="client.apellidocotitular">
                        <input type="hidden" name="email2" id="email2" :value="client.email2">
                        <input type="hidden" name="numerocasa" id="numerocasa" :value="client.numerocasa">
                        <input type="hidden" name="telefonotitular" id="telefonotitular" :value="client.telefonotitular">
                        <input type="hidden" name="telefonocotitular" id="telefonocotitular" :value="client.telefonocotitular">
                        <input type="hidden" name="country" id="country" :value="client.country">
                        <input type="hidden" name="state" id="state" :value="client.state">
                        <input type="hidden" name="city" id="city" :value="client.city">
                        <input type="hidden" name="direccion" id="direccion" :value="client.direccion">
                        <input type="hidden" name="cp" id="cp" :value="client.cp">
                        <input type="hidden" name="precio" id="precio" :value="client.precio">
                        <input type="hidden" name="moneda" id="moneda" :value="client.moneda">
                        <input type="hidden" name="vipclubweeks" id="vipclubweeks" :value="client.vipclubweeks">
                        <input type="hidden" name="smartweeks" id="smartweeks" :value="client.smartweeks">
                        <input type="hidden" name="hotelescheck" id="hotelescheck" :value="client.hotelescheck">
                        <input type="hidden" name="tourscheck" id="tourscheck" :value="client.tourscheck">
                        <input type="hidden" name="condoscheck" id="condoscheck" :value="client.condoscheck">
                        <input type="hidden" name="vueloscheck" id="vueloscheck" :value="client.vueloscheck">
                        <input type="hidden" name="cruceroscheck" id="cruceroscheck" :value="client.cruceroscheck">
                        <input type="hidden" name="autoscheck" id="autoscheck" :value="client.autoscheck">
                        <input type="hidden" name="trasladoscheck" id="trasladoscheck" :value="client.trasladoscheck">
                        <input type="hidden" name="yatescheck" id="yatescheck" :value="client.yatescheck">
                        <input type="hidden" name="descTravelVoucher" id="descTravelVoucher" :value="client.descTravelVoucher">
                        <input type="hidden" name="intervalinternacional" id="intervalinternacional" :value="client.intervalinternacional">
                        <input type="hidden" name="certificadoautos" id="certificadoautos" :value="client.certificadoautos">
                        <input type="hidden" name="recompensayates" id="recompensayates" :value="client.recompensayates">
                        <input type="hidden" name="recompensacondominios" id="recompensacondominios" :value="client.recompensacondominios">
                        <input type="hidden" name="agente" id="agente" :value="client.agente">
                        <input type="hidden" name="international_code" id="international_code" :value="client.international_code">
                        <input type="hidden" name="numTravelVoucher" id="numTravelVoucher" :value="client.numTravelVoucher">
                        <input type="hidden" name="chanelId" id="chanelId" :value="this.chanel">
                        <input type="hidden" name="totalintervalinternacional" id="totalintervalinternacional" :value="client.totalintervalinternacional">
                        <input type="hidden" name="certificadoautosval" id="certificadoautosval" :value="client.certificadoautosval">
                        <input type="hidden" name="recompensayatesval" id="recompensayatesval" :value="client.recompensayatesval">
                        <input type="hidden" name="status" id="status" :value="client.status">
                        <input type="hidden" name="lastminuteweeks" id="lastminuteweeks" :value="client.lastminuteweeks">
                        <input type="hidden" name="rentaautocheck" id="rentaautocheck" :value="client.rentaautocheck">
                        <input type="hidden" name="dealscheck" id="dealscheck" :value="client.dealscheck">
                        <input type="hidden" name="international_code_cel_titular" id="international_code_cel_titular" :value="client.international_code_cel_titular">
                        <input type="hidden" name="international_code_cel_cotitular" id="international_code_cel_cotitular" :value="client.international_code_cel_cotitular">
                        <input type="hidden" name="price" id="price" :value="client.price">
                        <input type="hidden" name="payinitpercentaje" id="payinitpercentaje" :value="client.payinitpercentaje">
                        <input type="hidden" name="payinit" id="payinit" :value="client.payinit">
                        <input type="hidden" name="adp1" id="adp1" :value="client.adp1">
                        <input type="hidden" name="dateadp1" id="dateadp1" :value="client.dateadp1">
                        <input type="hidden" name="adp2" id="adp2" :value="client.adp2">
                        <input type="hidden" name="dateadp2" id="dateadp2" :value="client.dateadp2">
                        <input type="hidden" name="adp3" id="adp3" :value="client.adp3">
                        <input type="hidden" name="dateadp3" id="dateadp3" :value="client.dateadp3">
                        <input type="hidden" name="tarifacierre" id="tarifacierre" :value="client.tarifacierre">
                        <input type="hidden" name="status" id="status" :value="client.status">
                        <input type="hidden" name="financiamiento" id="financiamiento" :value="client.financiamiento">
                        <input type="hidden" name="numbermonth" id="numbermonth" :value="client.numbermonth">
                        <input type="hidden" name="montomensualidad" id="montomensualidad" :value="client.montomensualidad">
                        <input type="hidden" name="currency" id="currency" :value="client.currency">
                        <input type="hidden" name="intereses" id="intereses" :value="client.intereses">
                        <input type="hidden" name="factoria" id="factoria" :value="client.factoria">
                        <input type="hidden" name="primermensualidad" id="primermensualidad" :value="client.primermensualidad">
                        <input type="hidden" name="nacionalidadtitular" id="nacionalidadtitular" :value="client.nacionalidadtitular">
                        <input type="hidden" name="nacionalidadcotitular" id="nacionalidadcotitular" :value="client.nacionalidadcotitular">
                        <input type="hidden" name="typemembership" id="typemembership" :value="client.typemembership">
                        <input type="hidden" name="anios" id="anios" :value="client.anios">
                        <input type="hidden" name="resort" id="resort" :value="client.resort">
                        <input type="hidden" name="group" id="group" :value="client.group">
                        <input type="hidden" name="typecontract" id="typecontract" :value="client.typecontract">
                        <input type="hidden" name="allinclusive" id="allinclusive" :value="client.allinclusive">
                        <input type="hidden" name="idtitular" id="idtitular" :value="client.idtitular">
                        <input type="hidden" name="idcotitular" id="idcotitular" :value="client.idcotitular">
                        <input type="hidden" name="beneficiario1" id="beneficiario1" :value="client.beneficiario1">
                        <input type="hidden" name="parentesco1" id="parentesco1" :value="client.parentesco1">
                        <input type="hidden" name="beneficiario2" id="beneficiario2" :value="client.beneficiario2">
                        <input type="hidden" name="parentesco2" id="parentesco2" :value="client.parentesco2">
                        <input type="hidden" name="beneficiario3" id="beneficiario3" :value="client.beneficiario3">
                        <input type="hidden" name="parentesco3" id="parentesco3" :value="client.parentesco3">
                        <input type="hidden" name="beneficiario4" id="beneficiario4" :value="client.beneficiario4">
                        <input type="hidden" name="parentesco4" id="parentesco4" :value="client.parentesco4">
                        <input type="hidden" name="beneficiario5" id="beneficiario5" :value="client.beneficiario5">
                        <input type="hidden" name="parentesco5" id="parentesco5" :value="client.parentesco5">
                        <input type="hidden" name="beneficiario6" id="beneficiario6" :value="client.beneficiario6">
                        <input type="hidden" name="parentesco6" id="parentesco6" :value="client.parentesco6">
                        <input type="hidden" name="beneficiario7" id="beneficiario7" :value="client.beneficiario7">
                        <input type="hidden" name="parentesco7" id="parentesco7" :value="client.parentesco7">
                        <input type="hidden" name="beneficiario8" id="beneficiario8" :value="client.beneficiario8">
                        <input type="hidden" name="parentesco8" id="parentesco8" :value="client.parentesco8">
                        <input type="hidden" name="beneficiario9" id="beneficiario9" :value="client.beneficiario9">
                        <input type="hidden" name="parentesco9" id="parentesco9" :value="client.parentesco9">
                        <input type="hidden" name="beneficiario10" id="beneficiario10" :value="client.beneficiario10">
                        <input type="hidden" name="parentesco10" id="parentesco10" :value="client.parentesco10">
                        <input type="hidden" name="noches" id="noches" :value="client.noches">
                        <input type="hidden" name="dias" id="dias" :value="client.dias">
                        <input type="hidden" name="personas" id="personas" :value="client.personas">
                        <input type="hidden" name="number_contract_refer" id="number_contract_refer" :value="client.number_contract_refer">
                        <input type="hidden" name="loginID_refer" id="loginID_refer" :value="client.loginID_refer">
                        <input type="hidden" name="name_refer" id="name_refer" :value="client.name_refer">
                        <input type="hidden" name="viewGroup" id="viewGroup" :value="viewGroup">
                        <input type="hidden" name="birthdate" id="birthdate" :value="client.birthdate">
                        <input type="hidden" name="birthdatecotitular" id="birthdatecotitular" :value="client.birthdatecotitular">
                        <input type="hidden" name="groupSelect" id="groupSelect" :value="client.resort">
                    </form>
                    <div class="row pt-0 pb-0" style="margin-left: 10px;">
                        <div class="col-12">
                            <h3 class="font-weight-bold  pt-0 pb-0" style="color: #025680;">
                                <span v-if="lang == 'en'">
                                    New contract
                                </span>
                                <span v-else>
                                    Nuevo socio
                                </span>
                                <span v-show="client.resort">:
                                    {{client.resort}}
                                </span>
                            </h3><br>
                        </div>
                        <div class="col-4" v-if="!client.resort && !this.client.resort2">
                            <h5 class="font-weight-bold" style="color: #025680;">Resort</h5>
                            <select @change="changeResor()" name="resort" class="form-control" v-model="client.resort2">
                                <option v-if="lang == 'en'" value="">Select Option</option>
                                <option v-else value="">Seleccione Opcion</option>
                                <option v-for="(resort, index) in this.resorts" :key="index" :value="resort.name">
                                    {{ resort.name }}
                                </option>
                            </select>
                        </div>
                        <div v-if="viewGroup && usertype == 'all'" class="col-4">
                            <h5 class="font-weight-bold" style="color: #025680;">Grupo</h5>
                            <select @change="changeClub()" v-model="client.group" name="group" id="" class="form-control">
                                <option>Select option</option>
                                <option v-for="(gruposSel, index) in this.gruposSelect" :value="gruposSel">{{gruposSel}}</option>
                            </select>
                        </div>
                        <div v-if="viewGroup && usertype != 'all'" class="col-4">
                            <h5 class="font-weight-bold" style="color: #025680;">Grupo</h5>
                            <select @change="changeClub()" v-model="client.group" name="group" id="" class="form-control">
                                <option>Select option</option>
                                <option v-for="(gruposSel, index) in this.gruposSelect" :value="gruposSel">{{gruposSel}}</option>
                            </select>
                        </div>
                    </div><br>
                    <div v-show="client.resort" class="card-body pt-0 pb-0">
                        <div class="row">
                            <div class="col" v-if="client.resort != 'VR La isla' && client.resort != 'Referidos'">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Contract number</label>
                                    <label for="name" class="form-label" v-else>Numero de contrato</label>
                                    <input v-if="client.resort != 'VR La isla' && client.resort != 'Referidos'" name="numerodecontrato" type="string" autocomplete="off" required data-msg="Please enter your name" class="form-control" v-model="client.number_contract" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Date of purchase</label>
                                    <label for="name" class="form-label" v-else>Fecha de compra</label>
                                    <input name="fechadecompra" @change="onChangeDate()" type="date" autocomplete="off" required data-msg="Please enter your name" class="form-control" v-model="client.fechac" />
                                </div>
                            </div>
                            <div class="col-1">
                                <label for="name" class="form-label" v-if="lang == 'en'">Years</label>
                                <label for="name" class="form-label" v-else>Años</label>
                                <select name="anios" @change="onChangeDate()" class="form-control" v-model="client.anios">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option v-if="client.resort != 'Peruvian Travel Club'" value="lifetime">Lifetime</option>
                                </select>
                            </div>
                            <div class="col">
                                <div class="form-group" v-if="client.resort != 'Peruvian Travel Club'">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Membership Type</label>
                                    <label for="name" class="form-label" v-else>Tipo de membresia</label>
                                    <select class="form-control" v-model="client.typemembership" name="typemembership" required>
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="Exit">Exit</option>
                                        <option value="Regular">Regular</option>
                                    </select>
                                </div>
                                <div class="form-group" v-if="client.resort == 'Peruvian Travel Club'">
                                    <label for="name" class="form-label">Tipo de membresia</label>
                                    <select class="form-control" v-model="client.typemembership" name="typemembership" required>
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="Silver">Silver</option>
                                        <option value="Gold">Gold</option>
                                        <option value="Black">Black</option>
                                        <option value="Premium">Premium</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Language</label>
                                    <label for="name" class="form-label" v-else>Idioma</label>
                                    <select v-if="lang == 'en'" class="form-control" v-model="client.lang" name="idioma" required>
                                        <option value="">Select Option</option>
                                        <option value="en">English</option>
                                        <option value="es">Spanish</option>
                                    </select>
                                    <select v-else class="form-control" v-model="client.lang" name="idioma" required>
                                        <option value="">Seleccione Opcion</option>
                                        <option value="en">Ingles</option>
                                        <option value="es">Español</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col" v-if="usertype != 'Vacancy Elite'">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Type of contract</label>
                                    <label for="name" class="form-label" v-else>Tipo de contrato</label>
                                    <select class="form-control" v-model="client.typecontract" name="tipocontrato" required>
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="Nacional">Nacional</option>
                                        <option value="Extranjero">Extranjero</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label v-if="lang == 'en'" for="name" class="form-label">Status</label>
                                    <label v-else for="name" class="form-label">Estatus</label>
                                    <select v-if="lang == 'en'" class="form-control" v-model="client.status" name="idioma" required>
                                        <option value="">Select Option</option>
                                        <option value="Pendiente">Pending</option>
                                        <option value="Procesable">Processable</option>
                                    </select>
                                    <select v-else class="form-control" v-model="client.status" name="idioma" required>
                                        <option value="">Seleccione Opcion</option>
                                        <option value="Pendiente">Pendiente</option>
                                        <option value="Procesable">Procesable</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h3 class="font-weight-bold" v-if="lang == 'en'" style="color: #025680;">Partner Information</h3>
                        <h3 class="font-weight-bold" v-else style="color: #025680;">Informacion del socio</h3>
                        <div class="row">
                            <div class="col-6">
                                <span v-if="lang == 'en'"><input @change="selectStateC('soltero')" type="checkbox" v-model="client.estadocivilsoltero" /> Single</span>
                                <span v-else><input @change="selectStateC('soltero')" type="checkbox" v-model="client.estadocivilsoltero" /> Soltero</span>
                                <span v-if="lang == 'en'" style="margin-left: 10px;"><input @change="selectStateC('casado')" v-model="client.estadocivilcasado" type="checkbox" /> Married</span>
                                <span v-else style="margin-left: 10px;"><input @change="selectStateC('casado')" v-model="client.estadocivilcasado" type="checkbox" /> Casado</span>
                                <span v-if="lang == 'en'" style="margin-left: 10px;"><input @change="selectStateC('concubinato')" type="checkbox" v-model="client.estadocivilconcubinato" /> Free Union</span>
                                <span v-else style="margin-left: 10px;"><input @change="selectStateC('concubinato')" type="checkbox" v-model="client.estadocivilconcubinato" /> Union libre</span>
                            </div>
                            <div class="col-2">
                            </div>
                            <div class="col-4">
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Holder name</label>
                                            <label for="name" class="form-label" v-else>Nombre titular</label>
                                            <input type="string" autocomplete="off" :onkeyup="capitalizarPrimeraLetra(client.name,'name')" required class="form-control" v-model="client.name" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Owner last name</label>
                                            <label for="name" class="form-label" v-else>Apellido titular</label>
                                            <input name="apellidotitular" type="string" :onkeyup="capitalizarPrimeraLetra(client.apellidotitular,'apellido')" autocomplete="off" required class="form-control" v-model="client.apellidotitular" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Date of Birth</label>
                                            <label for="name" class="form-label" v-else>Fecha de Nacimiento(titular)<span style="color:red">*</span></label>
                                            <input name="birthdate" type="date" autocomplete="off" required class="form-control" v-model="client.birthdate" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Name (co-owner)</label>
                                            <label for="name" class="form-label" v-else>Nombre(co-titular)</label>
                                            <input name="namecotitular" :onkeyup="capitalizarPrimeraLetra(client.namecotitular,'nameco')" type="string" autocomplete="off" required class="form-control" v-model="client.namecotitular" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" v-if="lang == 'en'" class="form-label">Surname(co-owner)</label>
                                            <label for="name" v-else class="form-label">Apellido(co-titular)</label>
                                            <input :onkeyup="capitalizarPrimeraLetra(client.apellidocotitular,'apellidoco')" name="apellidocotitular" type="string" autocomplete="off" required class="form-control" v-model="client.apellidocotitular" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Date of Birth</label>
                                            <label for="name" class="form-label" v-else>Fecha de Nacimiento(Co-titular)<span style="color:red">*</span></label>
                                            <input name="birthdatecotitular" type="date" autocomplete="off" required class="form-control" v-model="client.birthdatecotitular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" v-if="lang == 'en'" class="form-label">
                                        Holder nationality
                                    </label>
                                    <label for="name" v-else class="form-label">Nacionalidad titular</label>
                                    <select name="nacionalidad" class="form-control" v-model="client.nacionalidadtitular">
                                        <option v-for="(option, index) in countryOptions" :key="option.value+index" :value="option.text">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Email #1</label>
                                    <input name="email1" type="string" placeholder="Email" autocomplete="off" required class="form-control" v-model="client.email1" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" v-if="lang == 'en'" class="form-label">
                                        Co-owner nationality
                                    </label>
                                    <label for="name" v-else class="form-label">Nacionalidad cotitula</label>
                                    <select name="nacionalidadco" class="form-control" v-model="client.nacionalidadcotitular">
                                        <option v-for="(option, index) in countryOptions" :key="option.value + index" :value="option.text">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Email #2
                                        <span v-if="lang == 'en'">(Co-owner)(Optional)</span>
                                        <span v-else>(Co-titular)(Opcional)</span>
                                    </label>
                                    <input name="email2" type="string" placeholder="Email 2" autocomplete="off" required class="form-control" v-model="client.email2" />
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="client.resort == 'Peruvian Travel Club'">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">ID
                                        <span v-if="lang == 'en'">(Owner)</span>
                                        <span v-else>(Titular)</span>
                                    </label>
                                    <input name="idtitular" type="string" autocomplete="off" required class="form-control" v-model="client.idtitular" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">ID
                                        <span v-if="lang == 'en'">(Co Owner)</span>
                                        <span v-else>(Co Titular)</span>
                                    </label>
                                    <input name="idcotitular" type="string" autocomplete="off" required class="form-control" v-model="client.idcotitular" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="last_name" class="form-label" v-if="lang == 'en'">Code</label>
                                            <label for="last_name" class="form-label" v-else>Codigo</label>
                                            <select name="international_code" id="international_code" required class="form-control" v-model="client.international_code">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                                <option v-else value="">Seleccione Opcion</option>
                                                <option :value="'+52'">MEXICO +52</option>
                                                <option :value="'+1'">UNITED STATES +1</option>
                                                <option :value="'+1'">CANADA +1</option>
                                                <option :value="'+57'">COLOMBIA +57</option>
                                                <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <label for="name" class="form-label" v-if="lang == 'en'">House number</label>
                                            <label for="name" class="form-label" v-else>Numero de casa</label>
                                            <input name="numerocasa" type="string" autocomplete="off" required class="form-control" v-model="client.numerocasa" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-5">
                                        <label for="last_name" class="form-label" v-if="lang == 'en'">Code</label>
                                        <label for="last_name" class="form-label" v-else>Codigo</label>
                                        <select name="international_code" id="international_code" required class="form-control" v-model="client.international_code_cel_titular">
                                            <option v-if="lang == 'en'" value="">Select Option</option>
                                            <option v-else value="">Seleccione Opcion</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Telephone (owner)</label>
                                            <label for="name" class="form-label" v-else>Telefono(titular)</label>
                                            <input name="telefonotitular" type="string" autocomplete="off" required class="form-control" v-model="client.telefonotitular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-5">
                                        <label for="last_name" class="form-label" v-if="lang == 'en'">Code</label>
                                        <label for="last_name" class="form-label" v-else>Codigo</label>
                                        <select name="international_code" id="international_code" required class="form-control" v-model="client.international_code_cel_cotitular">
                                            <option v-if="lang == 'en'" value="">Select Option</option>
                                            <option v-else value="">Seleccione Opcion</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Telephone (co-owner)</label>
                                            <label for="name" class="form-label" v-else>Telefono(co-titular)</label>
                                            <input name="telefonocotitular" type="string" autocomplete="off" required class="form-control" v-model="client.telefonocotitular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="pais" class="form-label" v-if="lang == 'en'">Country</label>
                                    <label for="pais" class="form-label" v-else>Pais</label>
                                    <select name="pais" class="form-control" v-model="client.country">
                                        <option v-for="(option, index) in countryOptions" :key="option.value + index" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="estado" class="form-label" v-if="lang == 'en'">State</label>
                                    <label for="estado" class="form-label" v-else>Estado</label>
                                    <select class="form-control" id="state" name="state" aria-label="state" v-model="client.state" :disabled="this.disabledState">
                                        <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">City</label>
                                    <label for="name" class="form-label" v-else>Ciudad</label>
                                    <input name="ciudad" id="name" :onkeyup="capitalizarPrimeraLetra(client.city,'city')" type="string" autocomplete="off" required class="form-control" v-model="client.city" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Address</label>
                                    <label for="name" class="form-label" v-else>Direccion</label>
                                    <input name="direccion" :onkeyup="capitalizarPrimeraLetra(client.direccion,'direccion')" type="string" autocomplete="off" required class="form-control" v-model="client.direccion" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Postal Code</label>
                                    <label for="name" class="form-label" v-else>Codigo postal</label>
                                    <input name="cp" type="string" autocomplete="off" required class="form-control" v-model="client.cp" />
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h3 class="font-weight-bold" style="color: #025680;" v-if="lang == 'en'">Beneficiaries</h3>
                        <h3 class="font-weight-bold" style="color: #025680;" v-else>Beneficiarios</h3>
                        <div class="row">
                            <div class="col">
                                <label style="font-size: 18px;" v-if="lang == 'en'">Name</label>
                                <label style="font-size: 18px;" v-else>Nombre</label>
                                <input type="text" class="form-control" name="beneficiario1" :onkeyup="capitalizarPrimeraLetra(client.beneficiario1,'beneficiario1')" v-model="client.beneficiario1"><br>
                                <input type="text" class="form-control" name="beneficiario2" :onkeyup="capitalizarPrimeraLetra(client.beneficiario2,'beneficiario2')" v-model="client.beneficiario2"><br>
                                <input type="text" class="form-control" name="beneficiario3" :onkeyup="capitalizarPrimeraLetra(client.beneficiario3,'beneficiario3')" v-model="client.beneficiario3"><br>
                                <input type="text" v-if="client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" class="form-control" name="beneficiario4" v-model="client.beneficiario4" :onkeyup="capitalizarPrimeraLetra(client.beneficiario4,'beneficiario4')"><br>
                                <input type="text" v-if="client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" class="form-control" name="beneficiario5" v-model="client.beneficiario5" :onkeyup="capitalizarPrimeraLetra(client.beneficiario5,'beneficiario5')"><br>
                            </div>
                            <div class="col">
                                <label style="font-size: 18px;" v-if="lang == 'en'">Relationship</label>
                                <label style="font-size: 18px;" v-else>Parentesco</label>
                                <input type="text" class="form-control" name="parentesco1" :onkeyup="capitalizarPrimeraLetra(client.parentesco1,'parentesco1')" v-model="client.parentesco1"><br>
                                <input type="text" class="form-control" name="parentesco2" :onkeyup="capitalizarPrimeraLetra(client.parentesco2,'parentesco2')" v-model="client.parentesco2"><br>
                                <input type="text" class="form-control" name="parentesco3" :onkeyup="capitalizarPrimeraLetra(client.parentesco3,'parentesco3')" v-model="client.parentesco3"><br>
                                <input type="text" v-if="client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" class="form-control" name="parentesco4" v-model="client.parentesco4" :onkeyup="capitalizarPrimeraLetra(client.parentesco4,'parentesco4')"><br>
                                <input type="text" v-if="client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" class="form-control" name="parentesco5" v-model="client.parentesco5" :onkeyup="capitalizarPrimeraLetra(client.parentesco5,'parentesco5')"><br>
                            </div>
                            <hr>
                        </div>
                        <hr>
                        <h3 class="font-weight-bold" v-if="client.resort == 'Referidos'" style="color: #025680;">Referido por:</h3><br>
                        <div class="row" v-if="client.resort == 'Referidos'">
                            <div class="col" v-if="client.resort == 'Referidos'">
                                <div class="form-group">
                                    <label for="name" class="form-label">Contrato</label>
                                    <input name="numerodecontrato" type="string" placeholder="Numero de contrato de quien refiere" autocomplete="off" required data-msg="Please enter your name" class="form-control" v-model="client.number_contract_refer" />
                                </div>
                            </div>
                            <div class="col" v-if="client.resort == 'Referidos'">
                                <div class="form-group">
                                    <label for="name" class="form-label">LoginID</label>
                                    <input name="numerodecontrato" type="string" placeholder="LoginID de quien refiere" autocomplete="off" required data-msg="Please enter your name" class="form-control" v-model="client.loginID_refer" />
                                </div>
                            </div>
                            <div class="col" v-if="client.resort == 'Referidos'">
                                <div class="form-group">
                                    <label for="name" class="form-label">Nombre</label>
                                    <input name="numerodecontrato" type="string" placeholder="Nombre de quien refiere" autocomplete="off" required data-msg="Please enter your name" class="form-control" v-model="client.name_refer" />
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h3 class="font-weight-bold" style="color: #025680;" v-if="lang == 'en'">Membership Benefits</h3>
                        <h3 class="font-weight-bold" style="color: #025680;" v-else>Beneficios de la membresia</h3>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Vip weeks 
                                        <span v-if="lang == 'en'">by year</span>
                                        <span v-else>por año</span></label>
                                    <select name="vipclubweeks" required class="form-control" v-model="client.vipclubweeks">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="4">4</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="5">5</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="6">6</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="7">7</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="8">8</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="9">9</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="10">10</option>
                                        <option v-if="client.resort == 'Socios Monterrey' && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="Ilimitado">Ilimitado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Smart weeks 
                                        <span v-if="lang == 'en'">by year</span>
                                        <span v-else>por año</span></label>
                                    <select name="smartweeks" required class="form-control" v-model="client.smartweeks">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="4">4</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="5">5</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="6">6</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="7">7</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="8">8</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="9">9</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="10">10</option>
                                        <option v-if="client.resort == 'Socios Monterrey'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="Ilimitado">Ilimitado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Last minute weeks 
                                        <span v-if="lang == 'en'">by year</span>
                                        <span v-else>por año</span></label>
                                    <select name="smartweeks" required class="form-control" v-model="client.lastminuteweeks">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="4">4</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="5">5</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="6">6</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="7">7</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="8">8</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="9">9</option>
                                        <option v-if="client.resort != 'Peruvian Travel Club'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="10">10</option>
                                        <option v-if="client.resort == 'Socios Monterrey'  && client.resort != 'Resort Marketing y Consulting S.A. de C.V.'" value="Ilimitado">Ilimitado</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="client.resort != 'Peruvian Travel Club'">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">All</label>
                                    <label for="name" class="form-label" v-else>Todos</label>
                                    <input name="all" id="all" @change="allSelected()" type="checkbox" autocomplete="off" required v-model="all" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Hotels</label>
                                    <label for="name" class="form-label" v-else>Hoteles</label>
                                    <input name="hoteles" id="name" type="checkbox" autocomplete="off" required v-model="client.hotelescheck" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Tours</label>
                                    <input name="tours" type="checkbox" autocomplete="off" required v-model="client.tourscheck" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Condos</label>
                                    <input name="condoscheck" type="checkbox" autocomplete="off" required v-model="client.condoscheck" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Flights</label>
                                    <label for="name" class="form-label" v-else>Vuelos</label>
                                    <input name="vueloscheck" type="checkbox" autocomplete="off" required v-model="client.vueloscheck" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Rent a Car</label>
                                    <label for="name" class="form-label" v-else>Renta Auto</label>
                                    <input name="traslados" type="checkbox" autocomplete="off" required v-model="client.rentaautocheck" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label" v-if="lang == 'en'">Transfer</label>
                                    <label for="name" class="form-label" v-else>Traslados</label>
                                    <input name="traslados" type="checkbox" autocomplete="off" required v-model="client.trasladoscheck" />
                                </div>
                            </div>
                            <div class="col" v-if="mydeals == '1'">
                                <div class="form-group">
                                    <label for="name" class="form-label">My deals</label>
                                    <input name="yates" type="checkbox" autocomplete="off" required v-model="client.dealscheck" />
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h5 class="font-weight-bold" style="color: #025680;" v-if="client.resort != 'Referidos'">
                            <span v-if="lang == 'en'">Incentives</span>
                            <span v-else>Incentivos</span>
                        </h5>
                        <div class="row" v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Referidos'">
                            <div class="col">
                                Travel voucher
                            </div>
                            <div class="col">
                                Interval international
                            </div>
                            <div class="col">
                                <span v-if="lang == 'en'">Car certificate</span>
                                <span v-else>Certificado de auto</span>
                            </div>
                            <div class="col">
                                <span v-if="lang == 'en'">Yacht reward</span>
                                <span v-else>Recompensa en yates</span>
                            </div>
                        </div>
                        <div class="row" v-if="client.resort != 'Peruvian Travel Club' && client.resort != 'Referidos'">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <select name="travelvouchers" required class="form-control" v-model="client.numTravelVoucher">
                                            <option v-if="lang == 'en'" value="">Select Option</option>
                                            <option v-else value="">Seleccione Opcion</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <!--option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option-->
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input name="descTravelVoucher" id="descTravelVoucher" required class="form-control" v-model="client.descTravelVoucher" />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-3">
                                        <input type="checkbox" name="travelvouchers" required class="form-control" v-model="client.totalintervalinternacional" />
                                    </div>
                                    <div class="col">
                                        <input name="intervalinternacional" type="string" autocomplete="off" placeholder="#" required class="form-control" v-model="client.intervalinternacional" />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <select name="certificadoautos" required class="form-control" v-model="client.certificadoautos">
                                            <option v-if="lang == 'en'" value="">Select Option</option>
                                            <option v-else value="">Seleccione Opcion</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input name="certificadoautosval" type="string" autocomplete="off" required class="form-control" v-model="client.certificadoautosval" />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <select name="recompensayates" required class="form-control" v-model="client.recompensayates">
                                            <option v-if="lang == 'en'" value="">Select Option</option>
                                            <option v-else value="">Seleccione Opcion</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input name="recompensayatesval" type="string" autocomplete="off" required class="form-control" v-model="client.recompensayatesval" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="font-weight-bold" v-if="client.resort == 'Peruvian Travel Club' && client.resort != 'Referidos'" style="color: #025680;">Semanas Bono</h5>
                        <div class="row" v-if="client.resort == 'Peruvian Travel Club' && client.resort != 'Referidos'">
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Noches</label>
                                    <select name="vipclubweeks" required class="form-control" v-model="client.noches">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Dias</label>
                                    <select name="vipclubweeks" required class="form-control" v-model="client.dias">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="name" class="form-label">Personas</label>
                                    <select name="vipclubweeks" required class="form-control" v-model="client.personas">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                        <option v-else value="">Seleccione Opcion</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div v-if="client.group != 'Bajio' && client.resort != 'Bajio'">
                            <h5 class="font-weight-bold" v-if="lang == 'en'" style="color: #025680;">Prices and Payments</h5>
                            <h5 class="font-weight-bold" v-else style="color: #025680;">Precios y Pagos</h5>
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" v-if="lang == 'en'" class="form-label">Price (total contract value)</label>
                                            <label for="name" v-else class="form-label">Precio (valor total del contrato)</label>
                                            <input name="precio" type="number" autocomplete="off" required class="form-control" v-model="client.price" />
                                        </div>
                                        <div class="col-4">
                                            <label v-if="lang == 'en'">Currency</label>
                                            <label v-else>Moneda</label>
                                            <select name="recompensayates" required class="form-control" v-model="client.currency">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                                <option value="USD">USD</option>
                                                <option v-if="client.resort != 'Peruvian Travel Club'" value="MXN">MXN</option>
                                                <option v-if="client.resort == 'Peruvian Travel Club'" value="MXN">Soles</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="lang == 'en'" class="col">
                                    <label>Initial payment %</label>
                                    <input @change="onChangePercentaje()" v-model="client.payinitpercentaje" type="number" class="form-control">
                                </div>
                                <div v-if="lang == 'en'" class="col">
                                    <label>Initial payment</label>
                                    <input type="number" v-model="client.payinit" disabled="true" class="form-control">
                                </div>
                                <div v-if="usertype != 'Vacancy Elite'" class="col-3">
                                    <button class="btn btn-primary" @click="addMethodPay()" v-if="lang == 'en'">Add Payment</button>
                                    <button class="btn btn-primary" @click="addMethodPay()" v-else>Agregar Pago</button>
                                </div>
                            </div><br>
                            <div v-if="usertype != 'Vacancy Elite'" v-show="met1" class="row form-group">
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment 1</label>
                                    <label v-else>Pago 1</label>
                                    <input @change="onChangePercentaje()" v-model="client.monto1" required name="monto1" class="form-control" type="number" step=".01">
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment method 1</label>
                                    <label v-else>Metodo de pago 1</label>
                                    <select v-model="client.type1" required class="form-control" name="type1">
                                        <option value="Efectivo">Efectivo</option>
                                        <option value="TPV">TPV</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment type</label>
                                    <label v-else>Tipo de pago</label>
                                    <select name="comments1" id="" class="form-control">
                                        <option value="enganche" selected="true">Enganche</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="usertype != 'Vacancy Elite'" v-show="met2" class="row form-group">
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment 2</label>
                                    <label v-else>Pago 2</label>
                                    <input @change="onChangePercentaje()" v-model="client.monto2" required name="monto2" class="form-control" type="number" step=".01">
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment method 2</label>
                                    <label v-else>Metodo de pago 2</label>
                                    <select v-model="client.type2" required class="form-control" name="type2">
                                        <option value="Efectivo">Efectivo</option>
                                        <option value="TPV">TPV</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment type</label>
                                    <label v-else>Tipo de pago</label>
                                    <select name="comments2" id="" class="form-control">
                                        <option value="enganche" selected="true">Enganche</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="usertype != 'Vacancy Elite'" v-show="met3" class="row form-group">
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment 3</label>
                                    <label v-else>Pago 3</label>
                                    <input @change="onChangePercentaje()" v-model="client.monto3" required name="monto3" class="form-control" type="number" step=".01">
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment method 3</label>
                                    <label v-else>Metodo de pago 3</label>
                                    <select v-model="client.type3" required class="form-control" name="type3">
                                        <option value="Efectivo">Efectivo</option>
                                        <option value="TPV">TPV</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment type</label>
                                    <label v-else>Tipo de pago</label>
                                    <select name="comments3" id="" class="form-control">
                                        <option value="enganche" selected="true">Enganche</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="usertype != 'Vacancy Elite'" v-show="met4" class="row form-group">
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment 4</label>
                                    <label v-else>Pago 4</label>
                                    <input @change="onChangePercentaje()" v-model="client.monto4" required name="monto4" class="form-control" type="number" step=".01">
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment method 4</label>
                                    <label v-else>Metodo de pago 4</label>
                                    <select v-model="client.type4" required class="form-control" name="type4">
                                        <option value="Efectivo">Efectivo</option>
                                        <option value="TPV">TPV</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment type</label>
                                    <label v-else>Tipo de pago</label>
                                    <select name="comments4" id="" class="form-control">
                                        <option value="enganche" selected="true">Enganche</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="usertype != 'Vacancy Elite'" v-show="met5" class="row form-group">
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment 5</label>
                                    <label v-else>Pago 5</label>
                                    <input @change="onChangePercentaje()" v-model="client.monto5" required name="monto5" class="form-control" type="number" step=".01">
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment method 5</label>
                                    <label v-else>Metodo de pago 5</label>
                                    <select v-model="client.type5" required class="form-control" name="type5">
                                        <option value="Efectivo">Efectivo</option>
                                        <option value="TPV">TPV</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">Payment type</label>
                                    <label v-else>Tipo de pago</label>
                                    <select name="comments5" id="" class="form-control">
                                        <option value="enganche" selected="true">Enganche</option>
                                    </select>
                                </div>
                            </div>
                            <hr v-if="usertype != 'Vacancy Elite'" v-show="met1">
                            <div class="row">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label">ADP1</label>
                                            <input name="adp1" type="number" autocomplete="off" required @change="onChangePercentaje()" class="form-control" v-model="client.adp1" />
                                        </div>
                                        <div class="col-2">
                                            <label v-if="lang == 'en'">Currency</label>
                                            <label v-else>Moneda</label>
                                            <select required disabled="true" class="form-control" v-model="client.currency">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                                <option value="USD">USD</option>
                                                <option v-if="client.resort != 'Peruvian Travel Club'" value="MXN">MXN</option>
                                                <option v-if="client.resort == 'Peruvian Travel Club'" value="MXN">Soles</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <label v-if="lang == 'en'">Payment date</label>
                                            <label v-else>Fecha de Pago</label>
                                            <input name="dateadp1" type="date" autocomplete="off" required class="form-control" v-model="client.dateadp1" />
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label">ADP2</label>
                                            <input name="adp2" type="number" autocomplete="off" required @change="onChangePercentaje()" class="form-control" v-model="client.adp2" />
                                        </div>
                                        <div class="col-2">
                                            <label v-if="lang == 'en'">Currency</label>
                                            <label v-else>Moneda</label>
                                            <select required disabled="true" class="form-control" v-model="client.currency">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                                <option value="USD">USD</option>
                                                <option v-if="client.resort != 'Peruvian Travel Club'" value="MXN">MXN</option>
                                                <option v-if="client.resort == 'Peruvian Travel Club'" value="MXN">Soles</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <label v-if="lang == 'en'">Payment date</label>
                                            <label v-else>Fecha de Pago</label>
                                            <input name="dateadp2" type="date" autocomplete="off" required class="form-control" v-model="client.dateadp2" />
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col">
                                            <label for="name" class="form-label">ADP3</label>
                                            <input name="adp3" type="number" autocomplete="off" required @change="onChangePercentaje()" class="form-control" v-model="client.adp3" />
                                        </div>
                                        <div class="col-2">
                                            <label v-if="lang == 'en'">Currency</label>
                                            <label v-else>Moneda</label>
                                            <select required disabled="true" class="form-control" v-model="client.currency">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                                <option value="USD">USD</option>
                                                <option v-if="client.resort != 'Peruvian Travel Club'" value="MXN">MXN</option>
                                                <option v-if="client.resort == 'Peruvian Travel Club'" value="MXN">Soles</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <label v-if="lang == 'en'">Payment date</label>
                                            <label v-else>Fecha de Pago</label>
                                            <input name="dateadp3" type="date" autocomplete="off" required class="form-control" v-model="client.dateadp3" />
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="name" class="form-label" v-if="lang == 'en'">Closing fee</label>
                                            <label for="name" class="form-label" v-else>Tarifa de cierre</label>
                                            <input name="tarifacierre" type="number" autocomplete="off" required class="form-control" v-model="client.tarifacierre" />
                                        </div>
                                        <div class="col-2">
                                            <label v-if="lang == 'en'">Currency</label>
                                            <label v-else>Moneda</label>
                                            <select required disabled="true" class="form-control" v-model="client.currency">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                                <option value="USD">USD</option>
                                                <option v-if="client.resort != 'Peruvian Travel Club'" value="MXN">MXN</option>
                                                <option v-if="client.resort == 'Peruvian Travel Club'" value="MXN">Soles</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <label v-if="lang == 'en'">Financing</label>
                                            <label v-else>Financiamiento</label>
                                            <input name="financiamiento" type="text" autocomplete="off" required disabled="true" class="form-control" v-model="client.financiamiento" />
                                        </div>
                                        <div class="col-2">
                                            <label v-if="lang == 'en'">Currency</label>
                                            <label v-else>Moneda</label>
                                            <select required disabled="true" class="form-control" v-model="client.currency">
                                                <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                                <option value="USD">USD</option>
                                                <option v-if="client.resort != 'Peruvian Travel Club'" value="MXN">MXN</option>
                                                <option v-if="client.resort == 'Peruvian Travel Club'" value="MXN">Soles</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <br>
                            <h5 class="font-weight-bold" style="color: #025680;" v-if="lang == 'en'">Monthly payments</h5>
                            <h5 class="font-weight-bold" style="color: #025680;" v-else>Mensualidades</h5>
                            <div class="row">
                                <div class="col-2">
                                    <label v-if="lang == 'en'">Number of Months</label>
                                    <label v-else>Numero de meses</label>
                                    <select required @change="onChangeMes()" class="form-control" v-model="client.numbermonth">
                                        <option v-if="lang == 'en'" value="">Select Option</option>
                                    <option v-else value="">Seleccione Opcion</option>
                                        <option v-if="client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort == 'Crab House' || client.resort == 'Socios Monterrey' || client.resort == 'Peruvian Travel Club' || client.resort == 'Grupo Viaggi SAS'" value="6">6</option>
                                        <option v-if="client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort == 'Crab House' || client.resort == 'Socios Monterrey' || client.resort2 == 'Vacancy Elite' || client.resort == 'Grupo Viaggi SAS'" value="12">12</option>
                                        <option v-if="client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR La isla' || client.resort == 'VR Rosarito' || client.resort2 == 'Vacancy Elite' || client.resort == 'Socios Monterrey' || client.resort == 'Grupo Viaggi SAS'" value="24">24</option>
                                        <option v-if="client.resort == 'Vakanza' || client.resort == 'Referidos' || client.resort == 'VR Rosarito' || client.resort == 'Socios Monterrey' || client.resort2 == 'Vacancy Elite' || client.resort == 'Grupo Viaggi SAS'" value="36">36</option>
                                    </select>
                                </div>
                                <div class="col-2">
                                    <label v-if="lang == 'en'">Interests</label>
                                    <label v-else>Intereses %</label>
                                    <input name="montomensualidad" type="string" autocomplete="off" required disabled="true" class="form-control" v-model="client.intereses" />
                                </div>
                                <div class="col">
                                    <label>Factor IA %</label>
                                    <input disabled="true" name="factoria" type="string" autocomplete="off" required class="form-control" v-model="client.factoria" />
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">First Monthly Payment</label>
                                    <label v-else>Primera mensualidad</label>
                                    <input name="primermensualidad" type="date" autocomplete="off" required class="form-control" v-model="client.primermensualidad" />
                                </div>
                                <div class="col">
                                    <label v-if="lang == 'en'">First Monthly Payment</label>
                                    <label v-else>Monthly Amount</label>
                                    <input name="montomensualidad" type="string" autocomplete="off" required disabled="true" class="form-control" v-model="client.montomensualidad" />
                                </div>
                            </div><br>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label v-if="lang == 'en'">Sales Agent</label>
                                    <label for="name" class="form-label" v-else>Agente de ventas</label>
                                    <input name="agente" :onkeyup="capitalizarPrimeraLetra(client.agente,'agente')" type="string" autocomplete="off" required class="form-control" v-model="client.agente" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <br>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-2">
                            <div class="form-group">
                                <a v-show="client.resort" style="background-color: #025680 !important; width: 180px;" :disabled="loader" type="button" class="btn btn-primary" @click.prevent="sendForm">
                                    <i v-if="loader" class="fas fa-spin fa-spinner"></i>
                                    <span v-if="lang == 'en'">Save</span>
                                    <span v-else>Guardar</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import serverData from './../mixins/serverData';

export default {
    mixins: [serverData],
    props: ['codes', 'years', 'now', 'sandbox', 'openid', 'publicopen', 'countries', 'totalvoucherpersale', 'maximumdiscountvoucher', 'maximumexpirationdate', 'typeofcharge', 'freetime', 'currencies', 'registrationapplies', 'cliente', 'chanel', 'usertype', 'resorts','countriesgen','user','grupos','grupselected','lang'],
    data() {
        return {
            client: {
                group:'',
                resort: '',
                resort2: '',
                number_contract: '',
                fechac: '',
                fecharenovacion: '',
                lang: 'es',
                estadocivilsoltero: '',
                estadocivilcasado: '',
                estadocivilconcubinato: '',
                totalintervalinternacional: '',
                certificadoautosval: '',
                recompensayates: '',
                recompensayatesval: '',
                name: '',
                apellidotitular: '',
                email1: '',
                namecotitular: '',
                apellidocotitular: '',
                email2: '',
                numerocasa: '',
                telefonotitular: '',
                typecontract: '',
                telefonocotitular: '',
                country: null,
                state: null,
                city: '',
                direccion: '',
                cp: '',
                status: '',
                precio: null,
                moneda: null,
                vipclubweeks: '',
                lastminuteweeks: '',
                smartweeks: '',
                allinclusive: '',
                hotelescheck: '',
                tourscheck: '',
                condoscheck: '',
                vueloscheck: '',
                cruceroscheck: '',
                trasladoscheck: '',
                rentaautocheck: '',
                typemembership: '',
                dealscheck: '',
                descTravelVoucher: 0,
                intervalinternacional: '',
                certificadoautos: '',
                recompensayates: '',
                recompensacondominios: '',
                agente: '',
                international_code: null,
                international_code_cel_titular: null,
                international_code_cel_cotitular: null,
                numTravelVoucher: 0,
                price: 0,
                payinitpercentaje: 0,
                payinit: 0,
                adp1: 0,
                dateadp1: '',
                adp2: 0,
                dateadp2: '',
                adp3: 0,
                dateadp3: '',
                tarifacierre: 0,
                financiamiento: 0,
                anios: '',
                numbermonth: '',
                montomensualidad: 0,
                currency: '',
                intereses: '',
                factoria: '',
                primermensualidad: '',
                nacionalidadtitular: '',
                nacionalidadcotitular: '',
                idtitular: '',
                idcotitular: '',
                beneficiario1: '',
                parentesco1: '',
                beneficiario2: '',
                parentesco2: '',
                beneficiario3: '',
                parentesco3: '',
                beneficiario4: '',
                parentesco4: '',
                beneficiario5: '',
                parentesco5: '',
                beneficiario6: '',
                parentesco6: '',
                beneficiario7: '',
                parentesco7: '',
                beneficiario8: '',
                parentesco8: '',
                beneficiario9: '',
                parentesco9: '',
                beneficiario10: '',
                parentesco10: '',
                noches: '',
                dias: '',
                personas: '',
                number_contract_refer: '',
                loginID_refer: '',
                name_refer: '',
                monto1: 0,
                type1: '',
                comments1: '',
                monto2: 0,
                type2: '',
                comments2: '',
                monto3: 0,
                type3: '',
                comments3: '',
                monto4: 0,
                type4: '',
                comments4: '',
                monto5: 0,
                type5: '',
                comments5: '',
                birthdate: '',
                birthdatecotitular: ''
            },
            met1: false,
            met2: false,
            met3: false,
            viewGroup:false,
            met4: false,
            met5: false,
            all: false,
            loader: false,
            disabledState: false,
            nume: 0,
            mydeals:0,
            gruposSelect:[]
        }
    },
    computed: {
        countryOptions() {
            if (this.lang == 'es') {
                return [{ text: 'Elija un pais', value: null }].concat(this.countriesgen.map(country => {
                        return {
                            text: country.Nombre.toUpperCase(),
                            value: country.id_pais
                        }
                }));
            } else {
                return [{ text: 'Choose a country', value: null }].concat(this.countriesgen.map(country => {
                        return {
                            text: country.Nombre.toUpperCase(),
                            value: country.id_pais
                        }

                }));
            }
        },
        countrySelected() {
            if (this.client.country === null) return null;
            return this.countriesgen.find(c => c.id_pais == this.client.country);
        },
        stateOptions() {
            let options = [];
            if (this.countrySelected !== null) {
                if (this.countrySelected.states.length == 0) {
                    this.disabledState = true;
                } else {
                    this.disabledState = false;
                }
                options = options.concat(this.countrySelected.states.map(state => {
                    return {
                        text: state.Nombre.toUpperCase(),
                        value: state.Id_estado
                    }
                }));

                if (options.length > 0) {
                    options = [{ text: 'Elija un estado', value: null }].concat(options);
                }
            }

            return options;
        }
    },
    methods: {
        capitalizarPrimeraLetra(palabra, campo) {
            if (!palabra) return;
            var palabraOri = '';
            var mayuscula = "";
            var minuscula = "";
            var palabraConvert = '';

            palabraOri = palabra.split(' ');

            for (var i = 0; i < palabraOri.length; i++) {
                mayuscula = palabraOri[i].substring(0, 1).toUpperCase();
                minuscula = palabraOri[i].substring(1).toLowerCase();
                if(i < 1)
                {
                    palabraConvert += mayuscula.concat(minuscula);
                }else{
                    palabraConvert += ' '+mayuscula.concat(minuscula);
                }
            }

            if (campo == 'name') {
                this.client.name = palabraConvert;
            } else if (campo == 'apellido') {
                this.client.apellidotitular = palabraConvert;
            } else if (campo == 'nameco') {
                this.client.namecotitular = palabraConvert;
            } else if (campo == 'apellidoco') {
                this.client.apellidocotitular = palabraConvert;
            } else if (campo == 'city') {
                this.client.city = palabraConvert;
            } else if (campo == 'direccion') {
                this.client.direccion = palabraConvert;
            } else if (campo == 'beneficiario1') {
                this.client.beneficiario1 = palabraConvert;
            } else if (campo == 'beneficiario2') {
                this.client.beneficiario2 = palabraConvert;
            } else if (campo == 'beneficiario3') {
                this.client.beneficiario3 = palabraConvert;
            } else if (campo == 'beneficiario4') {
                this.client.beneficiario4 = palabraConvert;
            } else if (campo == 'beneficiario5') {
                this.client.beneficiario5 = palabraConvert;
            } else if (campo == 'parentesco1') {
                this.client.parentesco1 = palabraConvert;
            } else if (campo == 'parentesco2') {
                this.client.parentesco2 = palabraConvert;
            } else if (campo == 'parentesco3') {
                this.client.parentesco3 = palabraConvert;
            } else if (campo == 'parentesco4') {
                this.client.parentesco4 = palabraConvert;
            } else if (campo == 'parentesco5') {
                this.client.parentesco5 = palabraConvert;
            } else if (campo == 'agente') {
                this.client.agente = palabraConvert;
            }
        },
        changeResor(){
            this.gruposSelect = [];
            for (var i = 0; i < this.resorts.length; i++){
                if(this.resorts[i].name == this.client.resort2){
                    for(var ig = 0; ig < this.grupos.length; ig++){
                        if(this.grupos[ig].club == this.resorts[i].id){
                            this.gruposSelect.push(this.grupos[ig].name);
                        }
                    
                    }
                    this.viewGroup = true;
                }
            }
        },
        changeClub(){
            if(this.client.group == ""){

            }else{
                this.client.resort = this.client.group;
                for(var ig = 0; ig < this.grupos.length; ig++){
                    if(this.grupos[ig].name == this.client.group){
                        this.mydeals = this.grupos[ig].mydeals;
                    }
                
                }
            }
        },
        addMethodPay() {
            this.nume += 1;

            if (this.nume == 1) {
                this.met1 = true;
            }
            if (this.nume == 2) {
                this.met2 = true;
            }
            if (this.nume == 3) {
                this.met3 = true;
            }
            if (this.nume == 4) {
                this.met4 = true;
            }
            if (this.nume == 5) {
                this.met5 = true;
            }
        },
        onChangeMes() {
             if(this.usertype == 'Vacancy Elite'){
                this.client.payinit = (parseFloat(this.client.price)/100 * this.client.payinitpercentaje).toFixed(2);
            }else{
                this.client.payinit = (parseFloat(this.client.monto1) + parseFloat(this.client.monto2) + parseFloat(this.client.monto3) + parseFloat(this.client.monto4) + parseFloat(this.client.monto5)).toFixed(2);
            }

            this.client.financiamiento = parseFloat(this.client.price - this.client.payinit - this.client.adp1 - this.client.adp2 - this.client.adp3).toFixed(2);

            if (this.client.group == 'VR La Isla') {
                if (this.client.numbermonth == 6) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 12) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 24) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                }
            } else if (this.client.resort2 == 'Vacancy Elite') {
                if (this.client.numbermonth == 6) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 12) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 24) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 36) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;
                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                }
            }else {
                if (this.client.numbermonth == 6) {
                    this.client.intereses = 0;
                    this.client.factoria = 0;

                    this.client.montomensualidad = (this.client.financiamiento / this.client.numbermonth).toFixed(2);
                } else if (this.client.numbermonth == 12) {
                    this.client.intereses = 8;
                    this.client.factoria = 0.0869444;

                    this.mensualidad = (parseFloat(this.client.factoria) * parseFloat(this.client.financiamiento));
                    this.client.montomensualidad = this.mensualidad.toFixed(2);

                } else if (this.client.numbermonth == 24) {
                    this.client.intereses = 12;
                    this.client.factoria = 0.046875;
                    this.mensualidad = (parseFloat(this.client.factoria) * parseFloat(this.client.financiamiento));
                    this.client.montomensualidad = this.mensualidad.toFixed(2);
                } else if (this.client.numbermonth == 36) {
                    this.client.intereses = 14;
                    this.client.factoria = 0.0337731;
                    this.mensualidad = (parseFloat(this.client.factoria) * parseFloat(this.client.financiamiento));
                    this.client.montomensualidad = this.mensualidad.toFixed(2);
                }
            }
        },
        async sendForm(){
               this.loader = true;
            var apellidoti = this.client.apellidotitular;
            var apellidoco = this.client.apellidocotitular;
            var nameti = this.client.name;
            var nameco = this.client.namecotitular;

            const { data } = await axios.post('/api-v1/getCoincidencies', {
                params: {
                    apellidoti,
                    apellidoco,
                    nameti,
                    nameco
                }
            });

            if(!data){
                this.$refs.paymentForm.submit();
            }else{
                this.loader = false;
                Swal.fire({
                  title: 'Existe un Contrato registrado con el mismo Nombre. ¿Está seguro que desea continuar?',
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: 'Continuar',
                  denyButtonText: `Cancelar`,
                }).then((result) => {
                  if(result.isConfirmed) {
                    this.loader = true;
                    this.$refs.paymentForm.submit();
                  }else if (result.isDenied) {
                    Swal.close();
                  }
                })
            }
        },
        onChangeDate() {
            var fecha = new Date(this.client.fechac);
            var dias = 365; // Número de días a agregar
            fecha.setDate(fecha.getDate() + dias);
            this.client.fecharenovacion = fecha.toISOString().split('T')[0];
        },
        onChangePercentaje() {
            if(this.usertype == 'Vacancy Elite'){
                this.client.payinit = (parseFloat(this.client.price)/100 * this.client.payinitpercentaje).toFixed(2);
            }else{
                this.client.payinit = (parseFloat(this.client.monto1) + parseFloat(this.client.monto2) + parseFloat(this.client.monto3) + parseFloat(this.client.monto4) + parseFloat(this.client.monto5)).toFixed(2);
            }
            this.client.financiamiento = parseFloat(this.client.price - this.client.payinit - this.client.adp1 - this.client.adp2 - this.client.adp3).toFixed(2);
            this.onChangeMes();
        },
        selectStateC($stateC) {
            if ($stateC == 'soltero') {
                this.client.estadocivilcasado = false;
                this.client.estadocivilconcubinato = false;
            } else if ($stateC == 'casado') {
                this.client.estadocivilsoltero = false;
                this.client.estadocivilconcubinato = false;
            } else if ($stateC == 'concubinato') {
                this.client.estadocivilsoltero = false;
                this.client.estadocivilcasado = false;
            } else {
                this.client.estadocivilcasado = false;
                this.client.estadocivilconcubinato = false;
                this.client.estadocivilsoltero = false;
            }
        },
        allSelected() {
            if (!this.all) {
                this.client.hotelescheck = false;
                this.client.tourscheck = false;
                this.client.cruceroscheck = false;
                this.client.trasladoscheck = false;
                this.client.rentaautocheck = false;
                this.client.dealscheck = false;
                this.client.condoscheck = false;
                this.client.vueloscheck = false;
            } else {
                this.client.hotelescheck = true;
                this.client.tourscheck = true;
                this.client.cruceroscheck = true;
                this.client.trasladoscheck = true;
                this.client.rentaautocheck = true;
                this.client.dealscheck = true;
                this.client.condoscheck = true;
                this.client.vueloscheck = true;
            }
        },
        validateOldData() {
            this.loader = false;

            if (this.hasOld('idtitular')) {
                this.client.idtitular = this.old('idtitular');
            }
            if (this.hasOld('idcotitular')) {
                this.client.idcotitular = this.old('idcotitular');
            }
            if (this.hasOld('nametitular')) {
                this.client.name = this.old('nametitular');
            }
            if (this.hasOld('idioma')) {
                this.client.lang = this.old('idioma');
            }
            if (this.hasOld('fechacompra')) {
                this.client.fechac = this.old('fechacompra');
            }
            if (this.hasOld('fecharenovacion')) {
                this.client.fecharenovacion = this.old('fecharenovacion');
            }

            if (this.hasOld('estadocivilsoltero')) {
                this.client.estadocivilsoltero = this.old('estadocivilsoltero');
            }
            if (this.hasOld('estadocivilcasado')) {
                this.client.estadocivilcasado = this.old('estadocivilcasado');
            }
            if (this.hasOld('estadocivilconcubinato')) {
                this.client.estadocivilconcubinato = this.old('estadocivilconcubinato');
            }

            if (this.hasOld('apellidotitular')) {
                this.client.apellidotitular = this.old('apellidotitular');
            }
            if (this.hasOld('email')) {
                this.client.email1 = this.old('email');
            }
            if (this.hasOld('namecotitular')) {
                this.client.namecotitular = this.old('namecotitular');
            }
            if (this.hasOld('apellidocotitular')) {
                this.client.apellidocotitular = this.old('apellidocotitular');
            }
            if (this.hasOld('email2')) {
                this.client.email2 = this.old('email2');
            }
            if (this.hasOld('numerocasa')) {
                this.client.numerocasa = this.old('numerocasa');
            }
            if (this.hasOld('telefonotitular')) {
                this.client.telefonotitular = this.old('telefonotitular');
            }
            if (this.hasOld('telefonocotitular')) {
                this.client.telefonocotitular = this.old('telefonocotitular');
            }
            if (this.hasOld('country')) {
                this.client.country = this.old('country');
            }
            if (this.hasOld('state')) {
                this.client.state = this.old('state');
            }
            if (this.hasOld('city')) {
                this.client.city = this.old('city');
            }
            if (this.hasOld('direccion')) {
                this.client.direccion = this.old('direccion');
            }
            if (this.hasOld('cp')) {
                this.client.cp = this.old('cp');
            }
            if (this.hasOld('precio')) {
                this.client.precio = this.old('precio');
            }
            if (this.hasOld('moneda')) {
                this.client.moneda = this.old('moneda');
            }
            if (this.hasOld('vipclubweeks')) {
                this.client.vipclubweeks = this.old('vipclubweeks');
            }
            if (this.hasOld('smartweeks')) {
                this.client.smartweeks = this.old('smartweeks');
            }
            if (this.hasOld('allinclusive')) {
                this.client.allinclusive = this.old('allinclusive');
            }
            if (this.hasOld('hotelescheck')) {
                this.client.hotelescheck = this.old('hotelescheck');
            }
            if (this.hasOld('tourscheck')) {
                this.client.tourscheck = this.old('tourscheck');
            }
            if (this.hasOld('cruceroscheck')) {
                this.client.cruceroscheck = this.old('cruceroscheck');
            }
            if (this.hasOld('autoscheck')) {
                this.client.autoscheck = this.old('autoscheck');
            }
            if (this.hasOld('condoscheck')) {
                this.client.condoscheck = this.old('condoscheck');
            }
            if (this.hasOld('vueloscheck')) {
                this.client.vueloscheck = this.old('vueloscheck');
            }
            if (this.hasOld('trasladoscheck')) {
                this.client.trasladoscheck = this.old('trasladoscheck');
            }
            if (this.hasOld('yatescheck')) {
                this.client.yatescheck = this.old('yatescheck');
            }
            if (this.hasOld('descTravelVoucher')) {
                this.client.descTravelVoucher = this.old('descTravelVoucher');
            }
            if (this.hasOld('intervalinternacional')) {
                this.client.intervalinternacional = this.old('intervalinternacional');
            }
            if (this.hasOld('certificadoautos')) {
                this.client.certificadoautos = this.old('certificadoautos');
            }
            if (this.hasOld('recompensayates')) {
                this.client.recompensayates = this.old('recompensayates');
            }
            if (this.hasOld('recompensacondominios')) {
                this.client.recompensacondominios = this.old('recompensacondominios');
            }
            if (this.hasOld('agente')) {
                this.client.agente = this.old('agente');
            }
            if (this.hasOld('international_code')) {
                this.client.international_code = this.old('international_code');
            }
            if (this.hasOld('numTravelVoucher')) {
                this.client.numTravelVoucher = this.old('numTravelVoucher');
            }
            if (this.hasOld('number_contract')) {
                this.client.number_contract = this.old('number_contract');
            }
            if (this.hasOld('totalintervalinternacional')) {
                this.client.totalintervalinternacional = this.old('totalintervalinternacional');
            }
            if (this.hasOld('certificadoautosval')) {
                this.client.certificadoautosval = this.old('certificadoautosval');
            }
            if (this.hasOld('recompensayatesval')) {
                this.client.recompensayatesval = this.old('recompensayatesval');
            }
            if (this.hasOld('status')) {
                this.client.status = this.old('status');
            }
            if (this.hasOld('lastminuteweeks')) {
                this.client.lastminuteweeks = this.old('lastminuteweeks');
            }
            if (this.hasOld('rentaautocheck')) {
                this.client.rentaautocheck = this.old('rentaautocheck');
            }
            if (this.hasOld('dealscheck')) {
                this.client.dealscheck = this.old('dealscheck');
            }
            if (this.hasOld('international_code_cel_titular')) {
                this.client.international_code_cel_titular = this.old('international_code_cel_titular');
            }
            if (this.hasOld('international_code_cel_cotitular')) {
                this.client.international_code_cel_cotitular = this.old('international_code_cel_cotitular');
            }
            if (this.hasOld('price')) {
                this.client.price = this.old('price');
            }
            if (this.hasOld('payinitpercentaje')) {
                this.client.payinitpercentaje = this.old('payinitpercentaje');
            }
            if (this.hasOld('payinit')) {
                this.client.payinit = this.old('payinit');
            }
            if (this.hasOld('adp1')) {
                this.client.adp1 = this.old('adp1');
            }
            if (this.hasOld('dateadp1')) {
                this.client.dateadp1 = this.old('dateadp1');
            }
            if (this.hasOld('adp2')) {
                this.client.adp2 = this.old('adp2');
            }
            if (this.hasOld('dateadp2')) {
                this.client.dateadp2 = this.old('dateadp2');
            }
            if (this.hasOld('adp3')) {
                this.client.adp3 = this.old('adp3');
            }
            if (this.hasOld('dateadp3')) {
                this.client.dateadp3 = this.old('dateadp3');
            }
            if (this.hasOld('tarifacierre')) {
                this.client.tarifacierre = this.old('tarifacierre');
            }
            if (this.hasOld('financiamiento')) {
                this.client.financiamiento = this.old('financiamiento');
            }
            if (this.hasOld('numbermonth')) {
                this.client.numbermonth = this.old('numbermonth');
            }
            if (this.hasOld('montomensualidad')) {
                this.client.montomensualidad = this.old('montomensualidad');
            }
            if (this.hasOld('currency')) {
                this.client.currency = this.old('currency');
            }
            if (this.hasOld('intereses')) {
                this.client.intereses = this.old('intereses');
            }
            if (this.hasOld('factoria')) {
                this.client.factoria = this.old('factoria');
            }
            if (this.hasOld('primermensualidad')) {
                this.client.primermensualidad = this.old('primermensualidad');
            }
            if (this.hasOld('nacionalidadtitular')) {
                this.client.nacionalidadtitular = this.old('nacionalidadtitular');
            }
            if (this.hasOld('nacionalidadcotitular')) {
                this.client.nacionalidadcotitular = this.old('nacionalidadcotitular');
            }
            if (this.hasOld('anios')) {
                this.client.anios = this.old('anios');
            }
            if (this.hasOld('resort')) {
                this.client.resort = this.old('resort');
            }
            if (this.hasOld('group')) {
                this.client.group = this.old('group');
            }
            if (this.hasOld('typecontract')) {
                this.client.typecontract = this.old('typecontract');
            }
            if (this.hasOld('typemembership')) {
                this.client.typemembership = this.old('typemembership');
            }

            if (this.hasOld('beneficiario1')) {
                this.client.beneficiario1 = this.old('beneficiario1');
            }
            if (this.hasOld('beneficiario2')) {
                this.client.beneficiario2 = this.old('beneficiario2');
            }
            if (this.hasOld('beneficiario3')) {
                this.client.beneficiario3 = this.old('beneficiario3');
            }
            if (this.hasOld('beneficiario4')) {
                this.client.beneficiario4 = this.old('beneficiario4');
            }
            if (this.hasOld('beneficiario5')) {
                this.client.beneficiario5 = this.old('beneficiario5');
            }
            if (this.hasOld('beneficiario6')) {
                this.client.beneficiario6 = this.old('beneficiario6');
            }
            if (this.hasOld('beneficiario7')) {
                this.client.beneficiario7 = this.old('beneficiario7');
            }
            if (this.hasOld('beneficiario8')) {
                this.client.beneficiario8 = this.old('beneficiario8');
            }
            if (this.hasOld('beneficiario9')) {
                this.client.beneficiario9 = this.old('beneficiario9');
            }
            if (this.hasOld('beneficiario10')) {
                this.client.beneficiario10 = this.old('beneficiario10');
            }
            if (this.hasOld('parentesco1')) {
                this.client.parentesco1 = this.old('parentesco1');
            }
            if (this.hasOld('parentesco2')) {
                this.client.parentesco2 = this.old('parentesco2');
            }
            if (this.hasOld('parentesco3')) {
                this.client.parentesco3 = this.old('parentesco3');
            }
            if (this.hasOld('parentesco4')) {
                this.client.parentesco4 = this.old('parentesco4');
            }
            if (this.hasOld('parentesco5')) {
                this.client.parentesco5 = this.old('parentesco5');
            }
            if (this.hasOld('parentesco6')) {
                this.client.parentesco6 = this.old('parentesco6');
            }
            if (this.hasOld('parentesco7')) {
                this.client.parentesco7 = this.old('parentesco7');
            }
            if (this.hasOld('parentesco8')) {
                this.client.parentesco8 = this.old('parentesco8');
            }
            if (this.hasOld('parentesco9')) {
                this.client.parentesco9 = this.old('parentesco9');
            }
            if (this.hasOld('parentesco10')) {
                this.client.parentesco10 = this.old('parentesco10');
            }
            if (this.hasOld('noches')) {
                this.client.noches = this.old('noches');
            }
            if (this.hasOld('dias')) {
                this.client.dias = this.old('dias');
            }
            if (this.hasOld('personas')) {
                this.client.personas = this.old('personas');
            }
            if (this.hasOld('number_contract_refer')) {
                this.client.number_contract_refer = this.old('number_contract_refer');
            }
            if (this.hasOld('loginID_refer')) {
                this.client.loginID_refer = this.old('loginID_refer');
            }
            if (this.hasOld('name_refer')) {
                this.client.name_refer = this.old('name_refer');
            }
            if(this.hasOld('viewGroup')){
                this.viewGroup = this.old('viewGroup');
            }
            if(this.hasOld('birthdate')){
                this.client.birthdate = this.old('birthdate');
            }
            if(this.hasOld('birthdatecotitular')){
                this.client.birthdatecotitular = this.old('birthdatecotitular');
            }
        }
    },
    mounted() {
        if(this.grupselected){
            this.client.resort = this.grupselected;
        }
        this.validateOldData();

        for(var i = 0; i < this.resorts.length; i++) {
            if(this.resorts[i].name == this.client.resort2){
                for(var ig = 0; ig < this.grupos.length; ig++){
                    if(this.grupos[ig].club == this.resorts[i].id){
                        this.gruposSelect.push(this.grupos[ig].name);
                    }
                }
                this.viewGroup = true;
            }
        }

        if(this.user.club != 'all'){
            this.client.resort2 = this.user.club;
        }

        if(this.user.group != 'all'){
            for(var ig = 0; ig < this.grupos.length; ig++){
                if(this.grupos[ig].id == this.user.group){
                    this.client.resort = this.grupos[ig].name;
                    this.mydeals = this.grupos[ig].mydeals;
                    this.viewGroup = false;
                    break;
                }
            }
        }
    }
}

</script>
