<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card border-0 shadow-sm py-2">
                    <div v-if="client.resort">
                           <new-master-detail-membership-kovay-api 
                              :codes="codes"
                              :years="years"
                              :now="now"
                              :resorts="resorts"
                              :resort="client.resort"
                              :countriesgen="countriesgen"
                              :user="user"
                              :lang="this.lang?this.lang:'en'"
                              :periodicidad="this.periodicidad"
                              :tipomembresias="this.tipomembresias"
                              :status="this.status"
                              :idiomas="this.idiomas"
                              :estadosciviles="this.estadosciviles"
                              :parentesco="this.parentesco"
                              :states="this.states"
                              :moneda="this.moneda"
                              >
                            </new-master-detail-membership-kovay-api>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import serverData from './../mixins/serverData';

export default {
    mixins: [serverData],
    props: ['codes', 'years', 'now', 'resorts','countriesgen','user','lang','periodicidad','tipomembresias','status','idiomas','estadosciviles','parentesco','states','moneda'],
    data() {
        return {
            client: {
                resort: '96213bc2-b460-414e-980a-d0bd99d0869c'
            },
            loader: false,
        }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    }
}

</script>
