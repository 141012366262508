<template>
  <div>
    <div class="row" id="membreship">
      <div class="col-12">
        <p class="font-weight-bold">Agregar membresias</p>
        <div class="form-group row">
          <label for="price_per_adult" class="col-md-2 col-form-label text-muted">Membresia</label>
          <div class="input-group col-md-4">
            <select class="form-control" v-model="selected" name="membresia" >
              <option v-for="item in membership_" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary" v-on:click="AddMembreship(selected)"> Agregar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="listmembership.length > 0">
      <div class="col-12">
        <p class="font-weight-bold">Precios con membresia</p>
      </div>
    </div>
    <div class="row mt-4" v-for="(item, index) in listmembership" :key="item.membership_id">
      <div class="col-12">
        <div class="alert alert-info alert-dismissible fade show" role="alert">
          <h5 class="font-weight-bold">{{ index + 1 }}.- {{ findNameMembership(item.membership_id) }}</h5>
          <button type="button" class="close" @click="RemoveMembership(index)">
            <i class="fas fa-trash text-danger"></i>
          </button>
        </div>
      </div>
      <input type="hidden"
      :name="`membership[${index}][currency_code]`"
      :value="defaultCurrency_">
      <input type="hidden"
      :name="`membership[${index}][membership_id]`"
      v-model="listmembership[index].membership_id">

      <div class="col-md-4">
        <div class="form-group">
          <label for="price_per_adult_subscribed" class="col-form-label text-muted">Precio por adulto</label>
          <div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text border-0 bg-gray-200">{{ defaultCurrency_ }}</span>
              </div>
              <!-- :value="item.price_per_adult_subscribed" -->
              <input type="number" step=".01"
              class="form-control border-0 bg-gray-200"
              :name="`membership[${index}][price_per_adult_subscribed]`"

              v-model="listmembership[index].price_per_adult_subscribed"
              id="price_per_adult_subscribed"
              placeholder="Ejem: 100"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="price_per_child_subscribed" class="col-form-label text-muted">Precio por niño</label>
          <div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text border-0 bg-gray-200">{{ defaultCurrency_ }}</span>
              </div>
              <input type="number" step=".01"
              class="form-control border-0 bg-gray-200"
              :name="`membership[${index}][price_per_child_subscribed]`"

              v-model="listmembership[index].price_per_child_subscribed"
              id="price_per_child_subscribed"
              placeholder="Ejem: 100"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="price_per_infant_subscribed" class="col-form-label text-muted">Precio por infante</label>
          <div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text border-0 bg-gray-200">{{ defaultCurrency_ }}</span>
              </div>
              <input type="number" step=".01"
              class="form-control border-0 bg-gray-200"
              :name="`membership[${index}][price_per_infant_subscribed]`"

              v-model="listmembership[index].price_per_infant_subscribed"
              id="price_per_infant_subscribed"
              placeholder="Ejem: 100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  export default {
    props: {
      defaultcurrency: {
        type: String,
      },
      membership: {
        type: Array,
        default: function () {
          return [];
        }
      },
      tourmemberships:{
        type: Array,
        default: function () {
          return [];
        }
      },
    },
    data() {
      return {
        membership_: [],
        defaultCurrency_ : null,
        selected: null,
        listmembership:[],
        index: 0
      }
    },
    mounted() {
      this.listmembership = this.tourmemberships;
      this.membership_ = this.membership;
      this.defaultCurrency_ = this.defaultcurrency;
    },
    computed: {

    },
    methods:{
      doMath: function (id) {
        return this.index
      },
      findNameMembership(_id){

        var name = null;
        this.membership_.forEach(element => {
          if(element.id === _id) name = element.name;
        });

        return name;
      },
      AddMembreship(_id) {

        var isvalid = true;
        if(this.listmembership != null){
          this.listmembership.forEach(element => {
            if(element.membership_id === _id) isvalid = false;
          });

          if(isvalid) {
            var item = null;
            this.membership_.forEach(element => {
              if(element.id === _id) item = element;
            });

            this.listmembership.push({
              // id:item.id,
              membership_id: item.id,
              name: item.name,
              price_per_adult_subscribed: null,
              price_per_child_subscribed: null,
              price_per_infant_subscribed: null,
              price_per_adult: null,
              price_per_child: null,
              price_per_infant: null
            });
          }
        }
      },
      RemoveMembership(index){
        this.listmembership.splice(index, 1);
      }
    }
  }
</script>
