<template>
  <div>
    <city-dashboard :range="r" v-if="r"></city-dashboard>

    <div class="container mt-3">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-end flex-column">
          <h6 class="text-muted m-0">Conteo</h6>
        </div>
        <div class="col-md-6 text-right">
          <date-picker v-model="range" range confirm type="date"></date-picker>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-6 mt-3">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row">
                <div :class="`col-md-${reservationColumn}`" v-for="(r, i) in reservations" :key="i">
                  <p class="text-muted text-center">Reservaciones</p>
                  <h2 class="text-primary text-center"># {{ r.total }}</h2>
                  <p class="text-center text-muted m-0">Pago {{ r.payment_type || 'Indefinido' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mt-3">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <p class="text-center text-muted">Suscripciones</p>
              <h2 class="text-primary text-center"># {{ subscriptions }}</h2>
              <p class="text-center text-muted m-0">Creadas</p>
            </div>
          </div>
        </div>
        
        <div class="col-md-3 mt-3">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <p class="text-center text-muted">Cupones</p>
              <h2 class="text-primary text-center"># {{ coupons }}</h2>
              <p class="text-center text-muted m-0">Usados</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-3">
      <h6 class="text-muted">Membresias</h6>
    </div>

    <membership-dashboard :range="r" v-if="r"
    ></membership-dashboard>

    <div class="container my-3">
      <h6 class="text-muted">Canales con más ventas</h6>
      <div class="row"  v-if="couponTypes.length > 0">
        <div class="col-md-3 mt-3" v-for="ct in couponTypes" :key="ct.id">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <h2 class="text-primary text-center"># {{ ct.used }} <h6 class="text-muted d-inline">/ reservaciones</h6></h2>
              <p class="text-muted text-center">{{ ct.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info">No hay canales</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import MembershipDashboard from './MembershipDashboard.vue';
import CityDashboard from './CityDashboard.vue';

export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      range: [],
      r: null,
      couponTypes: [],
      reservations: [],
      subscriptions: 0,
      coupons: 0,
    };
  },
  components: {
    CityDashboard,
    MembershipDashboard
  },
  mounted() {
    let start = moment(this.startDate, 'YYYY-MM-DD');
    let end = moment(this.endDate, 'YYYY-MM-DD');
    this.range = [start.toDate(), end.toDate()];
  },
  computed: {
    reservationsLength() {
      return this.reservations.length
    },
    reservationColumn() {
      if (this.reservationsLength === 0) return 12;

      return 12 / this.reservationsLength;
    }
  },
  methods: {
    async loadCouponsTypesData() {
      const start = this.r.start.format('YYYY-MM-DD');
      const end = this.r.end.format('YYYY-MM-DD');
      const { data } = await axios.get('/api-v1/dashboard/coupons-monthly', {
        params: {
          start,
          end
        }
      });

      this.couponTypes = data.couponTypes.map(ct => {
        if (ct.used === null) {
          ct.used = 0;
        }

        return ct;
      });
    },
    async loadCountersData() {
      const start = this.r.start.format('YYYY-MM-DD');
      const end = this.r.end.format('YYYY-MM-DD');
      const { data } = await axios.get('/api-v1/dashboard/counters', {
        params: {
          start,
          end
        }
      });

      this.reservations = data.reservations;
      this.coupons = data.coupons;
      this.subscriptions = data.subscriptions;
    }
  },
  watch: {
    range(newRange) {
      this.r = {
        start: moment(newRange[0]),
        end: moment(newRange[1])
      };

      this.loadCouponsTypesData();
      this.loadCountersData();
    }
  }
}
</script>

<style>

</style>