<template>
  <div class="bg-info position-relative overflow-hidden" :style="{'min-width': `${width}px`, 'min-height': `${height}px`}">
    <img :src="imagePreview || image" class="img-fluid" v-if="imagePreview || image" ref="imageBanner" />
    <div class="position-absolute w-100 h-100 p-3 form-content">
      <form class="form-inline">
        <b-button :id="`popover-${position}`" variant="primary" :ref="`bPop-${position}`">
          <i class="fas fa-cogs"></i>
        </b-button>
  
        <b-select :options="dateOptions" v-model="bannerSelect"></b-select>
  
        <delete-form-confirmation v-if="bannerObject"
          :url="`/banners/${bannerObject.id}`"
          :unique="bannerObject.id"
        ></delete-form-confirmation>
      </form>

      <b-popover
        :target="`popover-${position}`"
        triggers="click"
        :show.sync="popoverShow"
        placement="auto"
        container="my-container"
        :ref="`popover_${position}`"
        @show="onShow"
        @shown="onShown"
        @hidden="onHidden"
      >
        <template v-slot:title>
          <b-button @click="onClose" class="close" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true">&times;</span>
          </b-button>
          Configuración
        </template>

        <form action="/banners" method="POST" enctype="multipart/form-data" ref="bannerForm">
          <csrf-token></csrf-token>
          <input type="hidden" name="position" :value="position">

          <input type="hidden" name="width" :value="width_">
          <input type="hidden" name="height" :value="height_">

          <b-form-group
            label="Ancho"
            label-for="input-width"
            :state="inputWidthState"
            class="mb-1"
            description="Enter width"
          >
            <b-form-input
              type="text"
              ref="inputWidth"
              id="input-width"
              :state="inputWidthState"
              size="sm"
              value="Ancho de la imagen"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Alto"
            label-for="input-height"
            :state="inputHeightState"
            class="mb-1"
            description="Enter height"
          >
            <b-form-input
              type="text"
              id="input-height"
              :state="inputHeightState"
              size="sm"
              value="Alto de la imagen"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Fecha de inicio"
            label-for="start_at"
            class="mb-1"
          >
            <b-form-input type="date"
              id="start_at"
              size="sm"
              name="start_at"
              v-model="start_at"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Fecha Fin"
            label-for="end_at"
            class="mb-1"
          >
            <b-form-input type="date"
              id="end_at"
              size="sm"
              name="end_at"
              v-model="end_at"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Imagen"
            label-for="input-image"
            :state="inputImageState"
            class="mb-1"
            description="Enter Image"
          >
            <b-form-file
              id="input-image"
              v-model="image_"
              :state="inputImageState"
              size="sm"
              ref="inputImage"
              name="image"
            ></b-form-file>
          </b-form-group>

          <b-button @click="onCancel" size="sm" variant="danger">Cancelar</b-button>
          <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
        </form>
      </b-popover>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      required: false,
      type: Number,
      default: 500
    },
    height: {
      required: false,
      type: Number,
      default: 120
    },
    position: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      width_: 0,
      height_: 0,
      image_: null,
      imagePreview: null,
      inputWidthState: null,
      inputHeightState: null,
      inputImageState: null,
      popoverShow: false,
      bannerSelect: null,
      start_at: null,
      end_at: null
    }
  },
  computed: {
    dateOptions() {
      return this.banners.map(b => ({value: b.start_at, text: b.start_at}));
    }, 
    image() {
      if (this.bannerObject) {
        return this.bannerObject.images[0].public_path;
      }

      return null;
    },
    bannerObject() {
      if (this.bannerSelect) {
        return this.banners.find(b => b.start_at === this.bannerSelect);
      }

      return null;
    }
  },
  mounted() {
    this.width_ = this.width;
    this.height_ = this.height;

    if (this.banners.length > 0) {
      this.bannerSelect = this.banners[0].start_at;
      this.width_ = this.banners[0].width;
      this.height_ = this.banners[0].height;
    }
  },
  methods: {
    /* handleChange(event) {
      // this.image_ = this.$refs.inputImage.files[0];
      console.log(event, this.image_);
      this.$nextTick(() => {
        this.imagePreview = URL.createObjectURL(this.image_);
        console.log(this.image_);
      });
    },*/
    onCancel() {
      this.image_ = null;
      this.imagePreview = null;
      this.popoverShow = false;
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.width_) {
        this.inputWidthState = false
      }
      if (!this.height_) {
        this.inputHeightState = false
      }
      if (!this.image_) {
        this.inputWidthState = false
      }
      if (this.width_ && this.height_ && this.image_) {
        this.onClose();
        this.$refs['bannerForm'].submit();
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      
      this.inputWidthState = null
      this.inputHeightState = null
      this.inputImageState = null
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.inputWidth)
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs[`bPop-${this.position}`])
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    }
  },
  watch: {
    image_(newVal) {
      if (newVal) {
        this.imagePreview = URL.createObjectURL(this.image_);
      }
    }
  }
}
</script>

<style scoped>
.form-content {
  z-index: 10;
  top: 0;
  left: 0;;
}

.b-input {
  width: 5rem;
  line-height: 1rem;
  font-size: .8rem;
  padding: .2rem;
  border: none;
}
</style>
