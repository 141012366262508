<template>
  <div>
    <div v-for="area in areas_" :key="area.id">
      <availability-row :title="area.name" :categories="categories" v-slot="{ category }">
        <input-admin :value="area.categories[category.id] || null" 
          @input="$emit('area-updated', {area_id: area.id, transportation_category_id: category.id, price: $event})"
          @hovered="$emit('hovered')"
          @unhovered="$emit('unhovered')"
          :loading="loading"
        ></input-admin>
      </availability-row>
    </div>
  </div>
</template>

<script>
import AvailabilityRow from './AvailabilityRow.vue';
import InputAdmin from './InputAdmin.vue';

export default {
  props: {
    areas: {
      required: true,
      type: Array,
    },
    categories: {
      required: true,
      type: Array,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      areas_: []
    }
  },
  components: {
    AvailabilityRow,
    InputAdmin
  },
  mounted() {
    this.init(this.areas);
  },
  methods: {
    init(value) {
      this.areas_ = value.map(({id, name, last_transportations_pricing}) => {
        return {
          id,
          name,
          categories: last_transportations_pricing.reduce((a, p) => {
            return {
              ...a,
              [p.transportation_category_id]: p.price
            }
          }, {})
        }
      });
    }
  },
  watch: {
    areas(newValue) {
      /**
       * Revisar los controladores y traer la ultima disponibilidad por area
       */
      console.log(newValue);
      this.init(newValue);
    }
  }
}

</script>
