<template>
  <div class="text-right">
    <form action="/banners" class="form-inline d-inline-block">
      <b-select name="country" v-model="country_" :options="countryOptions" class="form-control border-0 bg-gray-200">
      </b-select>
      <b-select name="city" v-model="city_" :options="cityOptions" class="form-control border-0 bg-gray-200">
      </b-select>
      <button type="submit" class="btn btn-white">Buscar</button>
    </form>
  </div>
</template>

<script>
export default {
  props: ['countries', 'country', 'city'],
  data() {
    return {
      country_: null,
      city_: null,
    }
  },
  mounted() {
    this.country_ = this.country || null;
    this.city_ = this.city || null;
  },
  computed: {
    countryOptions() {
      return [{value: null, text: 'Elija un pais'}].concat(Object.keys(this.countries).map((c) => ({
        value: c,
        text: c
      })));
    },
    cityOptions() {
      let c = [];
      if (this.country_) {
        c = this.countries[this.country_].map(c => ({
          value: c.id,
          text: c.name
        }));
      }

      return [{text: 'Elija una ciudad', value: null}].concat(c);
    }
  }
}
</script>
